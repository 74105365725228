import { Button } from 'primereact/button';
import React from 'react';

export const SearchButton = ({ onSearch, loading }) => {
  return (
    <Button
      label="검색"
      icon="pi pi-search"
      onClick={onSearch}
      loading={loading}
    />
  );
};
