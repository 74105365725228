import React from 'react';

const PartnerUploadSuccess = () => {
  return (
    <div className="flex h-screen">
      <div
        className="flex flex-wrap m-auto justify-content-center border-1 py-6"
        style={{
          backgroundColor: 'white',
          borderColor: '#1a7bce',
          borderRadius: '20px',
        }}
      >
        <h1
          className="w-full text-center"
          style={{ color: '#1a7bce', fontSize: '34px', fontWeight: '600' }}
        >
          업로드 완료
        </h1>
        <p className="text-lg text-center" style={{ color: '#1a7bce' }}>
          해당 창을 닫아주셔도 됩니다.
        </p>
      </div>
    </div>
  );
};

export default PartnerUploadSuccess;
