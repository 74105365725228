import * as AccountDialog from './../../components/Dialog/AccountDialog';
import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';
import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../components/Menu/SearchMenu';
import ServiceMenu from '../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { myInfoIds } from '../../recoil/atoms';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  // searchText: '',
  useYN: 'Y',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const shopService = ServiceProvider.shop;

const ShopList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const myInfo = useRecoilValue(myInfoIds);
  const { myAssociationId, myEnterpriseId } = myInfo;
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    shopService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [codes, setCodes] = useState({});
  const [currentShopId, setCurrentShopId] = useState(null);

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 협회 마스터, 협회 관리자, 어드민
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        const [associations, associationId] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId] = await CodeUtil.getEnterpriseCodes(
          myInfo,
          {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          }
        );
        const [shops, shopId] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });

        setCodes((ps) => ({ ...ps, associations, enterprises, shops }));

        await getList({
          ...searchConditions,
          associations: [associationId],
          enterprises: [enterpriseId],
          shops: [shopId],
        });
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() =>
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              associationId: value,
                            });

                          const [shops] = await CodeUtil.getShopCodes(myInfo, {
                            isParentEmpty: _.isEmpty(enterprises),
                          });

                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속업체</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showClear
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops] = await CodeUtil.getShopCodes(myInfo, {
                            isParentEmpty: _.isEmpty(
                              _.get(codes, 'enterprises')
                            ),
                            enterpriseId: value,
                          });

                          handleSelectAll(
                            value,
                            'enterprises',
                            { shops },
                            { shops: [] }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>

                  <ServiceMenu
                    title={'점검장'}
                    onClickRegisterBtn={() => setCurrentShopId(0)}
                    excelData={{
                      type: 'shop',
                      params: {
                        ...searchConditions,
                        page: lazyParams.page + 1,
                        size: lazyParams.rows,
                      },
                    }}
                    buttonVisible={checkRegistAuth(myInfoLoadable.contents)}
                  />

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      loading={loading}
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="shopCode"
                        header={
                          <span>
                            점검장
                            <br />
                            점검장코드
                          </span>
                        }
                        body={({ shopId, shopName, shopCode }) => (
                          <>
                            <span
                              className="cursor-pointer text-primary"
                              onClick={() => setCurrentShopId(shopId)}
                            >
                              {shopName}
                            </span>
                            <br />[{shopCode}]
                          </>
                        )}
                      />
                      <Column
                        field="associationCode"
                        header="소속협회"
                        body={({ associationName, associationCode }) => (
                          <span>
                            {associationName}
                            {associationCode && (
                              <>
                                <br />[{associationCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseCode"
                        header="소속업체"
                        body={({ enterpriseName, enterpriseCode }) => (
                          <span>
                            {enterpriseName}
                            {enterpriseCode && (
                              <>
                                <br />[{enterpriseCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="userCount"
                        header="소속사용자수"
                        bodyClassName="text-right"
                        body={({ userCount }) => (
                          <span>{userCount ? userCount + '명' : '없음'}</span>
                        )}
                      />
                      <Column
                        field="businessRegistrationNumber"
                        header="사업자등록번호"
                        body={({ businessRegistrationNumber }) => {
                          const bizNumber = businessRegistrationNumber.slice(
                            0,
                            10
                          );

                          return (
                            <span>{CommonUtil.Formatter.biz(bizNumber)}</span>
                          );
                        }}
                      />
                      <Column
                        field="representative"
                        header={
                          <div>
                            <span>대표자</span>
                            <br />
                            <span>연락처</span>
                          </div>
                        }
                        body={({ representative, cellphone }) => (
                          <div>
                            <span>{representative}</span>
                            <br />
                            <span>
                              {cellphone &&
                                CommonUtil.Formatter.cellphone(cellphone)}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="address"
                        header={
                          <div>
                            <span>우편번호</span>
                            <br />
                            <span>주소</span>
                          </div>
                        }
                        body={({
                          shopId,
                          postcode,
                          address,
                          addressDetail,
                        }) => (
                          <>
                            <div
                              id={`address-${shopId}`}
                              className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            >
                              <span>[{postcode}]</span>
                              <br />
                              <span>
                                {address} {addressDetail}
                              </span>
                            </div>
                          </>
                        )}
                      />

                      <Column field="note" header="비고" />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentShopId !== null && (
                    <AccountDialog.Shop
                      visible
                      userInfo={myInfoLoadable.contents}
                      shopId={currentShopId}
                      onHide={() => setCurrentShopId(null)}
                      getList={getList}
                      searchConditions={searchConditions}
                      lazyParams={lazyParams}
                    />
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopList, comparisonFn);
