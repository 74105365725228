import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';
import * as Button from './../../components/Atoms/Button';

import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import CustomerCompanyDialog from '../../components/Customer/CustomerCompanyDialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import CustomerCompanySafe from '../../services/CustomerCompanyService';
import {
  associationsList,
  enterpriseList,
  myInfoIds,
  shopListState,
} from '../../recoil/atoms';

const customerCompanyService = ServiceProvider.customerCompany;

const CustomerCompanyList = () => {
  const DEFAULT_SEARCH_CONDITIONS = {
    associations: [],
    enterprises: [],
    shops: [],
    searchText: '',
    useYN: '',
  };
  const DEFAULT_LAZY_PARAMS = {
    first: 0,
    rows: 10,
    page: 0,
  };
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const shops = useRecoilValue(shopListState);
  const enterprises = useRecoilValue(enterpriseList);
  const associations = useRecoilValue(associationsList);
  const myInfo = useRecoilValue(myInfoIds);
  const { myAssociationId } = myInfo;
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [total, setTotal] = useState(0);
  const [safeData, setSafeData] = useState([]);

  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    customerCompanyService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );
  const [currentCustomerCompanyId, setCurrentCustomerCompanyId] =
    useState(null);
  const [codes, setCodes] = useState({});
  const [customerType, setCustomerType] = useState('nomal');

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  const getSafe6List = async (params) => {
    try {
      const data = await CustomerCompanySafe.getList(params);
      if (data) {
        setTotal(data.total);
        setSafeData(data.list);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    setCodes((ps) => ({ ...ps, associations, enterprises, shops }));
  }, [shops, enterprises, associations]);

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const { associationInfo, enterpriseInfo, shopInfo } =
          myInfoLoadable.contents;
        if (customerType === 'nomal') {
          await getList({
            ...searchConditions,
            associations: [associationInfo.associationId],
            enterprises: [enterpriseInfo.enterpriseId],
            shops: [shopInfo.shopId],
          });
        } else {
          getSafe6List({
            ...searchConditions,
            associations: [associationInfo.associationId],
            enterprises: [enterpriseInfo.enterpriseId],
            shops: [shopInfo.shopId],
            page: lazyParams.page + 1,
            size: lazyParams.rows,
          });
        }
      }
    })();
  }, [myInfoLoadable.state, customerType, myInfoLoadable.contents]);

  const setInitSafeSearch = () => {
    setSearchConditions({
      ...DEFAULT_SEARCH_CONDITIONS,
      associations: [myAssociationId],
    });
    getSafe6List({
      ...searchConditions,
      searchText: DEFAULT_SEARCH_CONDITIONS.searchText,
      associations: [myAssociationId],
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    });
  };

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() =>
                      customerType === 'nomal'
                        ? initList()
                        : setInitSafeSearch()
                    }
                    onSearch={() => {
                      customerType === 'nomal'
                        ? getList(
                            searchConditions,
                            _.get(DEFAULT_LAZY_PARAMS, 'page'),
                            _.get(DEFAULT_LAZY_PARAMS, 'rows')
                          )
                        : getSafe6List({
                            ...searchConditions,
                            page: lazyParams.page + 1,
                            size: lazyParams.rows,
                          });
                    }}
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops] = await CodeUtil.getShopCodes(myInfo, {
                            isParentEmpty: _.isEmpty(enterprises),
                          });

                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops] = await CodeUtil.getShopCodes(myInfo, {
                            isParentEmpty: _.isEmpty(
                              _.get(codes, 'enterprises')
                            ),
                            enterpriseId: value,
                          });

                          handleSelectAll(
                            value,
                            'enterprises',
                            {
                              shops,
                            },
                            {
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">키워드</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="매매상사 이름, 사업자등록번호로 검색"
                        onChange={({ target: { value } }) =>
                          //TODO: 대표전화 일때만 포매팅해서 보낼것
                          {
                            let v = value;
                            const numRegExp = /[0-9]/g;
                            if (
                              numRegExp.test(Number(value[0])) &&
                              value[0] === '0'
                            ) {
                              v = CommonUtil.Formatter.phone(value);
                            }
                            setSearchConditions((ps) => ({
                              ...ps,
                              searchText: v,
                            }));
                          }
                        }
                        onKeyDown={(e) =>
                          e.key === 'Enter'
                            ? customerType === 'nomal'
                              ? getList(
                                  searchConditions,
                                  _.get(DEFAULT_LAZY_PARAMS, 'page'),
                                  _.get(DEFAULT_LAZY_PARAMS, 'rows')
                                )
                              : getSafe6List({
                                  ...searchConditions,
                                  page: lazyParams.page + 1,
                                  size: lazyParams.rows,
                                })
                            : null
                        }
                      />
                    </div>
                  </SearchMenu>

                  <div className="card">
                    <div className="flex flex-auto flex-wrap align-items-center justify-content-between">
                      <div className="flex align-items-center gap-3">
                        <div className="flex align-items-center gap-2">
                          <RadioButton
                            inputId="nomal"
                            name="type"
                            value="nomal"
                            onChange={({ value }) => {
                              setCustomerType(value);
                            }}
                            checked={customerType === 'nomal'}
                          />
                          <label htmlFor="nomal">일반</label>
                        </div>
                        <div className="flex align-items-center gap-2">
                          <RadioButton
                            inputId="safe6"
                            name="type"
                            value="safe6"
                            onChange={({ value }) => {
                              setCustomerType(value);
                            }}
                            checked={customerType === 'safe6'}
                          />
                          <label htmlFor="safe6">Safe6</label>
                        </div>
                      </div>
                      <div className="flex gap-1">
                        <Button.Default
                          label={`매매상사 신규 등록`}
                          onClick={() => setCurrentCustomerCompanyId(0)}
                        />

                        <Button.Export
                          type={'customerCompany'}
                          params={{
                            ...searchConditions,
                            page: lazyParams.page + 1,
                            size: lazyParams.rows,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      loading={loading}
                      value={customerType === 'nomal' ? data : safeData}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={
                        customerType === 'nomal' ? totalRecords : total
                      }
                      onPage={(event) => {
                        setLazyParams(event);
                        // console.log(event);
                        customerType === 'nomal'
                          ? getList(searchConditions, event.page, event.rows)
                          : getSafe6List({
                              ...searchConditions,
                              page: !!event.page ? event.page + 1 : 1,
                              size: event.rows,
                            });
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        align={'center'}
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="companyName"
                        header="매매상사명"
                        body={({
                          customerCompanyId,
                          companyName,
                          safe6CompanyId,
                        }) => (
                          <span
                            className="cursor-pointer text-primary"
                            onClick={() =>
                              setCurrentCustomerCompanyId(
                                customerType !== 'safe6'
                                  ? customerCompanyId
                                  : safe6CompanyId
                              )
                            }
                          >
                            {companyName}
                          </span>
                        )}
                      />
                      <Column
                        field="businessRegistrationName"
                        header={
                          <div>
                            <span>사업자명</span>
                            <br />
                            <span>사업자등록번호</span>
                          </div>
                        }
                        body={({
                          businessRegistrationName,
                          businessRegistrationNumber,
                        }) => (
                          <div>
                            <span>{businessRegistrationName}</span>
                            <br />
                            <span>
                              {businessRegistrationNumber &&
                                CommonUtil.Formatter.biz(
                                  businessRegistrationNumber
                                )}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="businessRegistrationName"
                        header={
                          <div>
                            <span>대표자명</span>
                            <br />
                            <span>대표전화</span>
                            <br />
                            <span>팩스</span>
                          </div>
                        }
                        body={({ representative, mainPhone, fax }) => (
                          <div>
                            <span>{representative}</span>
                            <br />
                            <span>
                              {mainPhone &&
                                CommonUtil.Formatter.phone(mainPhone)}
                            </span>
                            <br />
                            <span>{fax && CommonUtil.Formatter.fax(fax)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="unionName"
                        header={
                          <div>
                            <span>소속매매조합</span>
                            <br />
                            <span>소속매매단지</span>
                          </div>
                        }
                        body={({ unionName, complexName }) => (
                          <div>
                            <span>{unionName}</span>
                            <br />
                            <span>{complexName}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="address"
                        header={
                          <div>
                            <span>우편번호</span>
                            <br />
                            <span>주소</span>
                          </div>
                        }
                        body={({
                          shopId,
                          postcode,
                          address,
                          addressDetail,
                        }) => (
                          <>
                            <div
                              id={`address-${shopId}`}
                              className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            >
                              <span>[{postcode}]</span>
                              <br />
                              <span>
                                {address} {addressDetail}
                              </span>
                            </div>
                          </>
                        )}
                      />
                      <Column
                        field="shopCode"
                        header={
                          <span>
                            소속점검장
                            <br />
                            점검장코드
                          </span>
                        }
                        body={({ regShopName, regShopCode }) => (
                          <span>
                            {regShopName}
                            {regShopCode && (
                              <>
                                <br />[{regShopCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="associationCode"
                        header={
                          <span>
                            소속협회
                            <br />
                            협회코드
                          </span>
                        }
                        body={({ regAssociationName, regAssociationCode }) => (
                          <span>
                            {regAssociationName}
                            {regAssociationCode && (
                              <>
                                <br />[{regAssociationCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseCode"
                        header={
                          <span>
                            소속점검업체
                            <br />
                            점검업체코드
                          </span>
                        }
                        body={({ regEnterpriseName, regEnterpriseCode }) => (
                          <span>
                            {regEnterpriseName}
                            {regEnterpriseCode && (
                              <>
                                <br />[{regEnterpriseCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column field="note" header="비고" />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentCustomerCompanyId !== null && (
                    <CustomerCompanyDialog
                      visible
                      customerType={customerType}
                      userInfo={myInfoLoadable.contents}
                      customerCompanyId={currentCustomerCompanyId}
                      onHide={() => {
                        setCurrentCustomerCompanyId(null);
                        customerType !== 'safe6'
                          ? getList(
                              searchConditions,
                              lazyParams.page,
                              lazyParams.rows
                            )
                          : getSafe6List({
                              ...searchConditions,
                              page: 1,
                              size: lazyParams.rows,
                            });
                      }}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerCompanyList, comparisonFn);
