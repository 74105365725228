import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CommonUtil } from '../../../utils/commonUtil';
import FormUtil from '../../../utils/formUtil';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import UserPosition from './../../../enums/UserPosition';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import { useFormValid } from '../../../hooks/useFormValid';

//* form 생성에 필요한 input data
const formField = [
  {
    code: 'associationId',
    title: '소속협회',
    type: 'number',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'enterpriseCode',
    title: '점검업체 코드',
    type: 'code',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'enterpriseName',
    title: '성능법인명',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'enterpriseBasicInfo',
  },
  {
    code: 'businessRegistrationNumber',
    title: '사업자 등록 번호',
    type: 'biz',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'enterpriseBasicInfo',
  },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: false,
    pattern: true,
  },
  {
    code: 'representative',
    title: '대표자명',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'enterpriseDetailInfo',
  },
  {
    code: 'mainPhone',
    title: '대표전화',
    type: 'phone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'enterpriseDetailInfo',
  },
  {
    code: 'fax',
    title: '팩스',
    type: 'fax',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'enterpriseDetailInfo',
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'personInCharge',
    title: '담당자',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'picContact',
    title: '담당자 연락처',
    type: 'phone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'picEmail',
    title: '담당자 이메일',
    type: 'email',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'enterpriseType',
    title: '업체구분',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'useYn',
    title: '1. 점검업체 정보',
    type: 'boolean',
    defaultValue: 'Y',
    required: true,
    pattern: false,
  },
];

const codeService = ServiceProvider.code;
const enterpriseService = ServiceProvider.enterprise;

export const EnterpriseDialog = ({
  userInfo,
  enterpriseId = 0,
  onHide = null,
}) => {
  const { myAssociationId, myUserPosition } = useMemo(
    () => MyInfoUtil.unpack(userInfo),
    [userInfo]
  );

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};

    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const { control, handleSubmit, reset, watch, trigger, setValue, getValues } =
    useForm({
      defaultValues: getDefaultValues(formField),
      reValidateMode: 'onSubmit',
    });

  /* 다이얼로그 진입시 id가 있으면 수정 || 없으면 등록 */
  const isModified = enterpriseId > 0;

  const { isFormComplete } = useFormValid(watch(), formField, isModified);
  const [codes, setCodes] = useState({});
  const [enterpriseData, setEnterpriseData] = useState(null);

  const [needReset, setNeedReset] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    enterpriseCode: false,
    loginId: false,
  });
  const [loading, setLoading] = useState(false);

  async function getAssociationCodes({ preSelectedAssociationId }) {
    const { data } = await codeService.associations();
    const associations = [...data];
    const association =
      (preSelectedAssociationId
        ? _.find(associations, { value: preSelectedAssociationId })
        : _.get(associations, 0)) || {};

    return [associations, _.get(association, 'value'), association];
  }

  async function getEnterpriseTypeCodes() {
    const {
      data: { EnterpriseType: enterpriseTypes },
    } = await codeService.enterpriseTypes();
    const { value: enterpriseType } = enterpriseTypes[0] || {};
    return [enterpriseTypes, enterpriseType];
  }

  const getFilteredInputData = (formField, category) => {
    return formField
      .filter((item) => item.category === category)
      .map((filtered) => {
        const { code, title, type, defaultValue, required, pattern } = filtered;
        return {
          code,
          title,
          type,
          defaultValue,
          required,
          pattern,
        };
      });
  };

  function fillEmptyField(data) {
    const copy = { ...data };
    for (let field in copy) {
      copy[field] = copy[field] ?? '';
    }

    return copy;
  }

  function setFormattedData(data) {
    const formatter = {
      businessRegistrationNumber: 'biz',
      mainPhone: 'phone',
      fax: 'fax',
      cellphone: 'cellphone',
      picContact: 'phone',
    };

    for (let field in data) {
      if (formatter[field] && data[field]) {
        setValue(
          field,
          CommonUtil.Formatter[formatter[field]](_.get(data, field))
        );
      }
    }
  }

  function checkUserAuth(userInfo, type) {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!isModified) {
        const [
          [enterpriseTypes, enterpriseType],
          [associations, associationId, association],
        ] = await Promise.all([
          getEnterpriseTypeCodes(),
          getAssociationCodes({ preSelectedAssociationId: myAssociationId }),
        ]);

        setCodes({ enterpriseTypes, associations });
        setValue('enterpriseType', enterpriseType);
        setValue('associationId', associationId);
      } else {
        try {
          const { data } = await enterpriseService.getData(enterpriseId);
          const { associationId } = data;
          const [[enterpriseTypes], [associations]] = await Promise.all([
            getEnterpriseTypeCodes(),
            getAssociationCodes({ preSelectedAssociationId: associationId }),
          ]);

          setCodes({ enterpriseTypes, associations });
          setEnterpriseData(data);
          setFormattedData(data);
        } catch (error) {
          window.cerp.dialog.error(
            '점검업체 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseId, isModified, myAssociationId, reset, setValue]);

  useEffect(() => {
    if (needReset) {
      setIsAvailable({ enterpriseCode: false, loginId: false });
      reset(getDefaultValues(formField));

      setNeedReset(false);
    }
  }, [getDefaultValues, needReset, reset]);

  useEffect(() => {
    if (enterpriseData !== null) {
      setIsAvailable({ enterpriseCode: true, loginId: true });
      reset(fillEmptyField(enterpriseData));
    }
  }, [enterpriseData, reset]);

  return (
    <Modal.Form
      title={'점검업체'}
      childDataName={'점검장, 사용자'}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={async () => {
        try {
          const {
            data: { enterprise },
          } = await enterpriseService.delete(enterpriseId);

          window.cerp.toast.success(
            '점검업체 삭제 완료',
            `[${enterprise.enterpriseName}] 점검법인이 삭제되었습니다.`
          );
          onHide();
        } catch (error) {
          window.cerp.dialog.error(
            '점검업체 삭제 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }}
      onSubmitConfirm={handleSubmit((data) =>
        FormUtil.Async.onSubmit(
          { formData: data },
          isModified,
          'enterprise',
          '점검업체',
          setNeedReset
        )
      )}
      saveBtnEnable={
        !(isAvailable.enterpriseCode && isAvailable.loginId && isFormComplete)
      }
      deleteBtnVisible={checkUserAuth(userInfo, 'DELETE')}
    >
      <form autoComplete="off">
        <Panel
          headerTemplate={
            <div className="p-panel-header">
              {isModified && checkUserAuth(userInfo, 'DISABLED') ? (
                <Controlled.InputSwitch
                  control={control}
                  watch={watch}
                  inputData={{
                    inputLabel: formField.filter(
                      (item) => item.code === 'useYn'
                    )[0].title,
                    toggleLabel: {
                      trueLabel: '사용',
                      falseLabel: '미사용',
                    },
                    dataLabel: 'useYn',
                  }}
                  styleClass={'font-semibold'}
                />
              ) : (
                <span className="font-semibold">1. 점검업체 정보</span>
              )}
            </div>
          }
        >
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-4">
              <Controlled.ValidateInputText
                id={enterpriseId}
                userInfo={{ associationId: myAssociationId }}
                control={control}
                trigger={trigger}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'enterpriseCode'
                  )[0].title,
                  dataLabel: 'enterpriseCode',
                }}
                inputConfig={{
                  minLength: 3,
                  maxLength: 3,
                  // placeholder: '대문자 및 숫자만 사용할 수 있습니다.',
                  placeholder: '영문, 숫자 조합 (예시. X99)',
                }}
                rules={{
                  required: '필수 입력 항목입니다.',
                  pattern: {
                    value: CommonUtil.Pattern['enterpriseCode'],
                    message: '영문, 숫자 조합 (예시. X99)',
                  },
                  minLength: {
                    value: 3,
                    message: '코드는 3자리로 구성됩니다.',
                  },
                  maxLength: {
                    value: 3,
                    message: '코드는 3자리로 구성됩니다.',
                  },
                }}
              />
            </div>
            {getFilteredInputData(formField, 'enterpriseBasicInfo').map(
              (item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-4"
                >
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}
            <div className="col-12 sm:col-6 lg:col-6">
              <Controller
                control={control}
                name="associationId"
                render={({ field, fieldState, formState }) => (
                  <Titled.Dropdown
                    id={field.name}
                    title={
                      formField.filter(
                        (item) => item.code === 'associationId'
                      )[0].title
                    }
                    required
                    {...field}
                    options={codes.associations}
                    placeholder={
                      loading ? (
                        <>
                          <i className="pi pi-spin pi-spinner m-0 mr-2" />
                          조회하고 있습니다...
                        </>
                      ) : (
                        '협회없음'
                      )
                    }
                    disabled={
                      !_.includes([UserPosition.Erp], myUserPosition) ||
                      !_.isEmpty(enterpriseData)
                    }
                  />
                )}
              />
            </div>
            <div className="col-12 sm:col-6 lg:col-6">
              <Controller
                control={control}
                name="enterpriseType"
                render={({ field, fieldState, formState }) => (
                  <Titled.Dropdown
                    id={field.name}
                    title="업체구분"
                    required
                    {...field}
                    options={codes.enterpriseTypes}
                  />
                )}
              />
            </div>
            {getFilteredInputData(formField, 'enterpriseDetailInfo').map(
              (item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}

            {/* <div className="col-12 sm:col-6 lg:col-3 pb-0">
              <div className="field m-0">
                <label>직인</label>
                <div className="cm-stamp bg-transparent flex flex-auto align-items-start justify-content-start gap-2">
                  <Image
                    src={getOfficialSealPath(
                      officialSeals,
                      _.get(currentValues, 'officialSealURL')
                    )}
                    alt={officialSeals !== null ? officialSeals[0].name : ''}
                    preview={officialSeals !== null}
                    imageClassName="border-round border-1 border-200"
                  />
                  <div
                    {...getRootProps({
                      className: 'w-full',
                      style: { wordBreak: 'keep-all' },
                    })}
                  >
                    <input {...getInputProps()} />
                    <Button
                      type="button"
                      label="이미지 선택"
                      icon="pi pi-image"
                      className="w-full p-button-outlined"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-12 sm:col-6 lg:col-3">
              <div className="field m-0">
                <label>
                  <i className="text-red-400 pi pi-check mr-1" />
                  성능점검구분번호
                </label>
                <div className="p-inputgroup">
                  <Controller
                    control={control}
                    name="statementNumberPrefix"
                    defaultValue=""
                    rules={{
                      required: '필수 입력항목입니다.',
                    }}
                    render={({ field, fieldState, formState }) => (
                      <InputText id={field.name} {...field} readOnly disabled />
                    )}
                  />
                  <Controller
                    control={control}
                    name="statementNumberAffix"
                    defaultValue=""
                    rules={{
                      required: '필수 입력항목입니다.',
                      pattern: {
                        value: CommonUtil.Pattern['statementNumber'],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        options={_.get(codes, 'affixes')}
                        optionDisabled={(opt) =>
                          _.get(opt, 'isOccupied') &&
                          _.get(opt, 'shopId') !==
                            _.get(currentValues, 'shopId')
                        }
                        itemTemplate={({
                          label,
                          value,
                          isOccupied,
                          shopId,
                          shopCode,
                          shopName,
                        }) => (
                          <div className="w-full flex flex-row align-items-center justify-content-between gap-1">
                            <div>{label}</div>
                            {isOccupied && (
                              <Badge
                                className="border-round-xs"
                                {...(shopId ===
                                  _.get(currentValues, 'shopId') && {
                                  severity: 'danger',
                                })}
                                value={`[${shopCode}] ${shopName}`}
                              />
                            )}
                          </div>
                        )}
                        error={formState.errors[field.name]}
                      />
                    )}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </Panel>

        <Panel header="2. 담당자 정보" className="pt-3">
          <div className="grid">
            {getFilteredInputData(formField, 'picInfo').map((item, idx) => (
              <div
                key={`col_${item.code}`}
                className="col-12 sm:col-6 lg:col-3"
              >
                <Controlled.InputText
                  item={item}
                  key={idx}
                  control={control}
                  setValue={setValue}
                />
              </div>
            ))}
          </div>
        </Panel>

        <Panel header="3. 마스터 계정 정보" className="pt-3">
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-3">
              <Controlled.ValidateInputText
                id={enterpriseId}
                control={control}
                trigger={trigger}
                // currentValues={currentValues}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                inputData={{
                  inputLabel: '로그인 아이디',
                  dataLabel: 'loginId',
                }}
                inputConfig={{
                  placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                  autoComplete: 'off',
                }}
                rules={{
                  required: '필수 입력 항목입니다.',
                  // pattern: {
                  //   value: CommonUtil.Pattern['login'],
                  //   message: '유효하지 않은 포맷입니다.',
                  // },
                }}
              />
            </div>

            {!isModified &&
              getFilteredInputData(formField, 'password').map((item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputPassword
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    inputConfig={{
                      autoComplete: 'off',
                    }}
                  />
                </div>
              ))}
          </div>
        </Panel>
      </form>
    </Modal.Form>
  );
};
