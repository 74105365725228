import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import dayjs from 'dayjs';

const BasePriceSaveConfirmDialog = ({
  isUpdate = false,
  minStartDate = null,
  onHide = null,
}) => {
  const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(minStartDate || dayjs().toDate());
  const description = '';
  // useEffect(() => {
  //   if (minStartDate === null) {
  //     setTimeout(() => window.cerp.dialog.error('오류', '시작일자가 없습니다.'), 500);
  //     onHide();
  //   }
  // }, [minStartDate]);
  const formatConfirmData = (title, description, startDate) => {
    const confirmData = {
      title: title || '기본보험료테이블',
      description: description,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
    };

    // if (!title) confirmData.title = '기본보험료테이블';
    // confirmData.startDate = dayjs(startDate).format('YYYY-MM-DD');

    return confirmData;
  };

  return (
    <Dialog
      header="확인"
      footer={
        <>
          <Button
            label="아니오"
            className="p-button-text"
            onClick={(e) => onHide()}
          />
          <Button
            label="네"
            icon="pi pi-check"
            onClick={(e) =>
              onHide(formatConfirmData(title, description, startDate))
            }
          />
        </>
      }
      visible
      style={{ width: '30vw' }}
      breakpoints={{ '960px': '50vw', '640px': '75vw' }}
      modal
      onHide={onHide}
    >
      <div className="grid">
        <div className="col-12">
          <div className="flex flex-row align-items-center justify-content-start">
            <i className="pi pi-exclamation-triangle text-4xl" />
            <span className="ml-3">
              {isUpdate
                ? '보험료 정보를 수정하시겠습니까?'
                : '신규 보험료를 등록하시겠습니까?'}
            </span>
          </div>
        </div>
        <div className="col-12">
          <div className="field m-0">
            <label>제목</label>
            <InputText
              className="w-full"
              placeholder="기본보험료테이블"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="field m-0">
            <label>시작일자</label>
            <div className="p-inputgroup">
              <div className="p-inputgroup-addon">
                <i className="pi pi-calendar" />
              </div>
              <Calendar
                className="w-full"
                showIcon
                icon="pi pi-calendar"
                dateFormat="yy-mm-dd"
                mask="9999-99-99"
                monthNavigator
                yearNavigator
                yearRange={`${dayjs(minStartDate || new Date()).year()}:${
                  dayjs(minStartDate || new Date()).year() + 5
                }`}
                value={
                  startDate !== null
                    ? dayjs(startDate).toDate()
                    : dayjs().toDate()
                }
                {...(minStartDate !== null && {
                  minDate: dayjs(minStartDate).toDate(),
                })}
                onChange={(e) => setStartDate(e.value || dayjs().toDate())}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BasePriceSaveConfirmDialog;
