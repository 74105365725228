import { useCallback, useEffect, useState } from 'react';
import { MyInfoUtil } from '../utils/myInfoUtil';
import dayjs from 'dayjs';
import { myInfoSelector } from '../recoil/selectors';
import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

export const useAuthorizedFetch = (
  service,
  searchConditions,
  setSearchConditions,
  defaultSearchConditions,
  page = 0,
  size = 10,
  isSumNeeded = false
) => {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});
  const [initialSearchConditions, setInitialSearchConditions] = useState({
    ...defaultSearchConditions,
  });

  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const formatDate = (date) => {
    return dayjs(date).format('YYYYMMDD');
  };

  const getList = useCallback(
    async (conditions, page = 0, size = 10) => {
      setLoading(true);

      const params = { ...conditions };

      // date 형태의 데이터는 포매팅하기
      //TODO: date 형태가 있을 때만 객체를 순회하도록 하기
      for (let key in params) {
        if (key.toLowerCase().includes('date')) {
          params[key] = formatDate(params[key]);
        }

        // 전화번호로 검색하는 경우에 formatting 데이터를 되돌림
        if (key === 'searchText') {
          const reformat = params[key].split('-').join('');
          const number = Number(reformat);

          if (!isNaN(number)) {
            // 숫자로 이루어진 값을 검색할 때
            params[key] = reformat;
          }
        }
      }

      try {
        const {
          data: { total, list },
        } = await service.list({
          ...params,
          page: page + 1,
          size: size,
        });
        setTotalRecords(total);
        setData(list);
      } catch (error) {
        // console.log('error', error);
        window.cerp.dialog.error(
          '조회 실패',
          `[${error?.code}] ${error?.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [service]
  );

  const getSummary = useCallback(
    async (conditions) => {
      setLoading(true);

      try {
        const params = { ...conditions };

        // date 형태의 데이터는 포매팅하기
        //TODO: date 형태가 있을 때만 객체를 순회하도록 하기
        for (let key in params) {
          if (key.toLowerCase().includes('date')) {
            params[key] = formatDate(params[key]);
          }
        }

        const { data } = await service.summary({
          ...params,
          page: page + 1,
          size: size,
        });

        setSummary(data);
      } catch (error) {
        window.cerp.dialog.error(
          '조회실패',
          `[${error?.code}] ${error?.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [service]
  );

  const initList = useCallback(async () => {
    setSearchConditions(initialSearchConditions);
    await getList(initialSearchConditions);
  }, [getList, initialSearchConditions, setSearchConditions]);

  const initSummary = useCallback(async () => {
    setSearchConditions(initialSearchConditions);
    await getSummary(initialSearchConditions);
  }, [getSummary, initialSearchConditions, setSearchConditions]);

  const initData = async () => {
    setSearchConditions(initialSearchConditions);
    await Promise.all([initList(), initSummary()]);
  };

  const getData = async (searchConditions) => {
    await Promise.all([
      getList(searchConditions),
      getSummary(searchConditions),
    ]);
  };

  const setAuthorizedSearchConditions = useCallback(
    (searchConditions, defaultSearchConditions) => {
      const myIds = {
        associations: myAssociationId === null ? [] : [myAssociationId],
        enterprises: myEnterpriseId === null ? [] : [myEnterpriseId],
        shops: myShopId === null ? [] : [myShopId],
      };

      const [authorizedSearchConditions, authorizedDefaultSearchConditions] = [
        searchConditions,
        defaultSearchConditions,
      ].map((condition) => {
        const updatedCondition = { ...condition };

        for (let field in myIds) {
          if (updatedCondition[field]) {
            updatedCondition[field] = myIds[field];
          }
        }

        return updatedCondition;
      });

      return [authorizedSearchConditions, authorizedDefaultSearchConditions];
    },
    [myAssociationId, myEnterpriseId, myShopId]
  );

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const [authorizedSearchConditions, authorizedDefaultSearchConditions] =
          setAuthorizedSearchConditions(
            searchConditions,
            defaultSearchConditions
          );
        setSearchConditions((ps) => ({ ...ps, ...authorizedSearchConditions }));
        setInitialSearchConditions((ps) => ({
          ...ps,
          ...authorizedDefaultSearchConditions,
        }));
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  // useEffect(() => {
  //   (async () => {
  //     if (myInfoLoadable.state === 'hasValue') {
  //       const [authorizedSearchConditions, authorizedDefaultSearchConditions] =
  //         setAuthorizedSearchConditions(
  //           searchConditions,
  //           defaultSearchConditions
  //         );
  //       setSearchConditions((ps) => ({ ...ps, ...authorizedSearchConditions }));
  //       setInitialSearchConditions((ps) => ({
  //         ...ps,
  //         ...authorizedDefaultSearchConditions,
  //       }));
  //       await getList(authorizedSearchConditions);
  //       if (isSumNeeded) await getSummary(authorizedDefaultSearchConditions);
  //     }
  //   })();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [myInfoLoadable.state]);

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const [authorizedSearchConditions, authorizedDefaultSearchConditions] =
          setAuthorizedSearchConditions(
            searchConditions,
            defaultSearchConditions
          );
        setSearchConditions((ps) => ({ ...ps, ...authorizedSearchConditions }));
        setInitialSearchConditions((ps) => ({
          ...ps,
          ...authorizedDefaultSearchConditions,
        }));
        if (isSumNeeded) await getSummary(authorizedDefaultSearchConditions);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return {
    loading,
    totalRecords,
    data,
    summary,
    getList,
    initList,
    getSummary,
    initSummary,
    getData,
    initData,
  };
};
