import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import * as Button from '../../components/Atoms/Button/index.js';
import * as Modal from './../../components/Atoms/Modal';
import { compressImages } from '../../utils/compressUtil';
import { Image } from 'primereact/image';
import _ from 'lodash';
import dayjs from 'dayjs';
import { COUNT_PER_PAGE } from '../../constants/Constants.js';
import { MyInfoUtil } from '../../utils/myInfoUtil.js';
import SearchMenu from '../../components/Menu/SearchMenu.js';
import { myInfoSelector } from '../../recoil/selectors.js';
import SafePaymentDetail from './SafePaymentDetail.jsx';
import Settlement from '../../services/SettlementService.js';
import { ColumnGroup } from 'primereact/columngroup';
import AxiosUtil from '../../utils/axiosUtil.js';
import { useDropzone } from 'react-dropzone';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import SendSMS from '../Accident/SendSMS.jsx';
import { Claim } from '../../services/ClaimService.js';
import { RadioButton } from 'primereact/radiobutton';
import { CommonUtil } from '../../utils/commonUtil';
import { Badge } from 'primereact/badge';
import FileState from '../../enums/FileState.js';
import filesize from 'filesize';
import performanceCheckUtil from '../../utils/performanceCheckUtil.js';
const priceFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });

const DEFAULT_SEARCH_CONDITIONS = {
  settlementMonth: '',
  customerCompanyName: '',
  range: 'month',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 30,
  page: 0,
};

const SafePaymentList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const { myRoleCode } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const defaultMonth = dayjs().subtract(1, 'month');
  const defaultMonthDate = defaultMonth.toDate();
  const today = dayjs().toDate();
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [showDetail, setShowDetail] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [curDay, setCurDay] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sumData, setSumData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [date, setDate] = useState(defaultMonthDate);
  const [selectedDataId, setSelectedDataId] = useState('');
  const [searchData, setSearchData] = useState({
    date: '',
    carNo: '',
    dealerName: '',
    checkType: 'Y',
  });

  const [searchMode, setSearchMode] = useState(null);
  const [fileItems, setFileItems] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showModal, setShowModal] = useState({
    fileUpload: false,
    photoUpload: false,
    detail: false,
    change: false,
  });
  const [changeReason, setChangeReason] = useState(''); // 대상 변경 요청 사유
  const [changeRequest, setChangeRequest] = useState('');
  const [fileDate, setFileDate] = useState(today);
  const [companyAccount, setCompanyAccount] = useState(null);
  const Y_REASON_VALUES = new Set(Y_REASON.map((item) => item.value));

  // 매매상사 계좌정보 조회
  const getCompanyAccount = async () => {
    try {
      const data = await Settlement.getCustomerAccount();
      if (data) {
        setCompanyAccount(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (myRoleCode === '6_ADM') {
      getCompanyAccount();
    }
  }, [myRoleCode]);

  useEffect(() => {
    if (!changeReason) return;

    const newRequest = Y_REASON_VALUES.has(changeReason) ? 'A11' : 'A01';
    setChangeRequest(newRequest);
  }, [changeReason, Y_REASON_VALUES]);

  const handleSearchInput = (e) => {
    const { id, value } = e.target;
    setSearchData({ ...searchData, [id]: value });
  };

  const exportToExcel = async () => {
    setExporting(true);
    const params = {
      settleMonth: dayjs(date).format('YYYYMM'),
    };
    await AxiosUtil.exportToExcel(`settlement/sr`, params);
    setExporting(false);
  };

  const uploadExcelData = async () => {
    setUploadLoading(true);
    try {
      const res = await Settlement.uploadExcel(
        dayjs(fileDate).format('YYYYMM'),
        fileItems
      );

      if (res) {
        const { data, error } = res;
        if (!data && error) {
          window.cerp.dialog.error('업로드 실패', `${error?.message}`);
        } else if (data) {
          window.cerp.toast.success('업로드 성공');
        }
      }
    } catch (error) {
      if (error.response.data.error.message) {
        window.cerp.dialog.error(
          '업로드 실패',
          `${error.response.data.error.message}`
        );
      } else {
        window.cerp.toast.error('업로드 실패');
      }
    } finally {
      setFileItems([]);
      setUploadLoading(false);
    }
  };

  const [checkType, setCheckType] = useState('Y');
  const getMonthCompanyData = async (parameters) => {
    setLoading(true);
    try {
      const data = await Settlement.getCompanies(parameters);
      if (data) {
        setData(data.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        if (searchMode) {
          window.cerp.toast.error(`정산내역 검색 실패`);
        } else {
          window.cerp.toast.error(`정산내역 조회 실패`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const getDayCompany = async (parameters) => {
    try {
      const data = await Settlement.getCustomerCompany(parameters);
      if (data) {
        setData(data?.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        window.cerp.toast.error(`정산내역 조회 실패`);
      }
    }
  };

  useEffect(() => {
    if (!!myRoleCode) {
      if (myRoleCode === 'A_MST') {
        if (!searchMode) {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            page: lazyParams.page + 1,
            size: lazyParams.rows,
            checkType: checkType,
          });
        } else {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            customerCompanyName: !!searchConditions.customerCompanyName
              ? searchConditions.customerCompanyName
              : null,
            page: lazyParams.page + 1,
            size: lazyParams.rows,
            checkType: checkType,
          });
        }
      } else if (myRoleCode === 'C_ADM' || myRoleCode === '6_ADM') {
        getDayCompany({
          settlementMonth: dayjs(date).format('YYYYMM'),
          checkType: searchData?.checkType,
          dealerName: searchData?.dealerName,
          carNo: searchData?.carNo,
          page: lazyParams.page + 1,
          size: lazyParams.rows,
        });
        setCheckType(searchData.checkType);
      }
    }
  }, [myRoleCode, searchMode, lazyParams.page, lazyParams.rows]);

  const resetSearchData = () => {
    setSearchConditions({ ...DEFAULT_SEARCH_CONDITIONS });
    setLazyParams({ ...DEFAULT_LAZY_PARAMS });
    setDate(defaultMonthDate);
  };

  const [result, setResult] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchInfo, setSearchInfo] = useState({
    carNbr: '',
    distanceDriven: '',
    name: '',
    contact: '',
    shopName: '',
  });

  const isTargetEligible = async () => {
    setResult('');
    const { carNbr, distanceDriven } = searchInfo;

    if (typeof searchInfo.distanceDriven === 'string') {
      searchInfo.distanceDriven = Number(
        distanceDriven?.includes(',')
          ? distanceDriven?.replaceAll(',', '')
          : distanceDriven
      );
    }

    if (carNbr && distanceDriven) {
      setSearchLoading(true);
      try {
        const data = await Settlement.request(searchInfo);
        if (data) {
          setResult(data.repMessage);
        }
      } catch (error) {
        console.log(error);
        const { response } = error;
        window.cerp.dialog.error('조회 실패', response.data.error.message);
      } finally {
        setSearchLoading(false);
      }
    } else {
      window.cerp.toast.warn('필수값 미입력', '모두 입력해주세요.');
    }
  };

  const [curSafe6Info, setCurSafe6Info] = useState({
    safe6Info: {},
    photoInfo: [],
  });
  const getDetailData = async (dataId) => {
    try {
      const data = await Settlement.getCompanyDetail(dataId);
      if (data) {
        // console.log(data);
        if (!data.photoInfo.legth) {
          setCurSafe6Info(data);
          const loadedPhotos = data.photoInfo.map((item) => {
            item.state = FileState.Loaded;
            return item;
          });
          setFileItems(loadedPhotos);
        } else {
          setCurSafe6Info(data);
          setShowModal({ ...showModal, detail: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    setSearchInfo({ ...searchInfo, [id]: value });
  };

  const initSearch = () => {
    setSearchInfo({
      ...searchInfo,
      carNbr: '',
      distanceDriven: '',
    });
    setResult('');
  };

  const uploadPhoto = async () => {
    try {
      const { fileInfo, files } =
        performanceCheckUtil.File.convertForServerAtSafe6(fileItems);

      const fileData = {
        dataId: selectedDataId
          ? selectedDataId
          : curSafe6Info?.safe6Info?.dataId, // int
        fileData: fileInfo,
        reasonCode: changeReason,
        requestType: changeRequest, //대상-> 비대상요청 'A01', 비대상->대상요청 'A11'
      };
      // console.log(fileData, files);
      const data = await Settlement.registerPhoto(fileData, files);
      if (data) {
        console.log(data);
        getDayCompany({
          settlementMonth: dayjs(date).format('YYYYMM'),
          checkType: searchData?.checkType,
          dealerName: searchData?.dealerName,
          carNo: searchData?.carNo,
          page: lazyParams.page + 1,
          size: lazyParams.rows,
        });
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error('업로드 실패', '다시 시도해주세요.');
    } finally {
      setChangeReason('');
    }
  };

  const [showSmsModal, setShowSmsModal] = useState(false);
  const rowClick = true;
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [smsLoading, setSmsLoading] = useState(false);
  const [smsData, setSmsData] = useState({
    msgType: 'lms',
    sourceAddr: '15332729',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
    msgSubType: null,
  });

  //ANCHOR - post sms
  const postSms = async () => {
    setSmsLoading(true);
    const postData = {
      msgType: smsData.msgType,
      msgSubType: smsData.msgType === 'at' ? smsData.msgSubType : null, //opt
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: smsData.subject, // opt
      contents: smsData.contents,
      fileData: null,
      url: smsData.url ?? null,
      msgAuthType: 'SR',
      claimDataId: null,
    };
    // return console.log(postData);
    try {
      const data = await Claim.postSMS(postData);

      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
      }
    } catch (error) {
      setShowSmsModal(false);
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
    }
    setSmsLoading(false);
  };

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  {myRoleCode === '6_ADM' && (
                    <div className="card">
                      <Panel header="대상차량 조회">
                        <div className="flex flex-wrap">
                          <div className="flex gap-3 flex-wrap mr-6">
                            <div className="flex flex-column gap-2">
                              <div>
                                <i className="pi pi-check mr-1 text-red-400" />
                                <label htmlFor="carNbr">차량번호</label>
                              </div>
                              <InputText
                                id="carNbr"
                                onChange={(e) => handleInput(e)}
                                onKeyDown={(e) =>
                                  e.key === 'Enter' ? isTargetEligible() : null
                                }
                                value={searchInfo.carNbr}
                              />
                            </div>
                            <div className="flex flex-column gap-2">
                              <div>
                                <i className="pi pi-check mr-1 text-red-400" />
                                <label htmlFor="distanceDriven">
                                  주행거리 (Km)
                                </label>
                              </div>
                              <InputText
                                id="distanceDriven"
                                onChange={(e) => handleInput(e)}
                                keyfilter="int"
                                value={searchInfo.distanceDriven}
                                onKeyDown={(e) =>
                                  e.key === 'Enter' ? isTargetEligible() : null
                                }
                              />
                            </div>
                            <div className="flex align-items-end gap-3">
                              <Button.Default
                                type="button"
                                label="차량 조회"
                                icon="pi pi-check-circle"
                                loading={searchLoading}
                                onClick={() => isTargetEligible()}
                              />
                              <Button.Default
                                type="button"
                                label="초기화"
                                icon="pi pi-replay"
                                className="p-button-outlined"
                                disabled={searchLoading}
                                onClick={() => initSearch()}
                              />
                            </div>
                          </div>
                          <div className="flex gap-3 pr-4">
                            <div className="flex flex-column gap-2">
                              <div>
                                <label htmlFor="name">제시자명(딜러)</label>
                              </div>
                              <InputText
                                id="name"
                                onChange={(e) => handleInput(e)}
                                value={searchInfo.name}
                                onFocus={(e) => e.target.select()}
                              />
                            </div>
                            <div className="flex flex-column gap-2">
                              <div>
                                <label htmlFor="contact">수신번호</label>
                              </div>
                              <InputText
                                id="contact"
                                onChange={(e) => handleInput(e)}
                                value={CommonUtil.Formatter.cellphone(
                                  searchInfo.contact
                                )}
                                keyfilter="int"
                                onFocus={(e) => e.target.select()}
                              />
                            </div>
                            <div className="flex align-items-end">
                              <Button.Default
                                type="button"
                                label="문자전송"
                                className="p-button-outlined"
                                icon="pi pi-envelope"
                                onClick={() => {
                                  searchInfo.shopName =
                                    companyAccount?.companyName;
                                  searchInfo.bankName =
                                    companyAccount?.bankName;
                                  searchInfo.bankAccountNo =
                                    companyAccount?.bankAccountNo;
                                  setShowSmsModal(true);
                                }}
                                disabled={
                                  !searchInfo.contact || !searchInfo.carNbr
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-inputgroup  mt-4 w-12">
                          <span className="p-inputgroup-addon w-2">결과</span>
                          <InputText
                            value={result}
                            readOnly
                            className={`${
                              result?.includes('아닙니다')
                                ? 'text-red-500'
                                : 'text-blue-500'
                            } font-bold`}
                          />
                        </div>
                      </Panel>
                    </div>
                  )}
                  <SearchMenu
                    onInit={() => {
                      resetSearchData();
                      setSearchMode(null);
                    }}
                    onSearch={() => setSearchMode(searchMode + 1)}
                  >
                    <div className="field col-12 md:col-3 lg:col-2">
                      <label htmlFor="searchMonth" className="mb-2 font-bold">
                        조회기간
                      </label>
                      <Calendar
                        showIcon
                        id="searchMonth"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        view="month"
                        dateFormat="yy년 mm월"
                        className="w-full"
                      />
                    </div>
                    {myRoleCode === '6_ADM' && (
                      <>
                        <div className="field col-12 md:col-2 lg:col-2">
                          <label htmlFor="carNo" className="mb-2 font-bold">
                            차량번호
                          </label>
                          <InputText
                            id="carNo"
                            value={searchData.carNo}
                            onChange={(e) => handleSearchInput(e)}
                            className="w-full"
                          />
                        </div>
                        <div className="field col-12 md:col-2 lg:col-2">
                          <label
                            htmlFor="dealerName"
                            className="mb-2 font-bold"
                          >
                            제시자(딜러)
                          </label>
                          <InputText
                            id="dealerName"
                            value={searchData.dealerName}
                            onChange={(e) => handleSearchInput(e)}
                            className="w-full"
                          />
                        </div>
                        <div className="field col-12 md:col-3 lg:col-3">
                          <div className="mb-2 font-bold">Safe6 대상 구분</div>
                          <div className="flex align-items-center gap-2">
                            <RadioButton
                              id="checkType"
                              inputId="target_y"
                              value={'Y'}
                              checked={searchData.checkType === 'Y'}
                              onChange={(e) => handleSearchInput(e)}
                            />
                            <label htmlFor="target_y">대상</label>
                            <RadioButton
                              id="checkType"
                              inputId="n_target"
                              value={'N'}
                              checked={searchData.checkType === 'N'}
                              onChange={(e) => handleSearchInput(e)}
                            />
                            <label htmlFor="n_target">비대상</label>
                          </div>
                        </div>
                      </>
                    )}

                    {myRoleCode === 'A_MST' && (
                      <>
                        <div className="field col-12 sm:col-6 md:col-3 lg:col-3">
                          <div className="font-bold mb-2">Safe6 구분</div>
                          <Dropdown
                            id="checkType"
                            className="w-full"
                            options={[
                              { label: '대상 조회', value: 'Y' },
                              { label: '비대상 조회', value: 'N' },
                              { label: '세이프6 요청', value: 'Z' },
                            ]}
                            value={checkType}
                            onChange={(e) => setCheckType(e.value)}
                          />
                        </div>
                        <div className="field col-12 sm:col-6 md:col-3 lg:col-3">
                          <label
                            className="font-bold"
                            htmlFor="customerCompanyName"
                          >
                            매매상사
                          </label>
                          <InputText
                            id="customerCompanyName"
                            className="w-full"
                            placeholder="상사명으로 검색"
                            value={searchConditions.customerCompanyName}
                            onChange={({ target: { value } }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerCompanyName: value,
                              }));
                            }}
                            onKeyDown={(e) =>
                              e.key === 'Enter'
                                ? setSearchMode(searchMode + 1)
                                : null
                            }
                          />
                        </div>
                      </>
                    )}
                  </SearchMenu>

                  {myRoleCode === 'A_MST' && (
                    <div className="card flex flex-auto flex-wrap align-items-center justify-content-end">
                      <div className="flex gap-2">
                        <>
                          <Button.Default
                            type="button"
                            label="매도데이터 업로드"
                            icon="pi pi-upload"
                            className=" p-button-outlined"
                            loading={uploadLoading}
                            onClick={() =>
                              setShowModal({ ...showModal, fileUpload: true })
                            }
                          />
                          <Button.Default
                            label="엑셀 다운로드"
                            className="p-button-outlined"
                            icon="pi pi-file-excel"
                            disabled={exporting || !data?.length}
                            loading={exporting}
                            onClick={() => exportToExcel()}
                          />
                        </>
                      </div>
                    </div>
                  )}

                  <div className="card">
                    {myRoleCode === '6_ADM' ? (
                      <DataTable
                        // loading={isLoading}
                        selectionMode={rowClick ? null : 'radiobutton'}
                        selection={selectedCustomer}
                        onSelectionChange={(e) => setSelectedCustomer(e.value)}
                        value={data}
                        lazy
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={totalRecords}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="데이터가 없습니다."
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                        rowsPerPageOptions={COUNT_PER_PAGE}
                        resizableColumns
                        showGridlines
                        onPage={(event) => {
                          setLazyParams(event);
                        }}
                        dataKey="dataId"
                      >
                        {/* <Column selectionMode="single" header="선택" /> */}
                        <Column
                          header="순번"
                          align={'center'}
                          body={(rowData, column) => (
                            <span>{column.rowIndex + 1}</span>
                          )}
                        />
                        <Column
                          header="변경 요청"
                          align={'center'}
                          body={({
                            safe6Flag,
                            dataId,
                            safe6ReasonCode,
                            safe6ChangeCode,
                          }) =>
                            safe6Flag === 'Y' ? (
                              <Button.Default
                                type="button"
                                label="비대상 요청"
                                className="p-button-outlined py-1 px-2"
                                onClick={() => {
                                  setShowModal({
                                    ...showModal,
                                    photoUpload: true,
                                  });
                                  setSelectedDataId(dataId);
                                  getDetailData(dataId);
                                }}
                                disabled={safe6ChangeCode === 'A01'}
                              />
                            ) : (
                              <Button.Default
                                type="button"
                                label="대상 요청"
                                className="p-button-outlined py-1 px-2"
                                onClick={() => {
                                  setChangeReason(safe6ReasonCode);
                                  setShowModal({
                                    ...showModal,
                                    change: true,
                                  });
                                  setSelectedDataId(dataId);
                                  // getDetailData(dataId);
                                }}
                                disabled={safe6ChangeCode === 'A11'}
                              />
                            )
                          }
                        />
                        <Column
                          header="승인상태"
                          align={'center'}
                          body={({ safe6ChangeCode }) => {
                            const matchedStatus = REQUEST_STATUS.find(
                              (item) => item.value === safe6ChangeCode
                            );
                            const label = matchedStatus
                              ? matchedStatus.label
                              : safe6ChangeCode;
                            return <span>{label ?? '-'}</span>;
                          }}
                        />
                        {checkType === 'N' && (
                          <Column
                            header="비대상사유"
                            align={'center'}
                            body={({ safe6ReasonCode }) => {
                              const ALL_REASON = [
                                ...ORIGIN_N_REASON,
                                ...N_REASON,
                                ...Y_REASON,
                              ];
                              const matchedStatus = ALL_REASON.find(
                                (item) => item.value === safe6ReasonCode
                              );
                              const label = matchedStatus
                                ? matchedStatus.label
                                : safe6ReasonCode;
                              return <span>{label}</span>;
                            }}
                          />
                        )}
                        <Column
                          align={'center'}
                          header="성능번호"
                          body={({ statementNumber, dataId }) => (
                            <span>{statementNumber}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="sellDate"
                          header="매도일"
                          body={({ sellDate }) => (
                            <span>
                              {dayjs(sellDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                            </span>
                          )}
                        />
                        <Column
                          header="제시자(딜러)"
                          align={'center'}
                          body={({ customerName }) => (
                            <span>{customerName}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          header="성능일"
                          body={({ checkDate }) => (
                            <span>
                              {dayjs(checkDate, 'YYYYMMDD').format(
                                'YYYY-MM-DD'
                              )}
                            </span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="licensePlateNumber"
                          header="차량번호"
                          body={({ licensePlateNumber }) => (
                            <span>{licensePlateNumber}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          header="주행거리"
                          body={({ distanceDriven }) => (
                            <span>{distanceDriven}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="producingCountry"
                          header="국산/수입"
                          body={({ producingCountry }) => (
                            <div>
                              {producingCountry === 'F' ? '수입' : '국산'}
                            </div>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="vin"
                          header="차대번호"
                          body={({ vin }) => <div>{vin}</div>}
                        />
                        <Column
                          align={'center'}
                          field="cnm"
                          header="차량명"
                          body={({ cnm }) => <div>{cnm}</div>}
                        />
                        <Column
                          align={'center'}
                          field="fuelName"
                          header="연료"
                          body={({ fuelName }) => <span>{fuelName}</span>}
                        />
                        <Column
                          align={'center'}
                          field="modelYear"
                          header="연식"
                          body={({ modelYear }) => <span>{modelYear}</span>}
                        />
                        <Column
                          align={'center'}
                          field="transmissionName"
                          header="변속기"
                          body={({ transmissionName }) => (
                            <span>{transmissionName}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          header="최초등록일"
                          body={({ registrationDate }) => (
                            <span>
                              {dayjs(registrationDate, 'YYYYMMDD').format(
                                'YYYY-MM-DD'
                              )}
                            </span>
                          )}
                        />
                      </DataTable>
                    ) : (
                      <DataTable
                        selectionMode={rowClick ? null : 'radiobutton'}
                        selection={selectedCustomer}
                        onSelectionChange={(e) => setSelectedCustomer(e.value)}
                        loading={loading}
                        value={data}
                        lazy
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={totalRecords}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="데이터가 없습니다."
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                        rowsPerPageOptions={COUNT_PER_PAGE}
                        resizableColumns
                        showGridlines
                        onPage={(event) => {
                          setLazyParams(event);
                        }}
                        dataKey="customerCompanyId"
                        footerColumnGroup={
                          <ColumnGroup>
                            <Row>
                              <Column
                                colSpan={2}
                                footerClassName="text-right bg-yellow-200"
                                footer="전체 합계"
                              />
                              <Column
                                align={'center'}
                                footerClassName="bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalCountDomestic
                                      )
                                    : 0
                                }
                              />
                              <Column
                                align={'center'}
                                footerClassName="bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalCountForeign
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalSumDomestic
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalSumForeign
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        Number(sumData?.totalSumForeign) +
                                          Number(sumData?.totalSumDomestic)
                                      )
                                    : 0
                                }
                              />
                            </Row>
                          </ColumnGroup>
                        }
                      >
                        <Column
                          header="순번"
                          align={'center'}
                          body={(rowData, column) => (
                            <span>{column.rowIndex + 1}</span>
                          )}
                        />
                        {myRoleCode === 'A_MST' ? (
                          <Column
                            style={{ fontWeight: '600' }}
                            field="customerCompanyName"
                            header="소속상사"
                            body={({
                              customerCompanyName,
                              customerCompanyId,
                            }) => (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setCompanyId(customerCompanyId);
                                  setCompanyName(customerCompanyName);
                                  setShowDetail(true);
                                }}
                              >
                                {customerCompanyName}
                              </span>
                            )}
                          />
                        ) : (
                          <Column
                            header="매도일"
                            body={({ sellDate }) => (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setShowDetail(true);
                                  setCurDay(sellDate);
                                }}
                              >
                                {dayjs(sellDate, 'YYYYMMDD').format(
                                  'YYYY-MM-DD'
                                )}
                              </span>
                            )}
                          />
                        )}
                        <Column
                          align={'center'}
                          field="countDomestic"
                          header="국산차량 수"
                          body={({ countDomestic }) => (
                            <div>{countDomestic}</div>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="countForeign"
                          header="수입차량 수"
                          body={({ countForeign }) => <div>{countForeign}</div>}
                        />
                        <Column
                          align={'right'}
                          field="sumDomestic"
                          header="국산차량 금액"
                          body={({ sumDomestic }) => (
                            <span>{priceFormatter.format(sumDomestic)}</span>
                          )}
                        />
                        <Column
                          align={'right'}
                          field="sumForeign"
                          header="수입차량 금액"
                          body={({ sumForeign }) => (
                            <span>{priceFormatter.format(sumForeign)}</span>
                          )}
                        />
                        <Column
                          align={'right'}
                          field="sumTotal"
                          header="총합계 금액"
                          body={({ sumDomestic, sumForeign }) => (
                            <span>
                              {priceFormatter.format(
                                Number(sumDomestic) + Number(sumForeign)
                              )}
                            </span>
                          )}
                        />
                      </DataTable>
                    )}
                  </div>

                  {/* //ANCHOR - 상세내역 */}
                  {showDetail && (
                    <SafePaymentDetail
                      visible
                      onHide={() => {
                        setShowDetail(false);
                      }}
                      date={date}
                      companyId={companyId}
                      companyName={companyName}
                      loading={loading}
                      day={curDay}
                      checkType={checkType}
                    />
                  )}

                  {showModal.photoUpload && (
                    <UploadPhotoPopup
                      onHide={() => {
                        setFileItems([]);
                        setChangeReason('');
                        setShowModal({ ...showModal, photoUpload: false });
                      }}
                      setChangeReason={setChangeReason}
                      changeReason={changeReason}
                      setFileItems={setFileItems}
                      fileItems={fileItems}
                      loading={uploadLoading}
                      curSafe6Info={curSafe6Info.safe6Info}
                      setFileDate={setFileDate}
                      uploadPhoto={uploadPhoto}
                    />
                  )}
                  {showModal.fileUpload && (
                    <ImagesPopup
                      onHide={() => {
                        setFileItems([]);
                        setShowModal({ ...showModal, fileUpload: false });
                      }}
                      setFileItems={setFileItems}
                      fileItems={fileItems}
                      loading={uploadLoading}
                      fileDate={fileDate}
                      setFileDate={setFileDate}
                      uploadExcelData={uploadExcelData}
                    />
                  )}
                  {showModal.change && (
                    <Modal.Default
                      header={'Safe6 대상 변경 요청'}
                      onHide={() => {
                        setChangeReason('');
                        setShowModal({ ...showModal, change: false });
                      }}
                      widthClass={'30rem'}
                    >
                      <p className="font-bold">
                        대상변경 승인 요청을 하시겠습니까?
                      </p>
                      <div className="p-2">
                        <p>요청사유</p>
                        <Dropdown
                          placeholder="요청사유 선택"
                          className="w-full"
                          options={Y_REASON}
                          onChange={(e) => setChangeReason(e.value)}
                          value={changeReason}
                        />
                      </div>
                      <div className="flex justify-content-between mt-4 px-2">
                        <Button.Default
                          type="button"
                          label="취소"
                          icon="pi pi-times"
                          className=" p-button-outlined"
                          onClick={() => {
                            setChangeReason('');
                            setShowModal({ ...showModal, change: false });
                          }}
                        />
                        <Button.Default
                          type="button"
                          label="승인요청"
                          icon="pi pi-check"
                          className=" p-button"
                          onClick={() => {
                            uploadPhoto();
                            setShowModal({ ...showModal, change: false });
                          }}
                        />
                      </div>
                    </Modal.Default>
                  )}
                  {showModal.detail && curSafe6Info && (
                    <Modal.Default
                      onHide={() =>
                        setShowModal({ ...showModal, detail: false })
                      }
                      header={`상세정보`}
                    >
                      <DataTable
                        value={[curSafe6Info.safe6Info]}
                        dataKey="dataId"
                      >
                        <Column field="statementNumber" header="성능번호" />
                        <Column field="licensePlateNumber" header="차량번호" />
                        <Column field="insuranceName" header="보험사" />
                        <Column field="insurancePrice" header="보험료" />
                        <Column field="insurancePriceVat" header="보험료 VAT" />
                      </DataTable>

                      <div className="mt-4 mb-2 flex align-items-center text-lg">
                        <i className="pi pi-file mr-1" />
                        증빙자료
                      </div>
                      {!!curSafe6Info.photoInfo.length ? (
                        curSafe6Info.photoInfo.map((item, idx) => (
                          <Image
                            key={idx}
                            src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                            alt={item.originalFileName}
                            preview
                            imageClassName="border-round border-1 border-200"
                            imageStyle={{
                              width: 44,
                              height: 44,
                            }}
                          />
                        ))
                      ) : (
                        <div>- 첨부된 파일이 없습니다.</div>
                      )}
                    </Modal.Default>
                  )}

                  {showSmsModal && (
                    <SendSMS
                      loading={smsLoading}
                      showSmsType="SAFE6_CUSTOMER"
                      smsData={smsData}
                      setSmsData={setSmsData}
                      customerInfo={searchInfo}
                      onHide={() => {
                        setShowSmsModal(!showSmsModal);
                      }}
                      postSms={postSms}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SafePaymentList, comparisonFn);

const UploadPhotoPopup = ({
  onHide,
  setFileItems,
  loading,
  fileItems,
  curSafe6Info,
  setFileDate,
  uploadPhoto,
  setChangeReason,
  changeReason,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    // maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({ documentCode: '6_NONTARGET', file: v, state: 'I' });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    },
  });

  return (
    <>
      <Modal.Default
        header={`Safe6 비대상 변경 요청`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <p className="font-bold">대상변경 승인 요청을 하시겠습니까?</p>
          <div className="p-2">
            <p>요청사유</p>
            <Dropdown
              placeholder="선택"
              className="w-full"
              options={N_REASON}
              onChange={(e) => setChangeReason(e.value)}
              value={changeReason}
            />
          </div>
          <div className="flex flex-column">
            {/* 증빙서류 첨부기능 */}
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  파일을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
            <div className="p-2">
              {fileItems.map((item, idx) => {
                const { state = FileState.Loaded } = item;
                if (state !== FileState.Deleted) {
                  return (
                    <div
                      key={`FILE_${idx}`}
                      className="grid border-1 border-300 border-round-sm mt-2"
                    >
                      <div className="col-12 sm:col-9">
                        <div className="flex flex-auto align-items-center justify-content-start">
                          {_.has(item, 'imgId') ? (
                            <>
                              <Image
                                src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                                alt={item.originalFileName}
                                preview
                                imageClassName="border-round border-1 border-200"
                                imageStyle={{
                                  width: 44,
                                  height: 44,
                                }}
                              />
                              <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                <span>{item.originalFileName}</span>
                                <Badge
                                  value={`${filesize(item.fileSize, {
                                    round: 1,
                                    standard: 'jedec',
                                  })}`}
                                  severity="info"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Image
                                src={item.file.preview}
                                alt={item.file.name}
                                preview
                                imageClassName="border-round border-1 border-200"
                                imageStyle={{
                                  width: 44,
                                  height: 44,
                                }}
                              />
                              <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                <span>{item.file.name}</span>
                                <Badge
                                  value={`${filesize(item.file.size, {
                                    round: 1,
                                    standard: 'jedec',
                                  })}`}
                                  severity="info"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-12 sm:col-3 text-right">
                        <Button.Default
                          type="button"
                          icon="pi pi-trash"
                          className="p-button-danger p-button-outlined"
                          onClick={() => {
                            const clonedFiles = [...fileItems];
                            const clonedFile = clonedFiles[idx];

                            if (_.has(clonedFile, 'imgId')) {
                              clonedFiles[idx] = {
                                ...clonedFile,
                                state: FileState.Deleted,
                              };
                            } else {
                              clonedFiles.splice(idx, 1);
                            }
                            setFileItems(clonedFiles);
                          }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="flex justify-content-between mt-4 px-2">
              <Button.Default
                type="button"
                label="취소"
                icon="pi pi-times"
                className=" p-button-outlined"
                loading={loading}
                onClick={() => {
                  setFileItems([]);
                  setChangeReason('');
                  onHide(true);
                }}
              />
              <Button.Default
                type="button"
                label="승인요청"
                icon="pi pi-check"
                className=" p-button"
                loading={loading}
                onClick={() => {
                  uploadPhoto();
                  onHide(true);
                }}
              />
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};
const ImagesPopup = ({
  onHide,
  setFileItems,
  loading,
  fileItems,
  fileDate,
  setFileDate,
  uploadExcelData,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles);
      setFileItems([...acceptedFiles]);
    },
  });
  return (
    <>
      <Modal.Default
        header={`매도데이터 파일 업로드`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            {!!fileItems?.length ? (
              <div className="p-2">
                <Panel header="파일정보">
                  <div>- 이름 : {fileItems[0].name}</div>
                </Panel>
                <Panel header="등록기간" className="mt-2">
                  <div className="flex gap-2 align-items-center">
                    <div>- 월 선택 :</div>
                    <Calendar
                      showIcon
                      id="searchMonth"
                      value={fileDate}
                      onChange={(e) => setFileDate(e.value)}
                      view="month"
                      dateFormat="yy년 mm월"
                    />
                  </div>
                </Panel>
                <div className="flex align-items-center gap-6 justify-content-center mt-4">
                  <Button.Default
                    type="button"
                    label="취소"
                    icon="pi pi-times"
                    className=" p-button-outlined"
                    loading={loading}
                    onClick={() => {
                      setFileItems([]);
                      onHide(true);
                    }}
                  />
                  <Button.Default
                    type="button"
                    label="확인"
                    icon="pi pi-check"
                    className=" p-button"
                    loading={loading}
                    onClick={() => {
                      uploadExcelData();
                      onHide(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                <div
                  {...getRootProps({
                    className:
                      'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                    style: { wordBreak: 'keep-all' },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                    <i
                      className="pi pi-arrow-circle-down"
                      style={{ fontSize: '1.5rem' }}
                    />
                    파일을 끌어다 놓거나 클릭하여 등록해주세요.
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};

// 초기 비대상 사유
const ORIGIN_N_REASON = [
  {
    value: 'A21',
    label: '개시이전판매',
  },
  {
    value: 'A22',
    label: '가입제외차종',
  },
  {
    value: 'A23',
    label: '연식초과',
  },
  {
    value: 'A24',
    label: '주행거리초과',
  },
  {
    value: 'A25',
    label: '예외적용차종',
  },
  {
    value: 'A26',
    label: '미계약상사',
  },
  {
    value: 'A27',
    label: 'AS잔존',
  },
  {
    value: 'A28',
    label: '계약종료상사',
  },
  {
    value: 'A31',
    label: '상사이전',
  },
  {
    value: 'A50',
    label: '직접입력',
  },
];

// 대상 -> 비대상 변경요청 사유
const N_REASON = [
  { value: 'A32', label: '수출말소' },
  { value: 'A33', label: '경매말소' },
  { value: 'A31', label: '상사이전' },
  { value: 'A34', label: '위탁말소' },
  { value: 'A35', label: '환불차량' },
];

// 비대상 -> 대상 변경요청 사유
const Y_REASON = [
  { value: 'A23', label: '연식초과' },
  { value: 'A22', label: '가입제외차종' },
  { value: 'A27', label: 'AS잔존' },
];

// 승인대기, 거절 상태
const REQUEST_STATUS = [
  { value: 'A01', label: '승인대기' }, // 비대상요청
  { value: 'A02', label: '' }, // 비대상요청 승인
  { value: 'A03', label: '거절' }, // 비대상요청 거절
  { value: 'A11', label: '승인대기' }, // 대상요청
  { value: 'A12', label: '' }, // 대상요청 승인
  { value: 'A13', label: '거절' }, // 대상요청 거절
];
