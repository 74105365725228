import * as Modal from '../../components/Atoms/Modal';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Button from '../../components/Atoms/Button';
import Claim from '../../services/ClaimService';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DOC_TYPE } from '../../constants/Constants.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import ImageBox from '../../components/Common/Claim/ImageBox.jsx';

const BillingDialog = () => {
  const reportRef = useRef();
  const { id } = useParams();
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const aid = myInfo.contents.associationInfo?.associationId;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([]); // claimDocs
  const [statusCode, setStatusCode] = useState('Z01');
  const [userNames, setUserNames] = useState({
    firstId: '',
    firstName: '',
    lastId: '',
    lastName: '',
  });
  const [detailData, setDetailData] = useState({
    dataId: 0,
    funnelsType: '',
    claimStatus: '',
    customerName: '',
    customerContact: '',
    checkFlag: '',
    assuranceType: '',
    addType: '',
    shopId: null,
    statementNumber: null,
    carNbr: '',
    newCarNbr: null,
    vin: null,
    checkDate: null,
    buyDate: '',
    checkDistance: null,
    distanceDriven: 0,
    vehicleValue: 0,
    producingCountry: null,
    carName: null,
    carAddress: '',
    carLocation: '',
    diagnosisType: null,
    consultationGroupId: '',
    partnerInfo: [],
    receiptId: null,
    receiptName: '',
    receiptDate: '',
    documentGroupId: '',
    note: '',
    partnerReceiptDate: null,
    maintenanceCode: null,
    workYN: null,
    workFee: null,
    insuranceCode: '',
    insurancePolicyNumber: '',
    insurancePurchaseDate: '',
    insurancePaymentAmt: 0,
    deductibleAmt: null,
    partnerNote: null,
    repairNote: null,
    repairOpinion: null,
    completeYN: '',
    completeDate: '',
    completeLoginId: '',
    completeLoginContact: '',
    completeName: '',
    certCode: '',
    dealerContact: null,
  });
  const [completeDate, setCompleteDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  function calculateElapsedDays(targetDateString) {
    const today = dayjs();
    const targetDate = dayjs(targetDateString);
    const elapsedDays = today.diff(targetDate, 'day');
    return elapsedDays;
  }

  const [statusList, setStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      setStatusList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [insuranceList, setInsuranceList] = useState([]);
  const getInsuranceList = async () => {
    try {
      const data = await Claim.getInsurances();
      const list = data.filter(
        (el) => el.label?.includes('KB') || el.label?.includes('DB')
      );
      setInsuranceList(list);
    } catch (error) {
      console.log(error);
    }
  };

  function findLabelByCode(data, code) {
    const result = data.find((item) => item.codeData.insuranceCode === code);
    return result ? result.label : null;
  }

  useEffect(() => {
    getInsuranceList();
    getStatusList();
  }, []);

  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = await Claim.getDetail(dataId);
      if (data) {
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setStatusCode(data.claimData.claimStatus);

        setCompleteDate(
          data.claimData?.completeDate
            ? dayjs(data.claimData?.completeDate).toDate()
            : ''
        );
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (myInfo.contents) {
      setUserNames({
        ...userNames,
        lastId: myInfo.contents.userId,
        lastName: myInfo.contents.userName,
      });
    }
  }, [myInfo.contents]);

  //ANCHOR -  클레임 POST, PUT
  const registerClaim = async () => {
    setLoading(true);
    let fileDataList = [];
    let filesList = [];
    let partnerFiles = [];

    const Data = {
      dataId: detailData?.dataId,
      shopId: detailData.shopId,
      associationId: aid,
      addType: detailData.addType,
      funnelsType: detailData.funnelsType,
      claimStatus: showModal ? 'Z20' : statusCode,
      customerName: detailData?.customerName,
      customerContact: detailData?.customerContact,
      checkFlag: detailData.checkFlag,
      assuranceType: detailData.assuranceType,
      statementNumber: detailData?.statementNumber,
      carNbr: detailData.carNbr,
      vin: detailData?.vin,
      newCarNbr: detailData.newCarNbr,
      vehicleValue: detailData.vehicleValue,
      checkDate: detailData?.checkDate,
      buyDate: detailData.buyDate,
      checkDistance: detailData.checkDistance,
      distanceDriven: detailData.distanceDriven,
      producingCountry: detailData.producingCountry,
      carName: detailData.carName,
      carAddress: detailData.carAddress,
      carLocation: detailData.carLocation,
      diagnosisType: detailData.diagnosisType,
      consultationGroupId: detailData?.consultationGroupId,
      partnerInfo: null,
      receiptId: userNames.firstId ? userNames.firstId : myInfo.contents.userId,
      receiptName: userNames.firstName
        ? userNames.firstName
        : myInfo.contents.userName,
      receiptDate: detailData.receiptDate,
      documentGroupId: detailData?.documentGroupId,
      note: detailData.note,
      partenerReceiptDate: detailData.partnerReceiptDate,
      maintenanceCode: detailData.maintenanceCode,
      workYN: detailData.workYN,
      workFee: detailData.workFee,
      insuranceCode: detailData.insuranceCode,
      insurancePolicyNumber: detailData.insurancePolicyNumber,
      insurancePurchaseDate: detailData.insurancePurchaseDate,
      insurancePaymentAmt: detailData.insurancePaymentAmt,
      deductibleAmt: detailData.deductibleAmt,
      partnerNote: detailData.partnerNote,
      repairNote: detailData.repairNote,
      repairOpinion: detailData.repairOpinion,
      completeYN: detailData.completeYN,
      completeDate: completeDate
        ? dayjs(completeDate).format('YYYYMMDD')
        : null,
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeName: myInfo.contents.userName, //Opt 로그인유저 이름
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };

    try {
      const data = await Claim.updateClaim(Data, filesList, partnerFiles);
      if (data) {
        setShowModal(false);
        setIsChanged(false);
        window.cerp.toast.success('저장이 완료 되었습니다.');
        getDetailData(data.dataId);
        sessionStorage.removeItem('images');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(
        '저장 실패',
        '페이지 새로고침 후 다시 시도해주세요.'
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!id) {
      getDetailData(id);
    }
  }, [id]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('ko-KR').format(number);
  };

  const saveFilesAsZip = async () => {
    window.cerp.toast.info('다운로드를 시작합니다.');

    const partnerFiles = [];
    detailData.partnerInfo.map((partner) =>
      partner.partnerDocuments.map((doc) => partnerFiles.push(doc))
    );

    const folderName = detailData.carNbr;
    const files = docs.concat(partnerFiles);
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);

    const reportPDF = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    const papers = reportRef.current.querySelectorAll('.paper');
    const pdfWidth = 297;
    const pdfHeight = 210;

    if (papers.length > 0) {
      const now = dayjs().format('YYYY-MM-DD HH:mm:ss');

      for (let i = 0; i < papers.length; i++) {
        const page = papers[i];

        const canvas = await html2canvas(page, {
          scale: 2,
          logging: true,
          allowTaint: true,
          useCORS: true,
          imageTimeout: 0,
          backgroundColor: 'transparent',
        });

        const pageImage = canvas.toDataURL('image/png');
        const imgWidth = 297 - 20;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const x = (pdfWidth - imgWidth) / 2; // 가로 중앙 정렬

        reportPDF.addImage(pageImage, 'PNG', x, 0, imgWidth, imgHeight);
        if (i < papers.length - 1) {
          reportPDF.addPage();
        }
      }
    }

    const pdfBlob = reportPDF.output('blob');
    imagesFolder.file(`차량 기본 정보, 마감 정보.pdf`, pdfBlob);

    const imagePromises = files
      .filter((file) => file.documentCode !== 'C_FRONT')
      .map(async (file, index) => {
        let docType = DOC_TYPE.find((doc) => doc.value === file.documentCode);
        if (file.documentCode === 'C_ORIGINALDOCS') {
          docType = {
            ...docType,
            category: '매매상사접수서류',
            label: '자동차등록원부(갑)',
          };
        }
        const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(
          file.originalFileName
        );
        const fileExtension = isImage
          ? '.jpg'
          : file.originalFileName.slice(file.originalFileName.lastIndexOf('.'));
        const fileName = docType
          ? `${docType.category}/${index + 1}_${docType.label}.${fileExtension}`
          : `${index + 1}_${file.documentCode}.${fileExtension}`;
        const imgBlob = await Claim.getBlobFromUrl(file.filePath);
        imagesFolder.file(fileName, imgBlob, { binary: true });
      });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.success('다운로드가 완료되었습니다.');
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const papers = reportRef.current.querySelectorAll('.paper');
      if (papers.length === 0) return false;

      return reportRef.current;
    },
    pageStyle: `
    @page {
        size: A4 portrait;
        margin: 0 !important;
        padding: 0 !important;
      }    
    `,
    onBeforePrint: async () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (completeDate && isChanged) {
      setShowModal(true);
    }
  }, [completeDate]);

  return (
    <div ref={reportRef}>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
          <div className="flex md:justify-content-between justify-content-start">
            <div className="flex align-items-center gap-2 ">
              <div className="font-bold ml-2 text-xl md:block hidden">
                성능 보험 지급 관리
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <div className="flex align-items-center">*업무 진행상태 :</div>
                <Dropdown
                  value={statusCode}
                  onChange={(e) => {
                    setStatusCode(e.target.value);
                  }}
                  options={statusList}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full md:w-16rem font-bold overflow-hidden"
                />
              </div>

              <Button.Default
                className="sm:text-lg sm:text-base"
                icon="pi pi-save"
                label={'저장'}
                onClick={() => registerClaim()}
              />
              <Button.Default
                className="sm:text-lg sm:text-base p-button-outlined"
                icon="pi pi-download"
                label={'이미지 전체 다운로드'}
                onClick={() => saveFilesAsZip()}
              />
              {/* <Button.Default
                className="sm:text-lg sm:text-base p-button-outlined"
                icon="pi pi-print"
                label={'출력'}
                onClick={() => handlePrint()}
              /> */}
              <Button.Default
                className="p-button-outlined p-button-danger"
                icon="pi pi-times"
                label=""
                onClick={() => {
                  sessionStorage.removeItem('images');
                  sessionStorage.removeItem('data');
                  history.push('/compensation/billing/list');
                }}
              />
            </div>
          </div>
        </div>
        <div className="claimPaper">
          <div className="inner">
            {/* 기본 정보 테이블 */}
            <h3 className="font-bold text-center pt-6">
              에스알 성능 보험 지급 관리
            </h3>
            <div className="paper flex flex-wrap justify-content-center gap-2 pb-3">
              <table className="bg-white">
                <thead>
                  <tr>
                    <th colSpan="6" className="header">
                      차량 기본 정보
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="subject">차명</td>
                    <td className="content">{detailData.carName || '-'}</td>
                    <td className="subject">차량번호</td>
                    <td className="content">{detailData.carNbr || '-'}</td>
                    <td className="subject">변경된 차량번호</td>
                    <td className="content">{detailData.newCarNbr || '-'}</td>
                  </tr>
                  <tr>
                    <td className="subject">차대번호</td>
                    <td colSpan="5" className="merged">
                      {detailData.vin || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">소유자</td>
                    <td className="content">
                      {detailData?.customerName || '-'}
                    </td>
                    <td className="subject">연락처</td>
                    <td className="content">
                      {detailData?.customerContact
                        ? CommonUtil.Formatter.phone(
                            detailData?.customerContact
                          )
                        : '-'}
                    </td>
                    <td className="subject">접수일자</td>
                    <td className="content">
                      {detailData?.receiptDate
                        ? dayjs(detailData.receiptDate).format('YYYY-MM-DD')
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">차량 인도일 (경과일수)</td>
                    <td className="content">
                      {detailData?.buyDate
                        ? `${dayjs(detailData?.buyDate).format(
                            'YYYY-MM-DD'
                          )} (${calculateElapsedDays(
                            detailData.buyDate
                          )}일 경과)`
                        : '-'}
                    </td>
                    <td className="subject">성능 킬로수</td>
                    <td className="content">
                      {detailData?.checkDistance
                        ? `${formatNumber(detailData?.checkDistance)}Km`
                        : '-'}
                    </td>
                    <td className="subject">접수 킬로수</td>
                    <td className="content">
                      {detailData?.distanceDriven
                        ? `${formatNumber(detailData?.distanceDriven)}Km`
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">성능점검일</td>
                    <td className="content">{detailData?.checkDate || '-'}</td>
                    <td className="subject">차량가액</td>
                    <td colSpan="3" className="content">
                      {detailData?.vehicleValue
                        ? `${formatNumber(detailData?.vehicleValue)}원`
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">증상</td>
                    <td colSpan="5" className="merged">
                      {detailData?.note}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="bg-white">
                <thead>
                  <tr>
                    <th colSpan="6" className="header">
                      마감정보
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="subject">청구일</td>
                    <td colSpan="5" className="content">
                      <div className=" grid lg:col-4 col align-items-center">
                        <div className="w-18rem">
                          <Calendar
                            value={completeDate}
                            onChange={(e) => {
                              setCompleteDate(e.value);
                              setIsChanged(true);
                            }}
                            dateFormat="yy-mm-dd"
                            showButtonBar
                            showIcon
                            mask="9999-99-99"
                            placeholder="청구일 선택"
                            className="w-18rem"
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">보험사</td>
                    <td className="content">
                      {findLabelByCode(insuranceList, detailData.insuranceCode)}
                    </td>
                    <td colSpan="2" className="subject">
                      증권번호
                    </td>
                    <td colSpan="2" className="content">
                      {detailData.insurancePolicyNumber || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="subject">총 지급액</td>
                    <td colSpan="4" className="content">
                      {!!detailData.insurancePaymentAmt
                        ? `${formatNumber(detailData.insurancePaymentAmt)}원`
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Divider />
            <div className="section_title bg-blue-100 border-blue-300">
              <p>차량 기본 사진(고객, 딜러)</p>
            </div>
            <div className="imageWrap">
              <ImageBox title={'성능기록부'} code={'S_CHECK1'} docs={docs} />
              <ImageBox
                title={'매매계약서 (업소보관용)'}
                code={'S_CONTRACT'}
                docs={docs}
              />
              <ImageBox
                title={'자동차등록증'}
                code={'C_CERTIFICATE'}
                docs={docs}
              />
              <ImageBox
                title={'자동차등록원부(갑)'}
                code={'S_CHECK2'}
                docs={docs}
              />
              <ImageBox title={'리콜'} code={'C_RECALL'} docs={docs} />
              <ImageBox
                title={'현재 기준 계기판 사진'}
                code={'C_DASHBOARD'}
                docs={docs}
              />
              <ImageBox title={'기타사진'} code={'C_ETC'} docs={docs} />
              <ImageBox
                title={'개인정보 동의서'}
                code={'C_CONSENT'}
                docs={docs}
              />
            </div>
            {detailData?.partnerInfo.map((partnerData) => {
              return (
                <div key={partnerData.partnerId}>
                  <div className="section_title relative flex align-contents-center justify-content-center bg-blue-100 border-blue-100 border-blue-300">
                    <p className="m-0">
                      [{partnerData.partnerName}] 입고점검사진
                    </p>
                  </div>
                  <div className="imageWrap">
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      title={'소견서'}
                      code={'P_OPINION'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      title={'입고 차량전면사진'}
                      code={'P_FRONT'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      title={'입고 계기판사진'}
                      code={'P_DASHBOARD'}
                    />
                    <ImageBox
                      partnerData={partnerData.partnerDocuments}
                      partner={true}
                      title={'점검사진'}
                      code={'P_CHECK'}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="">
            <div className="claim claim_paper_wrapper">
              {detailData?.partnerInfo.map((partnerData) => {
                return (
                  <div className="inner" key={partnerData?.partnerId}>
                    <div className="section_title bg-blue-100 border-blue-100 border-blue-300">
                      <p>[{partnerData.partnerName}] 수리 완료 사진</p>
                    </div>
                    <div className="imageWrap">
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'보험금청구동의서'}
                        code={'P_INSURANCE_TEMPLATE'}
                      />
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'승인견적서'}
                        code={'P_APPROVED'}
                      />
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'사업자등록증'}
                        code={'P_REGISTRATION'}
                      />
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'사업자통장사본'}
                        code={'P_BANKBOOK'}
                      />
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'기타'}
                        code={'P_ETC'}
                      />
                      <ImageBox
                        partnerData={partnerData.partnerDocuments}
                        partner={true}
                        repairCenter={true}
                        title={'정비사진'}
                        code={'P_MAINTENANCE'}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {showModal && (
          <Modal.Default
            onHide={() => {
              setIsChanged(false);
              setShowModal(false);
            }}
            header={'청구완료 저장'}
            widthClass="30rem"
          >
            <div className="pb-2">아래 정보를 다시 한번 확인해주세요.</div>
            <div>
              <strong className="text-lg">
                - 청구일 : {dayjs(completeDate).format('YYYY-MM-DD')}
              </strong>
            </div>
            <Divider />
            <div className="flex gap-6 justify-content-center">
              <Button.Default
                label="취소"
                className="p-button-danger p-button-outlined"
                onClick={() => {
                  setIsChanged(false);
                  setShowModal(false);
                }}
              />
              <Button.Default
                label="저장"
                onClick={() => {
                  registerClaim();
                }}
                loading={loading}
              />
            </div>
          </Modal.Default>
        )}
      </BlockUI>
    </div>
  );
};

export default BillingDialog;
