import React, { useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';

const MIN_YEAR = '2010';

const RangeCalendar = ({
  startDate = null,
  endDate = null,
  onStartDateChanged,
  onEndDateChanged,
  touchUI = false,
  showNavigators = false,
  yearRange = '',
  rangeEndDate = null,
  disabled = false,
  startDisabled = false,
}) => {
  let today = dayjs();

  startDate = startDate || today.startOf('month').toDate();
  // endDate = endDate || today.endOf('month').toDate();
  // endDate = endDate || today.toDate();

  // console.log('[달력]', today, today.toDate());
  const startRef = useRef();
  const startInputRef = useRef();
  const endRef = useRef();
  const endInputRef = useRef();
  // const rangeEndDate = today.toDate();

  return (
    <div className="flex flex-auto align-items-center justify-content-start">
      <Calendar
        ref={startRef}
        inputRef={startInputRef}
        dateFormat="yy-mm-dd"
        showButtonBar
        showIcon
        mask="9999-99-99"
        monthNavigator={showNavigators}
        yearNavigator={showNavigators}
        yearRange={yearRange || `${MIN_YEAR}:${today.year() + 1}`}
        onViewDateChange={(e) => {
          const { value } = e;
          const formattedValue = dayjs(value).date(1).toDate();
          onStartDateChanged(formattedValue);
        }}
        touchUI={touchUI}
        maxDate={rangeEndDate || today.toDate()}
        value={startDate}
        onChange={(e) => {
          const {
            value,
            originalEvent: { key },
          } = e;
          if (value !== null) onStartDateChanged(value);
          if (key === 'Enter') {
            if (value === null) {
              onStartDateChanged(startDate);
              startInputRef.current.select();
            }
            startRef.current.hideOverlay();
          }
        }}
        disabled={disabled || startDisabled}
      />
      <i className="pi pi-arrow-right mx-3" />
      <Calendar
        ref={endRef}
        inputRef={endInputRef}
        dateFormat="yy-mm-dd"
        showButtonBar
        showIcon
        mask="9999-99-99"
        monthNavigator={showNavigators}
        yearNavigator={showNavigators}
        yearRange={yearRange || `${MIN_YEAR}:${today.year() + 1}`}
        onViewDateChange={(e) => {
          const { value } = e;
          const formattedValue = dayjs(value).date(1).toDate();
          onEndDateChanged(formattedValue);
        }}
        touchUI={touchUI}
        minDate={startDate}
        maxDate={rangeEndDate || today.toDate()}
        value={endDate}
        onChange={(e) => {
          const {
            value,
            originalEvent: { key },
          } = e;
          if (value !== null) onEndDateChanged(value);
          if (key === 'Enter') {
            if (value === null) {
              onEndDateChanged(endDate);
              endInputRef.current.select();
            }
            endRef.current.hideOverlay();
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default RangeCalendar;
