import AxiosUtil from '../utils/axiosUtil';
import axios from 'axios';
import _ from 'lodash';

const BASE_URI = '/apis/claim';

export const Claim = {
  /**
   * carmon, iw 양쪽에서 성능점검 여부 조회
   * @param {string} licensePlateNumber
   * @param {string} statementNumber
   * @param {string} vin
   * @returns
   */
  ClaimPerfomanceCheck: async (licensePlateNumber, statementNumber, vin) => {
    const res = await axios.get('apis/performance-checks/sr', {
      params: {
        licensePlateNumber: licensePlateNumber,
        statementNumber: statementNumber,
        vin: vin,
      },
    });
    return res.data.data;
  },
  homeInfo: async () => {
    const res = await axios.get(`${BASE_URI}/dashboard`);
    return res.data.data;
  },

  getList: async (data) => {
    const mappedParams = {
      ...data,
    };

    // 쿼리 파라미터로 변환 (null이 아닌 값만 포함)
    const queryString = Object.keys(mappedParams)
      .filter(
        (key) => mappedParams[key] !== null && mappedParams[key] !== undefined
      )
      .map((key) => {
        if (Array.isArray(mappedParams[key])) {
          return mappedParams[key]
            .map((value) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        }
        return `${key}=${encodeURIComponent(mappedParams[key])}`;
      })
      .join('&');

    const res = await axios.get(`${BASE_URI}?${queryString}`);
    return res.data.data;
  },

  getOldList: async (data) => {
    const res = await axios.get(`${BASE_URI}/sr`, {
      params: data,
    });
    return res.data.data;
  },

  getDetailOld: async (dataId) => {
    const res = await axios.get(`${BASE_URI}/sr/${dataId}`);
    return res.data.data;
  },

  /**
   * carmon erp 에서 성능점검 받은 차량정보 조회
   * @param {string} carNbr
   * @param {string} VIN
   * @returns 성능점검 데이터
   */
  getCheckWarranty: async (carNbr, VIN) => {
    const res = await axios.get(`${BASE_URI}/call`, {
      params: {
        carNbr: carNbr,
        VIN: VIN ? VIN : null,
      },
    });
    return res.data.data;
  },

  getDetail: async (dataId) => {
    const res = await AxiosUtil.Async.get(`${BASE_URI}/${dataId}`);
    return res.data;
  },

  registerClaim: async (data, files, pfiles) => {
    const res = await AxiosUtil.Async.multipart(
      `${BASE_URI}`,
      data,
      files,
      pfiles
    );
    return res;
  },

  getBase64Url: async function (imgUrl) {
    const encodedImgUrl = encodeURIComponent(imgUrl);
    const queryString = `imageUrl=${encodedImgUrl}`;
    return await AxiosUtil.Async.get(
      '/office/rest/extservice/base64encoding',
      queryString
    );
  },

  updateClaim: async (data, files, pfiles) => {
    const res = await AxiosUtil.Async.updateMultipart(
      `${BASE_URI}`,
      data,
      files,
      pfiles
    );
    return res.data;
  },

  deleteClaim: async (dataId) => {
    const res = await AxiosUtil.Async.delete(`${BASE_URI}/${dataId}`);
    return res.data;
  },

  getConsultation: async (data) => {
    const res = await axios.get(`${BASE_URI}/consultation`, {
      params: data,
    });
    return res.data.data;
  },

  posetConsultation: async (data) => {
    const res = await axios.post(`${BASE_URI}/consultation`, null, {
      params: data,
    });
    return res.data;
  },

  updateConsultation: async (data) => {
    const res = await axios.put(`${BASE_URI}/consultation`, null, {
      params: data,
    });
    return res.data;
  },

  getDiagnosisType: async () => {
    const res = await axios.get(`${BASE_URI}/code/maintenance`);
    return res.data.data;
  },

  getDocsType: async (type) => {
    const res = await axios.get(`${BASE_URI}/code/document/${type}`);
    return res.data;
  },

  getInsurances: async () => {
    const res = await axios.get(`/apis/codes/insurances`);
    return res.data.data;
  },

  postSMS: async (data, files) => {
    const res = await AxiosUtil.Async.multipart(`${BASE_URI}/msg`, data, files);
    return res.data;
  },

  /**
   * user_auth 없는 클레임 상세조회
   * @params carNbr, customerName, customerContact
   * @returns 클레임 상세
   */
  getClaimDetail: async (data) => {
    const res = await axios.get(`${BASE_URI}/app2`, { params: data });
    return res.data.data;
  },

  getCarDetail: async (carNbr) => {
    const res = await axios.get(`${BASE_URI}/app/${carNbr}`);
    return res.data.data;
  },

  partnerUpload: async (data, files) => {
    const res = await AxiosUtil.Async.updateMultipart(
      '/apis/claim/partner',
      data,
      files
    );
    return res.data;
  },

  dealerUploadNoAuth: async (data, files, pfiles) => {
    const res = await AxiosUtil.Async.updateMultipart(
      `${BASE_URI}/dealer/no`,
      data,
      files,
      pfiles
    );
    return res.data;
  },

  partnerUploadNoAuth: async (data, files, pfiles) => {
    const res = await AxiosUtil.Async.updateMultipart(
      '/apis/claim/partner/no',
      data,
      files,
      pfiles
    );
    return res.data;
  },

  getHolidays: async (startDate, endDate) => {
    const res = await axios.get(`/apis/searches/holidays`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
    return res.data.data;
  },

  getBlobFromUrl: async (imageUrl) => {
    const url = process.env.REACT_APP_CLOUDFRONT_BASE_URL + imageUrl;

    try {
      const res = await fetch(url);
      if (!res) {
        throw new Error(`Failed to fetch image: ${url}`);
      }
      return await res.blob();
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  },

  pushMessage: async (data) => {
    const res = await axios.post('/apis/push/message', data);
    return res.data;
  },

  exportToExcel: async (data) => {
    try {
      const mappedParams = {
        ...data,
      };

      // 쿼리 파라미터로 변환 (null이 아닌 값만 포함)
      const queryString = Object.keys(mappedParams)
        .filter(
          (key) => mappedParams[key] !== null && mappedParams[key] !== undefined
        )
        .map((key) => {
          if (Array.isArray(mappedParams[key])) {
            return mappedParams[key]
              .map((value) => `${key}=${encodeURIComponent(value)}`)
              .join('&');
          }
          return `${key}=${encodeURIComponent(mappedParams[key])}`;
        })
        .join('&');

      const res = await axios.get(`apis/excel/claims?${queryString}`, {
        params: data,
        responseType: 'blob',
      });
      const contentDisposition = res.headers['content-disposition'];
      const [, fileNameData] = _.split(contentDisposition, ';');
      const [, fileName] = _.split(fileNameData, '=');

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: res.headers['content-type'],
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        decodeURIComponent(_.replace(fileName, /"/g, ''))
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.cerp.toast.clear();
      window.cerp.toast.success('엑셀 파일 다운로드에 성공했습니다.');
    } catch (error) {
      window.cerp.toast.clear();
      console.log(error);
      window.cerp.toast.error('엑셀 다운로드에 실패했습니다.');
    }
  },
};

export default Claim;
