import React, { useEffect, useState, useCallback } from 'react';
import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { COUNT_PER_PAGE } from '../../constants/Constants.js';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import dayjs from 'dayjs';
import Settlement from '../../services/SettlementService.js';
import { Dropdown } from 'primereact/dropdown';
import { CommonUtil } from '../../utils/commonUtil.js';
import noImage from '../../assets/icons/no-image.png';

const SafePaymentDetail = ({ onHide, date, companyId, day, checkType }) => {
  const [lazyParams, setLazyParams] = useState({ first: 0, rows: 30, page: 0 });
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [payYn, setPayYn] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState({
    confirm: '',
    show: false,
  });
  const [showPhotoModal, setShowPhotoModal] = useState({
    show: false,
  });

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [curCarInfo, setCurCarInfo] = useState(null);

  useEffect(() => {
    if (checkType) {
      const title =
        checkType === 'Y' ? '대상' : checkType === 'N' ? '비대상' : '변경 요청';
      setTitle(title);
    }
  }, [checkType]);

  const getDayCompanyData = useCallback(
    async (id, page) => {
      setIsLoading(true);
      const params = {
        settlementMonth: dayjs(date).format('YYYYMM'),
        customerCompanyId: id,
        checkType: checkType,
        page: page,
        size: 30,
      };
      try {
        const data = await Settlement.getMonthCustomerCompany(params);
        if (data) {
          setTableData(data.list);
          setTotalRecords(data.total);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [checkType, date]
  );

  const getDayCustomerCompanyData = async (day, page) => {
    setIsLoading(true);
    const params = {
      settlementDay: day,
      page: page,
      size: 30,
    };
    try {
      const data = await Settlement.getDayCustomerCompany(params);
      if (data) {
        setTableData(data.list);
        setTotalRecords(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // 에스알 > 세이프6 변경요청 > 승인/거절
  const changeRequestType = async (dataId, requestType) => {
    setIsLoading(true);
    const successMsg = {
      A02: '비대상 변경 요청이 승인',
      A12: '대상 변경 요청이 승인',
      A03: '비대상 변경 요청이 거절',
      A13: '대상 변경 요청이 거절',
    };
    try {
      const data = await Settlement.approveChange({ dataId, requestType });
      if (data) {
        window.cerp.toast.success(
          'Safe6 변경 처리 완료',
          `${successMsg[requestType]} 처리되었습니다.`
        );
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(
        `Safe6 변경 처리 실패`,
        `${successMsg[requestType]} 처리되지 않았습니다.`
      );
    } finally {
      setIsLoading(false);
      setShowConfirmModal({ confirm: '', show: false });
      getDayCompanyData(companyId, lazyParams.page + 1);
    }
  };

  // 에스알 증빙서류 보기
  const getPhotoData = async (dataId) => {
    setIsLoading(true);

    try {
      const data = await Settlement.getCompanyDetail(dataId);
      if (data) {
        // console.log('data', data);
        if (data.photoInfo.length > 0) {
          sessionStorage.setItem('images', JSON.stringify(data?.photoInfo));
          CommonUtil.Window.popup(
            '/compensation/accident/claim/viewer',
            '',
            window.innerWidth * 0.9,
            window.innerHeight * 0.9
          );
        } else setShowPhotoModal({ confirm: '', show: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // 에스알 > 세이프6 대상 조회 > 상세 > 입금구분 변경
  const changeDepositType = async (dataId, requestType, depositCode) => {
    setIsLoading(true);
    try {
      const data = await Settlement.approveChange({
        dataId,
        requestType,
        depositCode,
      });
      if (data) {
        setPayYn('');
      }
    } catch (error) {
      console.log(error);
      setPayYn('');
    } finally {
      setIsLoading(false);
      setShowModal(false);
      getDayCompanyData(companyId, lazyParams.page + 1);
    }
  };

  useEffect(() => {
    if (!!companyId) {
      getDayCompanyData(companyId, lazyParams.page + 1);
    } else if (!!day) {
      getDayCustomerCompanyData(day, lazyParams.page + 1);
    }
  }, [companyId, lazyParams.page, day, getDayCompanyData]);

  return (
    <BlockUI
      template={
        isLoading && (
          <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
        )
      }
      blocked={isLoading}
    >
      <Dialog
        modal
        visible={true}
        maximizable
        breakpoints={{ '960px': '90vw' }}
        header={`Safe6 ${title} 차량 상세내역`}
        style={{ width: '90vw', height: '100vh' }}
        contentClassName="cm-dialog-content"
        onHide={onHide}
        footer={
          <div className="flex flex-auto align-items-center justify-content-center pt-4">
            <div className="flex flex-auto flex-wrap align-items-center justify-content-center gap-1">
              <Button.Default
                type="button"
                label="닫기"
                className="p-button-outlined"
                onClick={onHide}
              />
            </div>
          </div>
        }
      >
        <DataTable
          loading={isLoading}
          value={tableData}
          lazy
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={totalRecords}
          paginator
          responsiveLayout="scroll"
          emptyMessage="데이터가 없습니다."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
          rowsPerPageOptions={COUNT_PER_PAGE}
          resizableColumns
          showGridlines
          onPage={(event) => {
            setLazyParams(event);
          }}
          dataKey="dataId"
        >
          <Column
            header="순번"
            align={'center'}
            body={(rowData, column) => <span>{column.rowIndex + 1}</span>}
          />
          {!!companyId && (
            <Column
              field="customerCompanyName"
              header="소속상사"
              body={({ customerCompanyName, customerComapnyId }) => (
                <span>{customerCompanyName}</span>
              )}
            />
          )}
          <Column
            align={'center'}
            header="제시자(딜러)"
            body={({ customerName }) => <span>{customerName}</span>}
          />
          <Column
            align={'center'}
            header="성능번호"
            body={({ statementNumber, dataId }) => (
              <span
              // className="text-primary cursor-pointer"
              // onClick={() => getDetailData(dataId)}
              >
                {statementNumber}
              </span>
            )}
          />
          <Column
            align={'center'}
            field="sellDate"
            header="매도일"
            body={({ sellDate }) => (
              <span>{dayjs(sellDate, 'YYYYMMDD').format('YYYY-MM-DD')}</span>
            )}
          />
          <Column
            align={'center'}
            header="성능일"
            body={({ checkDate }) => (
              <span>{dayjs(checkDate, 'YYYYMMDD').format('YYYY-MM-DD')}</span>
            )}
          />
          <Column
            align={'center'}
            field="licensePlateNumber"
            header="차량번호"
            body={({ licensePlateNumber }) => <span>{licensePlateNumber}</span>}
          />
          <Column
            align={'center'}
            field="producingCountry"
            header="국산/수입"
            body={({ producingCountry }) => (
              <div>{producingCountry === 'F' ? '수입' : '국산'}</div>
            )}
          />
          <Column
            align={'center'}
            field="vin"
            header="차대번호"
            body={({ vin }) => <div>{vin}</div>}
          />
          <Column
            align={'center'}
            field="cnm"
            header="차량명"
            body={({ cnm }) => <div>{cnm}</div>}
          />
          <Column
            align={'center'}
            header="주행거리"
            body={({ distanceDriven }) => <span>{distanceDriven}</span>}
          />
          <Column
            align={'center'}
            field="fuelName"
            header="연료"
            body={({ fuelName }) => <span>{fuelName}</span>}
          />
          <Column
            align={'center'}
            field="modelYear"
            header="연식"
            body={({ modelYear }) => <span>{modelYear}</span>}
          />
          <Column
            align={'center'}
            field="transmissionName"
            header="변속기"
            body={({ transmissionName }) => <span>{transmissionName}</span>}
          />
          <Column
            align={'center'}
            header="최초등록일"
            body={({ registrationDate }) => (
              <span>
                {dayjs(registrationDate, 'YYYYMMDD').format('YYYY-MM-DD')}
              </span>
            )}
          />

          {/* //TODO - 납부완료, 미납, 환불, 환불_상계 선택 */}
          {checkType === 'Y' && (
            <Column
              align={'center'}
              header="입금구분"
              body={({ dataId, licensePlateNumber, safe6DepositCode }) => {
                const depositCode = safe6DepositCode ? safe6DepositCode : 'A42';
                const depositLable = PAY_NY.find(
                  (item) => item.value === depositCode
                ).label;

                return (
                  <span
                    className="text-primary cursor-pointer font-bold"
                    onClick={() => {
                      setCurCarInfo(licensePlateNumber);
                      setShowModal(true);
                      setSelectedRowData({
                        ...selectedRowData,
                        dataId: dataId,
                        depositCode: safe6DepositCode,
                      });
                    }}
                  >
                    {depositLable}
                  </span>
                  // <Dropdown
                  //   options={PAY_NY}
                  //   placeholder="선택"
                  //   onChange={(e) => setPayYn(e.value)}
                  //   value={payYn}
                  // />
                );
              }}
            />
          )}
          {/* //TODO - 입금요청 딜러앱으로 푸쉬 알림 */}
          {/* {checkType === 'Y' && (
            <Column
              align={'center'}
              header="입금요청"
              body={({ registrationDate }) => (
                <Button.Default
                  type="button"
                  label="푸시전송"
                  className="p-button-outlined py-1 px-2"
                />
              )}
            />
          )} */}

          {checkType === 'N' && (
            <Column
              align={'center'}
              field="transmissionName"
              header="비대상구분"
              body={({ safe6ReasonCode }) => {
                const matchedReason = REASON.find(
                  (item) => item.value === safe6ReasonCode
                );
                const label = matchedReason
                  ? matchedReason.label
                  : safe6ReasonCode;
                return <span>{label}</span>;
              }}
            />
          )}
          {checkType === 'Z' && (
            <Column
              align={'center'}
              field="transmissionName"
              header="대상여부"
              body={({ safe6Flag }) => {
                const safe6Yn = safe6Flag === 'Y' ? '대상' : '비대상';
                return <span>{safe6Yn}</span>;
              }}
            />
          )}
          {checkType === 'Z' && (
            <Column
              align={'center'}
              field="transmissionName"
              header="요청사유"
              body={({ safe6ReasonCode }) => {
                const matchedReason = REASON.find(
                  (item) => item.value === safe6ReasonCode
                );
                const label = matchedReason
                  ? matchedReason.label
                  : safe6ReasonCode;
                return <span>{label}</span>;
              }}
            />
          )}
          {checkType === 'Z' && (
            <Column
              align={'center'}
              field="transmissionName"
              header="요청구분"
              body={({ safe6ChangeCode }) => {
                const label =
                  safe6ChangeCode === 'A01' ? '비대상요청' : '대상요청';
                return <span>{label}</span>;
              }}
            />
          )}
          {checkType === 'Z' && (
            <Column
              align={'center'}
              field="transmissionName"
              header="증빙서류"
              body={({ dataId }) => (
                <span>
                  <Button.Default
                    label="보기"
                    className="p-button-outlined p-1 px-2"
                    onClick={() => {
                      setTimeout(() => {
                        getPhotoData(dataId);
                      }, 0);
                    }}
                  />
                </span>
              )}
            />
          )}
          {checkType === 'Z' && (
            <Column
              className="p-1 px-2"
              align={'center'}
              field="approval"
              header="승인"
              body={({ safe6ChangeCode, dataId }) => {
                if (safe6ChangeCode) {
                  return (
                    <div className="flex gap-2">
                      <Button.Default
                        label="승인"
                        className="p-button-outlined p-1 px-2"
                        onClick={() => {
                          setSelectedRowData({
                            dataId: dataId,
                            safe6ChangeCode: safe6ChangeCode,
                          });
                          setTimeout(() => {
                            setShowConfirmModal({
                              confirm: '승인',
                              show: true,
                            });
                          }, 0);
                        }}
                      />
                      <Button.Default
                        label="거절"
                        className="p-button-outlined p-1 px-2 p-button-danger"
                        onClick={() => {
                          setSelectedRowData({
                            dataId: dataId,
                            safe6ChangeCode: safe6ChangeCode,
                          });
                          setShowConfirmModal({ confirm: '거절', show: true });
                        }}
                      />
                    </div>
                  );
                }
              }}
            />
          )}
        </DataTable>
      </Dialog>
      {showConfirmModal.show && (
        <Modal.Default
          widthClass={'24rem'}
          header={'변경요청 ' + showConfirmModal.confirm}
          onHide={() => setShowConfirmModal({ confirm: '', show: false })}
        >
          <div>{showConfirmModal.confirm + ' 하시겠습니까?'}</div>
          <div className="mt-4 flex justify-content-between">
            <Button.Close
              onClose={() => setShowConfirmModal({ confirm: '', show: false })}
            />
            <Button.Default
              label="확인"
              onClick={() => {
                const requestTypeMap = {
                  승인: {
                    A01: 'A02',
                    A11: 'A12',
                  },
                  거절: {
                    A01: 'A03',
                    A11: 'A13',
                  },
                };
                const { confirm } = showConfirmModal;
                const { dataId, safe6ChangeCode } = selectedRowData;

                const requestType = requestTypeMap[confirm]?.[safe6ChangeCode];
                if (requestType) {
                  changeRequestType(dataId, requestType);
                }
              }}
            />
          </div>
        </Modal.Default>
      )}
      {showPhotoModal.show && (
        <Modal.Default
          widthClass={'24rem'}
          header={'증빙서류 확인'}
          onHide={() => setShowPhotoModal({ confirm: '', show: false })}
        >
          <div className="flex justify-content-center w-full flex-wrap">
            <img
              src={noImage}
              alt=""
              style={{ width: '60%', opacity: '0.2' }}
            />
            <p className="w-full text-center">첨부된 증빙서류가 없습니다.</p>
          </div>
          <div className="mt-4 flex justify-content-between">
            <Button.Close
              onClose={() => setShowPhotoModal({ confirm: '', show: false })}
            />
          </div>
        </Modal.Default>
      )}
      {showModal && (
        <Modal.Default
          header={'입금상태 변경'}
          widthClass={'24rem'}
          onHide={() => {
            setPayYn('');
            setShowModal(false);
          }}
        >
          <div className="p-2">
            <div className="mb-2">- 차량번호 : {curCarInfo} </div>
            <div className="mb-2">- 입금상태</div>
            <Dropdown
              className="w-full"
              options={PAY_NY}
              placeholder="선택"
              onChange={(e) => setPayYn(e.value)}
              value={payYn}
            />
            <div className="mt-4 flex justify-content-between">
              <Button.Close
                onClose={() => {
                  setPayYn('');
                  setShowModal(false);
                }}
              />
              <Button.Save
                disabled={payYn === ''}
                onSave={(e) => {
                  changeDepositType(selectedRowData.dataId, 'A99', payYn);
                }}
              />
            </div>
          </div>
        </Modal.Default>
      )}
    </BlockUI>
  );
};

export default SafePaymentDetail;

const PAY_NY = [
  { value: 'A42', label: '미납' },
  { value: 'A41', label: '납부' },
  { value: 'A43', label: '환불' },
  { value: 'A44', label: '상계' },
];

const REASON = [
  {
    value: 'A21',
    label: '개시이전판매',
  },
  {
    value: 'A22',
    label: '가입제외차종',
  },
  {
    value: 'A23',
    label: '연식초과',
  },
  {
    value: 'A24',
    label: '주행거리초과',
  },
  {
    value: 'A25',
    label: '예외적용차종',
  },
  {
    value: 'A26',
    label: '미계약상사',
  },
  {
    value: 'A27',
    label: 'AS잔존',
  },
  {
    value: 'A28',
    label: '계약종료상사',
  },
  {
    value: 'A31',
    label: '상사이전',
  },
  {
    value: 'A32',
    label: '수출말소',
  },
  {
    value: 'A33',
    label: '경매말소',
  },
  {
    value: 'A34',
    label: '위탁말소',
  },
  {
    value: 'A35',
    label: '환불차량',
  },
  {
    value: 'A50',
    label: '직접입력',
  },
];
