import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { Claim } from '../../services/ClaimService';
import dayjs from 'dayjs';
import RangeCalendar from '../../components/Common/RangeCalendar';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { shopListState, touchUIState } from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { CommonUtil } from '../../utils/commonUtil';
import { ServiceProvider } from '../../services/index';
import { Shop } from '../../services/CodeService.js';

const partnerService = ServiceProvider.partner;

const BillingList = () => {
  const history = useHistory();
  const touchUI = useRecoilValue(touchUIState);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const today = dayjs();
  const startDate = dayjs('20240701').toDate();
  const endDate = today;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 100,
    page: 0,
  });
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfoLoadable.contents.roleCode === 'D_ADM';
  const aid = myInfoLoadable.contents.associationInfo?.associationId;

  const [searchLoading, setSearchLoading] = useState(false);
  const defaultData = {
    startDate: dayjs(startDate).format('YYYYMMDD'),
    endDate: dayjs(endDate).format('YYYYMMDD'),
    page: lazyState.page + 1,
    checkType: ['Z08', 'Z11', 'Z19', 'Z20'],
    size: lazyState.rows,
    orderType: 'D',
  };

  const [searchMode, setSearchMode] = useState(false);
  const [searchTotal, setSearchTotal] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ['list', searchMode, lazyState.page, lazyState.rows],
    queryFn: () => Claim.getList(defaultData),
    enabled: !searchMode && !!aid,
    refetchInterval: 30000, // 30초 간격
  });

  const [billingSearchState, setBillingSearchState] = useState({
    startDate: dayjs('20240701').toDate(),
    endDate: dayjs().toDate(),
    shopId: null,
    carNbr: null,
    customerName: null,
    customerContact: null,
    checkType: null,
    receiptName: null,
    completeName: null,
    orderType: 'D',
  });

  const resetBillingSearchState = () => {
    setBillingSearchState({
      startDate: dayjs('20240701').toDate(),
      endDate: dayjs().toDate(),
      shopId: null,
      carNbr: null,
      customerName: null,
      customerContact: null,
      checkType: null,
      receiptName: null,
      completeName: null,
      orderType: 'D',
    });
  };
  const getSearchBillingList = async () => {
    try {
      setSearchLoading(true);
      // 상태값이 null이 아닌 것만 params 데이터로 넣음
      const filteredParams = Object.keys(billingSearchState).reduce(
        (acc, key) => {
          if (
            billingSearchState[key] !== null &&
            billingSearchState[key] !== ''
          ) {
            acc[key] = billingSearchState[key];
          }
          return acc;
        },
        {}
      );

      const data = await Claim.getList({
        ...filteredParams,
        startDate: dayjs(billingSearchState.startDate).format('YYYYMMDD'),
        endDate: dayjs(billingSearchState.endDate).format('YYYYMMDD'),
        checkType: !billingSearchState.checkType
          ? ['Z08', 'Z11', 'Z19', 'Z20']
          : billingSearchState.checkType,
        page: lazyState.page + 1,
        size: lazyState.rows,
      });
      if (data) {
        setTableData(data.list);
        setSearchTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    getSearchBillingList();
    setSearchMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyState.page]);

  useEffect(() => {
    if (data && !isLoading && !searchMode) {
      setTableData(data.list);
      setSearchMode(false);
    }
  }, [data, isLoading, searchMode]);

  const setShopListState = useSetRecoilState(shopListState);
  const shopList = useRecoilValue(shopListState);

  useEffect(() => {
    if (!!myInfoLoadable.contents.associationInfo) {
      const getShopList = async (eids) => {
        try {
          const data = await Shop.getList({ eids: eids });
          if (data) {
            setShopListState(data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      return () =>
        getShopList(myInfoLoadable.contents.associationInfo.associationId);
    }
  }, [myInfoLoadable.contents.associationInfo, setShopListState]);

  const handleSearchInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setBillingSearchState({ ...billingSearchState, [name]: value });
  };

  const resetSearch = () => {
    setSearchMode(false);
    setSearchTotal(0);
    resetBillingSearchState();
  };

  const [partnerAllList, setPartnerAllList] = useState([]);
  const getPartnerList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid,
        page: 1,
        size: 200,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!aid && !partner) {
      getPartnerList(aid);
    }
  }, [aid, partner]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchMode(true);
      getSearchBillingList();
    }
  };

  const [exporting, setExporting] = useState(false);
  const exportToExcel = async () => {
    setExporting(true);

    const filteredParams = Object.keys(billingSearchState).reduce(
      (acc, key) => {
        if (
          billingSearchState[key] !== null &&
          billingSearchState[key] !== ''
        ) {
          acc[key] = billingSearchState[key];
        }
        return acc;
      },
      {}
    );

    await Claim.exportToExcel({
      ...filteredParams,
      startDate: dayjs(billingSearchState.startDate).format('YYYYMMDD'),
      endDate: dayjs(billingSearchState.endDate).format('YYYYMMDD'),
      checkType:
        billingSearchState.checkType === null
          ? ['Z08', 'Z11', 'Z19', 'Z20']
          : billingSearchState.checkType,
      page: lazyState.page + 1,
      size: lazyState.rows,
    });
    setExporting(false);
  };

  return (
    <div>
      <BlockUI
        template={
          isLoading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      ></BlockUI>
      {partner ? null : (
        <Panel className="shadow-1 mt-3">
          <div className="grid">
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">업무처리상태</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={billingSearchState.checkType}
                  onChange={(e) => {
                    // setCheckType(e.value);
                    setBillingSearchState((ps) => ({
                      ...ps,
                      checkType: e.target.value,
                    }));
                  }}
                  options={STATUS_LIST}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full"
                />
              </span>
            </div>
            <div className="col-12 md:col-8 lg:col-4">
              <div className="mb-2 font-bold">기간</div>
              <RangeCalendar
                startDate={billingSearchState.startDate}
                endDate={billingSearchState.endDate}
                onStartDateChanged={(date) => {
                  setBillingSearchState((ps) => ({
                    ...ps,
                    startDate: date,
                  }));
                }}
                onEndDateChanged={(date) => {
                  setBillingSearchState((ps) => ({
                    ...ps,
                    endDate: date,
                  }));
                }}
                touchUI={touchUI}
                showNavigators={true}
              />
            </div>
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">성능점검장</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={billingSearchState.shopId}
                  onChange={(e) => {
                    setBillingSearchState((ps) => ({
                      ...ps,
                      shopId: e.target.value,
                    }));
                  }}
                  options={shopList}
                  optionLabel="label"
                  placeholder="점검장 선택"
                  className="w-full"
                />
              </span>
            </div>
            <div className="col-12 md:col-8 lg:col-2">
              <div className="mb-2 font-bold">접수일자 기준</div>
              <span onKeyDown={handleKeyDown}>
                <Dropdown
                  value={billingSearchState.orderType}
                  onChange={(e) => {
                    setBillingSearchState((ps) => ({
                      ...ps,
                      orderType: e.target.value,
                    }));
                  }}
                  options={[
                    { value: 'D', label: '내림차순' },
                    { value: 'A', label: '오름차순' },
                  ]}
                  optionLabel="label"
                  placeholder="정렬순서 선택"
                  className="w-full"
                />
              </span>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">이름</div>
              <InputText
                value={billingSearchState.customerName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name={'customerName'}
                placeholder={'고객명 입력'}
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">연락처</div>
              <InputText
                value={billingSearchState.customerContact ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name={'customerContact'}
                placeholder={'고객 연락처 입력'}
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">차량번호</div>
              <InputText
                value={billingSearchState.carNbr ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="carNbr"
                placeholder="차량번호 입력"
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">최초 작성자</div>
              <InputText
                value={billingSearchState.receiptName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="receiptName"
                placeholder="최초 작성자 입력"
              />
            </div>
            <div className="col-6 md:col-2 col">
              <div className="mb-2 font-bold">최종 작성자</div>
              <InputText
                value={billingSearchState.completeName ?? ''}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={handleKeyDown}
                className="w-full"
                name="completeName"
                placeholder="최종 작성자 입력"
              />
            </div>
          </div>
          <div className="flex gap-4 mt-3">
            <Divider />
          </div>
          <div className="flex justify-content-end md:flex-row flex-column">
            <div className="flex justify-content-end md:mt-0 mt-2">
              <Button
                label="엑셀 다운로드"
                className="p-button-outlined mr-1"
                icon="pi pi-download"
                disabled={exporting || tableData.length === 0}
                loading={exporting}
                onClick={async () => await exportToExcel()}
              />
              <Button
                label="검색조건 초기화"
                icon="pi pi-undo"
                className="p-button-outlined mr-1"
                onClick={resetSearch}
              />
              <Button
                label="검색"
                icon="pi pi-search"
                onClick={() => {
                  setSearchMode(true);
                  getSearchBillingList();
                }}
                loading={searchLoading}
              />
            </div>
          </div>
        </Panel>
      )}
      <Panel className="shadow-1 mt-3 mb-3">
        <DataTable
          value={tableData}
          lazy
          onPage={(e) => setlazyState(e)}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={
            searchMode ? searchTotal : !!data?.total ? data?.total : 0
          }
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={
            searchMode
              ? '전체 {totalRecords}건'
              : '전체 {totalRecords}건 중 {first} ~ {last}번'
          }
          rowsPerPageOptions={[20, 30, 50, 100, 500, 1000, 5000, 10000]}
          showGridlines
          emptyMessage="데이터가 없습니다."
          loading={isLoading || searchLoading || shopList.length < 2}
          onSelectionChange={({ value }) => {
            setSelectedRows(value);
            history.push({
              pathname: `/compensation/billing/claim/detail/${value.dataId}`,
            });
          }}
          dataKey="id"
          selection={selectedRows}
          selectionMode="single"
          stripedRows
        >
          <Column
            align={'center'}
            field="dtn"
            header="순번"
            body={(dataId, column) => (
              <div className="w-2rem">{column.rowIndex + 1}</div>
            )}
          />

          <Column
            align={''}
            field={'claimStatus'}
            header={'업무처리상태'}
            body={({ claimStatus }) => {
              return (
                <div className="w-14rem">
                  {STATUS_LIST.find((el) => el.value === claimStatus)?.label}
                </div>
              );
            }}
          />
          <Column
            align={'center'}
            field={'receiptDate'}
            header={'접수일자'}
            body={({ receiptDate }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.stringToDayForm(receiptDate)}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'shopId'}
            header={'성능점검장'}
            body={({ shopId }) => {
              const item = shopList.find((el) => el.value === shopId);
              return <div className="w-8rem">{item?.label?.split(']')[1]}</div>;
            }}
          />
          {TABLE_COLUMNS.map((col, i) => (
            <Column
              align={'center'}
              key={i}
              field={col.field}
              header={<div className="w-10rem">{col.header}</div>}
            />
          ))}
          <Column
            align={'center'}
            field={'customerContact'}
            header={'고객연락처'}
            body={({ customerContact }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.phone(customerContact)}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'carAddress'}
            header={'고객차량위치'}
            body={({ carAddress }) => (
              <div className="w-8rem">{carAddress}</div>
            )}
          />
          <Column
            align={'center'}
            field={'note'}
            header={'접수증상'}
            body={({ note }) => <div className="w-18rem">{note}</div>}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => id === el.partnerId)
                    )
                  );
                return (
                  <div className="w-10rem" style={{ whiteSpace: 'pre-wrap' }}>
                    {data
                      ?.flat()
                      .map(
                        (el, idx) =>
                          `${el.partnerName}${
                            data.length - 1 === idx ? '' : '\r\n'
                          }`
                      )}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'partnerId'}
              header={'진단점 연락처'}
              body={({ partnerInfo }) => {
                const ids = [];
                partnerInfo.map((el) => ids.push(el.partnerId));
                const data = [];
                ids &&
                  ids.forEach((id) =>
                    data.push(
                      partnerAllList.filter((el) => Number(id) === el.partnerId)
                    )
                  );
                return (
                  <div className="w-9rem">
                    {data?.flat().map((el, idx) => {
                      return `${CommonUtil.Formatter.cellphone(el.cellphone)}${
                        data.length - 1 === idx ? '' : '\r\n'
                      }`;
                    })}
                  </div>
                );
              }}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'receipName'}
              header={'최초 작성자'}
              body={({ receiptName }) => (
                <div className="w-6rem">{receiptName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'completeName'}
              header={'최종 작성자'}
              body={({ completeName }) => (
                <div className="w-6rem">{completeName}</div>
              )}
            />
          )}
          {partner ? null : (
            <Column
              align={'center'}
              field={'insurancePaymentAmt'}
              header={'총지급액'}
              body={({ insurancePaymentAmt }) => (
                <div className="w-6rem">
                  {!!insurancePaymentAmt
                    ? insurancePaymentAmt
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        ?.toString() + ' 원'
                    : '-'}
                </div>
              )}
            />
          )}
        </DataTable>
      </Panel>
    </div>
  );
};

export default BillingList;

const TABLE_COLUMNS = [
  { field: 'carNbr', header: '차량번호' },
  { field: 'newCarNbr', header: '차량번호(변경)' },
  { field: 'vin', header: '차대번호' },
  { field: 'carName', header: '차명' },
  { field: 'customerName', header: '고객명' },
];

const STATUS_LIST = [
  { value: 'Z08', label: '수리완료' },
  { value: 'Z11', label: '지급종결' },
  { value: 'Z19', label: '청구대기' },
  { value: 'Z20', label: '청구완료' },
];
