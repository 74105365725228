import * as Controlled from './../../components/Atoms/Controlled';
import * as SearchDialog from './../../components/Dialog/SearchDialog';
import * as Titled from './../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'primereact/calendar';

import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { CodeUtil } from '../../utils/CodeUtil';
import { CommonUtil } from '../../utils/commonUtil';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FormatUtil } from '../../utils/formatUtil';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import Postcode from '../Common/Postcode';
import { ServiceProvider } from '../../services';
import UserPosition from '../../enums/UserPosition';
import UserRoleType from '../../enums/UserRoleType';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import CustomerCompanySafe from '../../services/CustomerCompanyService';
let defaultValues = {
  useYn: 'Y',
};

const codeService = ServiceProvider.code;
const customerCompanyService = ServiceProvider.customerCompany;

const CustomerCompanyDialog = ({
  userInfo,
  customerCompanyId = 0,
  onHide = null,
  customerType,
}) => {
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);
  const myInfo = MyInfoUtil.unpack(userInfo);

  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });
  const currentValues = useWatch({ control });

  const [isModifiable, setIsModifiable] = useState(false);
  const [codes, setCodes] = useState({});
  const [customerCompanyData, setCustomerCompanyData] = useState(null);
  const [isAvailable, setIsAvailable] = useState({ loginId: false });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [needReset, setNeedReset] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    delete: false,
  });
  const [loading, setLoading] = useState(false);
  const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);

  const [showCustomerCompanyPopup, setShowCustomerCompanyPopup] =
    useState(false);

  async function getUnionCodes({ preSelectedUnionId }) {
    const { data } = await codeService.unions();
    const unions = [{ value: 0, label: '조합없음' }, ...data];
    const union =
      (preSelectedUnionId
        ? _.find(unions, { value: preSelectedUnionId })
        : _.get(unions, 0)) || {};

    return [unions, _.get(union, 'value'), union];
  }

  async function getComplexCodes({ unionId = 0, preSelectedComplexId }) {
    if (unionId === 0) return [[{ value: 0, label: '매매단지없음' }], 0, {}];

    const { data } = await codeService.complexes(unionId);
    const complexes = [...data];
    const complex =
      (preSelectedComplexId
        ? _.find(complexes, { value: preSelectedComplexId })
        : _.get(complexes, 0)) || {};

    return [complexes, _.get(complex, 'value'), complex];
  }

  async function onSubmit(formData) {
    setLoading(true);
    const submitData = FormatUtil.Convert.exceptHyphen(formData);
    if (customerType === 'safe6') {
      if (!startDate) {
        return window.cerp.toast.warn(
          '필수값 누락',
          'Safe6 시작일을 입력해 주세요.'
        );
      } else if (!!startDate) {
        submitData.safe6BeginDate = dayjs(startDate).format('YYYYMMDD');
        submitData.safe6EndDate = endDate
          ? dayjs(endDate).format('YYYYMMDD')
          : null;
        delete submitData.regEnterpriseId;
        delete submitData.regShopId;
      }
    }
    // return console.log(submitData);
    try {
      const { data } =
        customerCompanyId > 0 && customerType !== 'safe6'
          ? await customerCompanyService.update(submitData)
          : !customerCompanyId && customerType !== 'safe6'
          ? await customerCompanyService.register(submitData)
          : customerCompanyId > 0 && customerType === 'safe6'
          ? await CustomerCompanySafe.update(submitData)
          : await CustomerCompanySafe.register(submitData);
      const { customerCompany, masterUser, safe6Company } = data;

      if (customerCompanyId > 0) {
        // 새로운 데이터 받아서 다이얼로그 다시 세팅

        const {
          regAssociationId,
          regEnterpriseId,
          regShopId,
          unionId,
          complexId,
        } = customerType !== 'safe6' ? customerCompany : safe6Company;

        const { loginId } = masterUser;

        setIsModifiable(loginId === null);
        setIsAvailable((ps) => ({ ...ps, loginId: loginId !== null }));

        const [associations] = await CodeUtil.getAssociationCodes(myInfo, {
          preSelectedAssociationId: regAssociationId,
        });
        const [enterprises] = await CodeUtil.getEnterpriseCodes(myInfo, {
          isParentEmpty: _.isEmpty(associations),
          associationId: regAssociationId,
          preSelectedEnterpriseId: regEnterpriseId,
        });
        const [shops] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: regEnterpriseId,
          preSelectedShopId: regShopId,
        });

        const [unions, complexes] = await Promise.all([
          getUnionCodes({ preSelectedUnionId: unionId }),
          getComplexCodes({ unionId, preSelectedComplexId: complexId }),
        ]);

        setCodes({ associations, enterprises, shops, unions, complexes });
        if (customerType !== 'safe6') {
          setCustomerCompanyData({ ...customerCompany, loginId });
          setFormattedData({ ...customerCompany, loginId });
        } else {
          setCustomerCompanyData({ ...safe6Company, loginId });
          setFormattedData({ ...safe6Company, loginId });
        }

        setTimeout(() => {
          window.cerp.toast.success(
            '매매상사 정보 수정 완료',
            `[${
              customerType !== 'safe6'
                ? customerCompany.companyName
                : safe6Company.companyName
            }] 매매상사 정보를 수정했습니다.`
          );
        }, 500);
      } else {
        onHide();

        setTimeout(() => {
          window.cerp.toast.success(
            '매매상사 등록 완료',
            `[${
              customerType !== 'safe6'
                ? customerCompany.companyName
                : safe6Company.companyName
            }] 매매상사를 등록했습니다.`
          );
        }, 500);
      }
    } catch (error) {
      const { response } = error;
      window.cerp.dialog.error(
        customerCompanyId > 0
          ? '매매상사 정보 수정 실패'
          : '매매상사 신규 등록 실패',
        `[${response?.data?.error?.code}] ${response?.data?.error?.message}`
      );
    } finally {
      setLoading(false);
    }
  }

  function setFormattedData(data) {
    const formatter = {
      businessRegistrationNumber: 'biz',
      mainPhone: 'phone',
      fax: 'fax',
      cellphone: 'cellphone',
    };

    for (let field in data) {
      if (formatter[field] && data[field]) {
        setValue(
          field,
          CommonUtil.Formatter[formatter[field]](_.get(data, field))
        );
      }
    }
  }

  function fillEmptyField(data) {
    const copy = { ...data };
    for (let field in copy) {
      copy[field] = copy[field] ?? '';
    }

    return copy;
  }

  function checkAuth(userInfo, type) {
    const { roleCode } = userInfo;

    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'A_ADM' ||
          r.value === 'E_ADM' ||
          r.value === 'E_OFF' ||
          r.value === 'S_ADM' ||
          r.value === 'S_OFF'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'A_ADM' ||
          r.value === 'E_ADM' ||
          r.value === 'E_OFF' ||
          r.value === 'S_ADM' ||
          r.value === 'S_OFF'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const myInfo = MyInfoUtil.unpack(userInfo);

      if (customerCompanyId > 0 && !!customerType) {
        const { data, error } =
          customerType !== 'safe6'
            ? await customerCompanyService.getData(customerCompanyId)
            : await CustomerCompanySafe.getData(customerCompanyId);

        if (data) {
          const {
            regAssociationId,
            regEnterpriseId,
            regShopId,
            unionId,
            complexId,
            loginId,
          } = data;

          setIsModifiable(loginId === null);
          setIsAvailable((ps) => ({ ...ps, loginId: loginId !== null }));

          const [associations] = await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: regAssociationId,
          });
          const [enterprises] = await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: regAssociationId,
            preSelectedEnterpriseId: regEnterpriseId,
          });
          const [shops] = await CodeUtil.getShopCodes(myInfo, {
            isParentEmpty: _.isEmpty(enterprises),
            enterpriseId: regEnterpriseId,
            preSelectedShopId: regShopId,
          });

          const [unions, complexes] = await Promise.all([
            getUnionCodes({ preSelectedUnionId: unionId }),
            getComplexCodes({ unionId, preSelectedComplexId: complexId }),
          ]);

          setCodes({ associations, enterprises, shops, unions, complexes });
          setCustomerCompanyData(data);
          setFormattedData(data);
          if (customerType === 'safe6' && !!data.safe6BeginDate) {
            setStartDate(dayjs(data.safe6BeginDate, 'YYYYMMDD').toDate());
            setEndDate(dayjs(data.safe6EndDate, 'YYYYMMDD').toDate());
          }
        }

        if (error)
          window.cerp.dialog.error(
            '매매상사 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
      } else {
        const [associations, associationId] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [unions, unionId] = await getUnionCodes({});
        const [enterprises, enterpriseId] = await CodeUtil.getEnterpriseCodes(
          myInfo,
          {
            associationId,
            preSelectedEnterpriseId: myEnterpriseId,
          }
        );
        const [complexes, complexId] = await getComplexCodes({ unionId });
        const [shops, shopId] = await CodeUtil.getShopCodes(myInfo, {
          enterpriseId,
          preSelectedShopId: myShopId,
        });

        setCodes({ associations, enterprises, shops, unions, complexes });

        setValue('regAssociationId', associationId);
        setValue('regEnterpriseId', enterpriseId);
        setValue('regShopId', shopId);
        setValue('unionId', unionId);
        setValue('complexId', complexId);

        defaultValues = _.merge(
          {
            regAssociationId: associationId,
            regEnterpriseId: enterpriseId,
            regShopId: shopId,
            unionId: unionId,
            complexId: complexId,
          },
          defaultValues
        );
      }

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerCompanyId,
    myAssociationId,
    myEnterpriseId,
    myShopId,
    setValue,
    customerType,
  ]);

  useEffect(() => {
    if (needReset) {
      setIsAvailable((ps) => ({ loginId: false }));
      reset({ ...defaultValues });
      setNeedReset(false);
    }
  }, [needReset, reset]);

  useEffect(() => {
    if (customerCompanyData !== null) {
      reset(fillEmptyField(customerCompanyData));
    }
  }, [customerCompanyData, reset]);

  return (
    <Dialog
      modal
      breakpoints={{ '960px': '90vw' }}
      header={
        customerCompanyId > 0
          ? `${customerType === 'safe6' ? 'Safe6' : ''} 매매상사 정보 수정`
          : `${customerType === 'safe6' ? 'Safe6' : ''} 매매상사 신규 등록`
      }
      visible
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
          <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
            {customerCompanyId > 0 && checkAuth(userInfo, 'DELETE') && (
              <>
                <ConfirmDialog
                  visible={showConfirmPopup['delete']}
                  header="확인"
                  message={
                    <span className="font-bold">
                      매매상사를 삭제하시겠습니까?
                    </span>
                  }
                  icon="pi pi-exclamation-triangle"
                  acceptLabel="네"
                  accept={async () => {
                    try {
                      const { data } =
                        customerType !== 'safe6'
                          ? await customerCompanyService.delete(
                              customerCompanyId
                            )
                          : await CustomerCompanySafe.deleteData(
                              customerCompanyId
                            );

                      if (data) {
                        console.log(data);
                        window.cerp.toast.success(
                          '매매상사 삭제 완료',
                          `[${data.customerCompanyName}] 매매상사가 삭제되었습니다.`
                        );
                        onHide();
                      }
                    } catch (e) {
                      if (e) {
                        const { response } = e;
                        console.log(response.data.error.message);
                        window.cerp.dialog.error(
                          '매매상사 삭제 실패',
                          `${response.data.error.message}`
                        );
                      }
                    }
                  }}
                  rejectLabel="아니오"
                  reject={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                  }
                  onHide={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                  }
                />

                <Button
                  label="삭제"
                  icon="pi pi-trash"
                  className="p-button-outlined p-button-danger"
                  onClick={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: true }))
                  }
                />
              </>
            )}

            <ConfirmDialog
              visible={showConfirmPopup['submit']}
              header="확인"
              message={
                customerCompanyId > 0
                  ? '매매상사 정보를 수정하시겠습니까?'
                  : '신규 매매상사를 등록하시겠습니까?'
              }
              icon="pi pi-exclamation-triangle"
              acceptLabel={customerCompanyId > 0 ? '수정' : '등록'}
              accept={() => handleSubmit(onSubmit)()}
              rejectLabel="취소"
              reject={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: false }))
              }
              onHide={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: false }))
              }
            />
            <Button
              id="btn-save"
              label="저장"
              icon="pi pi-save"
              autoFocus
              onClick={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: true }))
              }
              disabled={
                !(_.get(currentValues, 'loginId') === '') &&
                !(_.get(currentValues, 'loginId') !== '' && isAvailable.loginId)
              }
            />
          </div>
        </div>
      )}
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <form autoComplete="off">
          <Panel
            headerTemplate={
              <div className="p-panel-header">
                <div className="flex flex-column align-items-start justify-content-center gap-1">
                  <span className="p-panel-title">1. 매매상사 기본 정보</span>
                </div>
                {customerCompanyId > 0 && checkAuth(userInfo, 'DISABLED') && (
                  <div className="flex align-items-center justify-content-start gap-1">
                    <label
                      className={classNames({
                        'font-semibold': currentValues['useYn'] === YN.NO,
                      })}
                    >
                      미사용
                    </label>
                    <Controller
                      control={control}
                      name="useYn"
                      defaultValue="Y"
                      render={({ field, fieldState, formState }) => (
                        <InputSwitch
                          id={field.name}
                          {...field}
                          trueValue={'Y'}
                          falseValue={'N'}
                          checked={field.value}
                        />
                      )}
                    />
                    <label
                      className={classNames({
                        'font-semibold': currentValues['useYn'] === YN.YES,
                      })}
                    >
                      사용
                    </label>
                  </div>
                )}
              </div>
            }
          >
            <div className="grid">
              {[
                {
                  code: 'regAssociationId',
                  title: '협회',
                  dataKey: 'associations',
                  placeholder: '협회없음',
                  required: true,
                },
                {
                  code: 'regEnterpriseId',
                  title: '점검업체',
                  dataKey: 'enterprises',
                  placeholder: '점검법인없음',
                  required: customerType !== 'safe6' ? true : false,
                },
                {
                  code: 'regShopId',
                  title: '점검장',
                  dataKey: 'shops',
                  placeholder: '점검장없음',
                  required: customerType !== 'safe6' ? true : false,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue={0}
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                      // validate: (v) => v > 0 || '선택된 값이 없습니다.',
                    }}
                    render={({ field, fieldState, formState }) => (
                      <>
                        <Titled.Dropdown
                          id={field.name}
                          {...item}
                          {...field}
                          options={codes[item.dataKey]}
                          onChange={async (value) => {
                            field.onChange(value);

                            switch (item.dataKey) {
                              case 'associations':
                                {
                                  const [enterprises, enterpriseId] =
                                    await CodeUtil.getEnterpriseCodes(myInfo, {
                                      associationId: value,
                                    });
                                  const [shops, shopId] =
                                    await CodeUtil.getShopCodes(myInfo, {
                                      enterpriseId,
                                    });

                                  setCodes((ps) => ({
                                    ...ps,
                                    enterprises,
                                    shops,
                                  }));
                                  setValue('regEnterpriseId', enterpriseId);
                                  setValue('regShopId', shopId);

                                  defaultValues = _.merge(
                                    {
                                      regAssociationId: value,
                                      regEnterpriseId: enterpriseId,
                                      regShopId: shopId,
                                    },
                                    defaultValues
                                  );
                                }
                                break;

                              case 'enterprises':
                                {
                                  const [shops, shopId] =
                                    await CodeUtil.getShopCodes(myInfo, {
                                      enterpriseId: value,
                                    });

                                  setCodes((ps) => ({ ...ps, shops }));
                                  setValue('regShopId', shopId);

                                  defaultValues = _.merge(
                                    {
                                      regEnterpriseId: value,
                                      regShopId: shopId,
                                    },
                                    defaultValues
                                  );
                                }
                                break;

                              case 'shops':
                                {
                                  defaultValues = _.merge(
                                    {
                                      regShopId: value,
                                    },
                                    defaultValues
                                  );
                                }
                                break;
                            }
                          }}
                          placeholder={
                            loading ? (
                              <>
                                <i className="pi pi-spin pi-spinner m-0 mr-2" />
                                조회하고 있습니다...
                              </>
                            ) : (
                              item.placeholder
                            )
                          }
                          error={formState.errors[field.name]}
                          showFilter
                          disabled={(() => {
                            switch (item.dataKey) {
                              case 'associations':
                                return (
                                  !_.includes(
                                    [UserPosition.Erp],
                                    myUserPosition
                                  ) || MyInfoUtil.isInspector(myRoleCode)
                                );
                              case 'enterprises':
                                return (
                                  !_.includes(
                                    [
                                      UserPosition.Erp,
                                      UserPosition.Association,
                                    ],
                                    myUserPosition
                                  ) || MyInfoUtil.isInspector(myRoleCode)
                                );
                              case 'shops':
                                return (
                                  !_.includes(
                                    [
                                      UserPosition.Erp,
                                      UserPosition.Association,
                                      UserPosition.Enterprise,
                                    ],
                                    myUserPosition
                                  ) || MyInfoUtil.isInspector(myRoleCode)
                                );
                              default:
                                return true;
                            }
                          })()}
                        />
                      </>
                    )}
                  />
                </div>
              ))}
              <div className="col-12 sm:col-6 lg:col-3" />
              {[
                {
                  code: 'unionId',
                  title: '소속조합',
                  dataKey: 'unions',
                  placeholder: '조합없음',
                },
                {
                  code: 'complexId',
                  title: '소속매매단지',
                  dataKey: 'complexes',
                  placeholder: '매매단지없음',
                  // required: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue={0}
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                      validate: (v) => v >= 0 || '선택된 값이 없습니다.',
                    }}
                    render={({ field, fieldState, formState }) => (
                      <Titled.Dropdown
                        id={field.name}
                        {...item}
                        {...field}
                        options={codes[item.dataKey]}
                        onChange={async (value) => {
                          field.onChange(value);

                          switch (item.dataKey) {
                            case 'unions':
                              {
                                const [complexes, complexId] =
                                  await getComplexCodes({ unionId: value });
                                setCodes((ps) => ({ ...ps, complexes }));
                                setValue('complexId', complexId);

                                defaultValues = _.merge(
                                  {
                                    unionId: value,
                                    complexId,
                                  },
                                  defaultValues
                                );
                              }
                              break;

                            case 'complexes':
                              {
                                defaultValues = _.merge(
                                  {
                                    complexId: value,
                                  },
                                  defaultValues
                                );
                              }
                              break;
                          }
                        }}
                        placeholder={
                          loading ? (
                            <>
                              <i className="pi pi-spin pi-spinner m-0 mr-2" />
                              조회하고 있습니다...
                            </>
                          ) : (
                            item.placeholder
                          )
                        }
                        error={formState.errors[field.name]}
                        showFilter
                      />
                    )}
                  />
                </div>
              ))}
              {[
                {
                  code: 'companyName',
                  title: '매매상사명',
                  required: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue=""
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                      pattern: {
                        value: CommonUtil.Pattern[item.type],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <div className="flex align-items-end w-full">
                        <Titled.InputText
                          id={field.name}
                          {...item}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);

                            let value = e.target.value;
                            if (item.type)
                              CommonUtil.Formatter[item.type] &&
                                (value =
                                  CommonUtil.Formatter[item.type](value));

                            setValue(field.name, value);
                            defaultValues = _.merge(
                              {
                                complexId: value,
                              },
                              defaultValues
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setShowCustomerCompanyPopup(true);
                            }
                          }}
                          error={formState.errors[field.name]}
                        />
                        <Button
                          type="button"
                          label="검색"
                          style={{
                            wordBreak: 'keep-all',
                            width: '5rem',
                          }}
                          tooltip="기존 등록되었던 매매상사를 조회할 수 있습니다."
                          tooltipOptions={{
                            position: 'top',
                          }}
                          onClick={(e) => {
                            setShowCustomerCompanyPopup(true);
                          }}
                        />

                        {/* 매매상사 검색 다이얼로그 */}
                        {showCustomerCompanyPopup && (
                          <SearchDialog.CustomerCompany
                            userInfo={userInfo}
                            preSelectedAssociationId={_.get(
                              currentValues,
                              'regAssociationId'
                            )}
                            preSelectedEnterpriseId={_.get(
                              currentValues,
                              'regEnterpriseId'
                            )}
                            preSelectedShopId={_.get(
                              currentValues,
                              'regShopId'
                            )}
                            searchText={_.get(currentValues, 'companyName')}
                            onHide={(customerCompanyData) => {
                              setShowCustomerCompanyPopup(false);

                              if (customerCompanyData) {
                                reset(fillEmptyField(customerCompanyData));
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                </div>
              ))}
            </div>
          </Panel>

          <Panel header="2. 매매상사 상세 정보 및 대표자 정보" className="pt-3">
            <div className="grid">
              <div className="col-12 sm:col-6 lg:col-3">
                <Controller
                  control={control}
                  name="companyType"
                  defaultValue="IB"
                  render={({ field, fieldState, formState }) => (
                    <Titled.Dropdown
                      id={field.name}
                      title="사업자 구분"
                      {...field}
                      options={[
                        { label: '개인사업자', value: 'IB' },
                        { label: '법인사업자', value: 'CB' },
                        { label: '해당없음', value: '' },
                      ]}
                      required
                    />
                  )}
                />
              </div>
              {[
                {
                  code: 'businessRegistrationName',
                  title: '사업자명',
                  required: true,
                },
                {
                  code: 'businessRegistrationNumber',
                  title: '사업자등록번호',
                  type: 'biz',
                  required: true,
                },
                {
                  type: 'phone',
                  code: 'mainPhone',
                  title: '대표전화',
                  required: false,
                  pattern: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue=""
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                      pattern: {
                        value: CommonUtil.Pattern[item.type],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <>
                        <Titled.InputText
                          id={field.name}
                          {...item}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);

                            let value = e.target.value;
                            if (item.type)
                              CommonUtil.Formatter[item.type] &&
                                (value =
                                  CommonUtil.Formatter[item.type](value));

                            setValue(field.name, value);
                          }}
                          error={formState.errors[field.name]}
                        />
                      </>
                    )}
                  />
                </div>
              ))}
              {customerType === 'safe6' && (
                <>
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="mb-2">
                      <i className="text-red-400 pi pi-check mr-1" />
                      Safe6 시작일
                    </div>
                    <Calendar
                      value={startDate}
                      onChange={(e) => setStartDate(e.value)}
                      dateFormat="yy-mm-dd"
                      className="w-full"
                      // maxDate={dayjs().toDate()}
                    />
                  </div>
                  <div className="col-12 sm:col-6 lg:col-3">
                    <div className="mb-2">Safe6 종료일</div>
                    <Calendar
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      dateFormat="yy-mm-dd"
                      className="w-full"
                    />
                  </div>
                </>
              )}
              {[
                {
                  type: 'fax',
                  code: 'fax',
                  title: '팩스',
                  pattern: true,
                },
                {
                  type: 'email',
                  code: 'email',
                  title: '이메일',
                  pattern: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue=""
                    rules={{
                      required: item.required ? '필수 입력항목입니다.' : false,
                      pattern: {
                        value: CommonUtil.Pattern[item.type],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <>
                        <Titled.InputText
                          id={field.name}
                          {...item}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);

                            let value = e.target.value;
                            if (item.type)
                              CommonUtil.Formatter[item.type] &&
                                (value =
                                  CommonUtil.Formatter[item.type](value));

                            setValue(field.name, value);
                          }}
                          error={formState.errors[field.name]}
                        />
                      </>
                    )}
                  />
                </div>
              ))}

              <div className="col-12 sm:col-6">
                <div className="field mb-0">
                  <label>주소</label>
                </div>
                <div className="p-inputgroup">
                  <Controller
                    control={control}
                    name="postcode"
                    defaultValue=""
                    render={({ field, fieldState, formState }) => (
                      <div className="p-inputgroup-addon">
                        {field.value || '우편번호'}
                      </div>
                    )}
                  />
                  <Controller
                    control={control}
                    name="address"
                    defaultValue=""
                    render={({ field, fieldState, formState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        readOnly
                        className="bg-gray-100"
                      />
                    )}
                  />
                  <Button
                    type="button"
                    label="주소검색"
                    icon="pi pi-search"
                    onClick={() => setIsPostcodeOpen(true)}
                  />
                </div>
              </div>
              <div className="col-12 sm:col-6">
                <Controller
                  control={control}
                  name="addressDetail"
                  defaultValue=""
                  render={({ field, fieldState, formState }) => (
                    <Titled.InputText
                      id={field.name}
                      title="상세주소"
                      {...field}
                      disabled={_.isEmpty(currentValues['address'])}
                    />
                  )}
                />
              </div>
            </div>
          </Panel>

          <Panel header="3. 사무장 정보" className="pt-3">
            <div className="grid">
              {[
                {
                  code: 'representative',
                  title: '대표자명',
                  required: false,
                },
                {
                  type: 'phone',
                  code: 'cellphone',
                  title: '휴대전화',
                  required: false,
                  pattern: true,
                },
                {
                  type: 'email2',
                  code: 'email2',
                  title: '이메일',
                  required: false,
                  pattern: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue=""
                    rules={{
                      required: item.required && '필수 입력항목입니다.',
                      pattern: {
                        value: CommonUtil.Pattern[item.type],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <>
                        <Titled.InputText
                          id={field.name}
                          {...item}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);

                            let value = e.target.value;
                            if (item.type)
                              CommonUtil.Formatter[item.type] &&
                                (value =
                                  CommonUtil.Formatter[item.type](value));

                            setValue(field.name, value);
                          }}
                          error={formState.errors[field.name]}
                        />
                      </>
                    )}
                  />
                </div>
              ))}
            </div>
          </Panel>

          {/* <Panel header="4. 매매상사 계정 정보" className="pt-3"> */}
          <Panel header="4. 매매상사 계정 정보" className="pt-3">
            <div className="grid">
              <div className="col-12 sm:col-6 lg:col-3">
                <Controlled.ValidateInputText
                  id={customerCompanyId}
                  isModifiable={isModifiable}
                  control={control}
                  trigger={trigger}
                  getValues={getValues}
                  isAvailable={isAvailable}
                  setIsAvailable={setIsAvailable}
                  setValue={setValue}
                  inputData={{
                    inputLabel: '로그인 아이디',
                    dataLabel: 'loginId',
                  }}
                  inputConfig={{
                    placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                    autoComplete: 'off',
                  }}
                  rules={{
                    // required: '필수 입력 항목입니다.',
                    required: false,
                    pattern: {
                      value: CommonUtil.Pattern['login'],
                      message: '유효하지 않은 포맷입니다.',
                    },
                  }}
                />
              </div>

              {(!customerCompanyId > 0 || isModifiable) &&
                [
                  {
                    code: 'password',
                    title: '패스워드',
                    type: 'password',
                    defaultValue: '',
                    required:
                      _.get(currentValues, 'loginId') && '필수 입력입니다.',
                    pattern: true,
                    category: 'password',
                  },
                  {
                    code: 'passwordConfirmation',
                    title: '패스워드 확인',
                    type: 'password',
                    defaultValue: '',
                    required:
                      _.get(currentValues, 'loginId') && '필수 입력입니다.',
                    validate: (val) => {
                      if (
                        _.get(currentValues, 'loginId') &&
                        _.get(currentValues, 'password') !== val
                      ) {
                        return '비밀번호가 일치하지 않습니다.';
                      }
                    },
                    pattern: true,
                    category: 'password',
                  },
                ].map((item, idx) => (
                  <div
                    key={`col_${item.code}`}
                    className="col-12 sm:col-6 lg:col-3"
                  >
                    <Controlled.InputPassword
                      item={item}
                      key={idx}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      inputConfig={{
                        autoComplete: 'off',
                      }}
                    />
                  </div>
                ))}
            </div>
          </Panel>

          <Panel header="5. 메모 및 기타정보" className="pt-3">
            <div className="grid">
              <div className="col-12">
                <Controller
                  control={control}
                  name="note"
                  defaultValue=""
                  render={({ field, fieldState, formState }) => (
                    <InputTextarea
                      className="w-full mb-0"
                      {...field}
                      rows={3}
                      autoResize
                      placeholder="메모"
                    />
                  )}
                />
              </div>
            </div>
          </Panel>
        </form>
      </BlockUI>

      <Postcode
        open={isPostcodeOpen}
        onHide={() => setIsPostcodeOpen(false)}
        onComplete={(data) => {
          setIsPostcodeOpen(false);
          setValue('postcode', data.zonecode);
          setValue('address', data.address);
        }}
      />
    </Dialog>
  );
};

export default CustomerCompanyDialog;
