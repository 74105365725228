import * as Button from './../../../components/Atoms/Button';
import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';
import * as filesize from 'filesize';
import { Dropdown } from 'primereact/dropdown';
import { compressImages } from '../../../utils/compressUtil';
import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { CommonUtil } from '../../../utils/commonUtil';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import Postcode from '../../Common/Postcode';
import { ServiceProvider } from '../../../services';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../../recoil/selectors';
import { useFormValid } from '../../../hooks/useFormValid';
import { useRecoilValueLoadable } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import FileState from '../../../enums/FileState';
import { Badge } from 'primereact/badge';
import { Partner } from '../../../services/PartnerService';
import { BlockUI } from 'primereact/blockui';

const kakaoService = ServiceProvider.kakao;

const formField = [
  {
    code: 'partnerName',
    title: '진단점명',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'partnerBasicInfo',
  },
  {
    code: 'cellphone',
    title: '휴대전화',
    type: 'cellphone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'mainPhone',
    title: '대표번호',
    type: 'phone',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'representative',
    title: '대표자명',
    type: 'representative',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'partnerBasicInfo',
  },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: true,
    category: 'master',
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    category: 'password',
  },
  {
    code: 'postcode',
    title: '우편번호',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'postcode',
  },
  {
    code: 'address',
    title: '주소',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'address',
  },
  {
    code: 'addressDetail',
    title: '상세주소',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'addressDetail',
  },
  {
    code: 'bankName',
    title: '은행명',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'bankName',
  },
  {
    code: 'bankAccountNumber',
    title: '계좌번호',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'bankAccountNumber',
  },
  {
    code: 'partnerShip',
    title: '제휴 구분',
    type: 'text',
    defaultValue: 'N',
    required: true,
    pattern: true,
    category: 'partnerShip',
  },
  {
    code: 'note',
    title: '메모',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'note',
  },
  {
    code: 'reliability',
    title: '신뢰도',
    defaultValue: '1',
    required: false,
    pattern: false,
  },
  {
    code: 'partnerLocation',
    type: 'text',
    defaultValues: {
      lat: '',
      lon: '',
    },
    required: true,
    pattern: false,
  },
];

export const PartnerDialog = ({
  userInfo,
  shopId = 0,
  onHide = null,
  partnerId,
  deletePartner,
  partnerTypes,
  getList,
  searchConditions,
}) => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};
    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const { control, watch, trigger, setValue, getValues } = useForm({
    defaultValues: getDefaultValues(formField),
    reValidateMode: 'onSubmit',
  });

  const isModified = !!partnerId;
  const currentValues = useWatch({ control });

  const { isFormComplete } = useFormValid(watch(), formField, isModified);
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    partnerCode: false,
    loginId: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(null);
  useEffect(() => {
    if (partnerId) {
      const getPartnerInfo = async (id) => {
        setIsLoading(true);
        try {
          const data = await Partner.getDetailData(id);
          if (data) {
            setPartnerInfo(data.partnerInfo);
            setFileItems(data.partnerDocuments);
            if (data.partnerInfo.partnerRepairType) {
              setValue(
                'partnerRepairType',
                data.partnerInfo.partnerRepairType?.split(',')
              );
            }
            formField.forEach((el) =>
              setValue(el.code, data.partnerInfo[el.code])
            );
            setIsAvailable({
              partnerCode: data.partnerInfo.partnerCode,
              loginId: data.partnerInfo.loginId,
            });
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      getPartnerInfo(partnerId);
    }
  }, [partnerId, setValue]);

  const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
  const [fileItems, setFileItems] = useState([]);

  const getAddressInfo = async (address) => {
    const {
      documents: [res],
    } = await kakaoService.getAddressInfo({
      query: address,
    });
    setValue('partnerLocation', `${res.y},${res.x}`);
  };

  const checkUserAuth = (userInfo, type) => {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  };

  const getFilteredInputData = (formField, category) => {
    return formField.filter((item) => item.category === category);
  };

  const registerPartner = async () => {
    setLoading(true);
    if (currentValues.password !== currentValues.passwordConfirmation) {
      return window.cerp.dialog.error('비밀번호가 일치하지 않습니다.');
    }

    if (!fileItems.length)
      return window.cerp.toast.warn('필수서류를 등록해주세요.');

    const fileData = [];
    const files = [];
    fileItems.map((el) =>
      fileData.push({
        documentCode: el.documentCode,
        state: el.state,
      })
    );
    fileItems.map((el) => files.push(el.file));
    const postData = {
      ...currentValues,
      associationId: myInfoLoadable.contents.associationInfo.associationId,
      useYn: 'Y',
      partnerCode: null,
      partnerShip: currentValues.partnerShip ?? 'Y',
      partnerRepairType: currentValues.partnerRepairType?.toString(),
      fileData: fileData,
    };
    // return console.log('postData', postData);

    try {
      const data = await Partner.postPartner(postData, files);
      if (data) {
        window.cerp.toast.success('등록이 완료되었습니다.');
        onHide();
        getList({
          ...searchConditions,
          aids: [userInfo.associationInfo.associationId],
        });
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(error?.message);
      onHide();
    }
    setLoading(false);
  };

  const updatePartner = async () => {
    setLoading(true);
    let fileData = [];
    let filesList = [];
    let deleteFiles = [];
    fileItems.filter((el) => el.state === 'D' && deleteFiles.push(el));
    let imgFile = [];
    fileItems.filter((el) => el.file && imgFile.push(el));

    if (!!deleteFiles.length) {
      const { files } = CommonUtil.File.convertForServerClaim(deleteFiles);
      files.map((el) => filesList.push(el));
      deleteFiles.map((el) => fileData.push(el));
    }

    if (!!imgFile.length) {
      imgFile.map((el) =>
        fileData.push({ documentCode: el.documentCode, state: el.state })
      );
      imgFile.map((el) => filesList.push(el.file));
    }

    const updateData = {
      ...currentValues,
      associationId: myInfoLoadable.contents.associationInfo.associationId,
      useYn: 'Y',
      partnerCode: partnerInfo.partnerCode,
      partnerId: partnerInfo.partnerId,
      partnerRepairType: currentValues.partnerRepairType?.toString(),
      partnerShip: currentValues.partnerShip ?? 'Y',
      password: null,
      passwordConfirmation: null,
      fileData: fileData,
    };

    // return console.log(updateData);
    try {
      const data = await Partner.putPartner(updateData, filesList);
      if (data) {
        window.cerp.toast.success('수정이 완료되었습니다.');
        onHide();
        getList({
          ...searchConditions,
          aids: [userInfo.associationInfo.associationId],
        });
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(error?.message);
      onHide();
    }
    setLoading(false);
  };

  const isFormVaild =
    !!currentValues.partnerRepairType &&
    !!currentValues.partnerRepairType?.length &&
    !!fileItems.length;

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const files = Object.values(acceptedFiles);
      files.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(files, options);
      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            file: v,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );
      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    },
  });

  return (
    <Modal.Form
      title={'진단점'}
      childDataName={'사용자'}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={() => deletePartner(partnerId)}
      onSubmitConfirm={isModified ? updatePartner : registerPartner}
      saveBtnEnable={
        isModified
          ? !(isAvailable.loginId && isFormVaild && isFormComplete)
          : !(isAvailable.loginId && isFormVaild && isFormComplete)
      }
      deleteBtnVisible={
        checkUserAuth(userInfo, 'DELETE') || userInfo.roleCode === 'I_ADM'
      }
    >
      <BlockUI
        blocked={isLoading}
        template={
          isLoading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      >
        <form autoComplete="off">
          <Panel
            headerTemplate={
              <div className="p-panel-header">
                <span className="font-semibold">1. 진단점 기본 정보</span>
              </div>
            }
          >
            <div className="grid">
              {_.filter(formField, { category: 'partnerBasicInfo' }).map(
                (item) => (
                  <div
                    className="col-12 sm:col-6 lg:col-3"
                    key={`inputs_${item.code}`}
                  >
                    <Controller
                      control={control}
                      name={item.code}
                      rules={{
                        required: item.required && '필수 입력항목입니다.',
                        pattern: {
                          value: CommonUtil.Pattern[item.type],
                          message: '유효하지 않은 포맷입니다.',
                        },
                      }}
                      render={({ field }) => (
                        <Titled.InputText
                          id={field.name}
                          {...item}
                          {...field}
                        />
                      )}
                    />
                  </div>
                )
              )}
            </div>
          </Panel>
          <Panel
            className="pt-3"
            headerTemplate={
              <div className="p-panel-header">
                <span className="font-semibold">2. 진단점 상세 정보</span>
              </div>
            }
          >
            <div className="grid">
              <div className="col-12 sm:col-6">
                <div className="field mb-0">
                  <i className="text-red-400 pi pi-check mr-1" />
                  <label htmlFor="address">주소</label>
                </div>
                <div
                  className="p-inputgroup"
                  onClick={() => setIsPostcodeOpen(true)}
                >
                  <Controller
                    control={control}
                    name="postcode"
                    rules={{
                      required: '필수 입력항목입니다.',
                    }}
                    render={({ field }) => (
                      <div className="p-inputgroup-addon">
                        {field.value || '우편번호'}
                      </div>
                    )}
                  />
                  <Controller
                    control={control}
                    name="address"
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        readOnly
                        className="bg-gray-100"
                      />
                    )}
                  />
                  <Button.Default
                    type="button"
                    label="주소검색"
                    icon="pi pi-search"
                    onClick={() => setIsPostcodeOpen(true)}
                  />
                </div>
              </div>
              <div className="col-12 sm:col-6">
                <Controller
                  control={control}
                  name="addressDetail"
                  defaultValue=""
                  render={({ field }) => (
                    <Titled.InputText
                      id={field.name}
                      {...field}
                      title="상세주소"
                      disabled={_.isEmpty(currentValues['address'])}
                    />
                  )}
                />
              </div>

              <div className="col-12 sm:col-6 lg:col-6">
                <Controller
                  control={control}
                  name="partnerRepairType"
                  rules={{
                    required: '필수 입력항목입니다.',
                  }}
                  render={({ field }) => (
                    <Titled.MultiSelect
                      id={field.name}
                      {...field}
                      title="진단 부위"
                      required={true}
                      placeholder="진단부위 선택"
                      options={partnerTypes}
                      display="true"
                    />
                  )}
                />
              </div>
              <div className="col-12 sm:col-6 lg:col-3">
                <Controller
                  control={control}
                  name="partnerShip"
                  defaultValue=""
                  rules={{
                    required: '필수 입력항목입니다.',
                  }}
                  render={({ field }) => (
                    <Titled.RadioButton
                      id={field.name}
                      {...field}
                      title="제휴 구분"
                      items={[
                        { label: '제휴', value: 'Y' },
                        { label: '비제휴', value: 'N' },
                      ]}
                      required
                    />
                  )}
                />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="field col-12">
                <label>
                  <i className="text-red-400 pi pi-check mr-1" />
                  서류 등록
                </label>
                <div className="grid">
                  <div className="col-12">
                    <div
                      {...getRootProps({
                        className:
                          'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                        style: { wordBreak: 'keep-all' },
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                        <i
                          className="pi pi-arrow-circle-down"
                          style={{ fontSize: '1.5rem' }}
                        />
                        사업자등록증사본, 통장사본, 업체동의서 등록
                        <p>
                          (이 영역에 사진을 끌어다 놓거나, 영역을 클릭하여
                          사진을 선택하세요.)
                        </p>
                      </div>
                    </div>
                  </div>
                  {fileItems.length > 0 && (
                    <div className="col-12 px-3">
                      <div className="flex flex-auto flex-column gap-2">
                        {fileItems.map((item, idx) => {
                          const { state = FileState.Loaded } = item;
                          if (state !== FileState.Deleted) {
                            return (
                              <div
                                key={`FILE_${idx}`}
                                className="grid border-1 border-300 border-round-sm mt-0"
                              >
                                <div className="col-12 sm:col-9">
                                  <div className="flex flex-auto align-items-center justify-content-start">
                                    {_.has(item, 'imgId') ? (
                                      <>
                                        <Image
                                          src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                                          alt={item.originalFileName}
                                          preview
                                          imageClassName="border-round border-1 border-200"
                                          imageStyle={{
                                            width: 44,
                                            height: 44,
                                          }}
                                        />
                                        <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                          <span>{item.originalFileName}</span>
                                          <Badge
                                            value={`${filesize(item.fileSize, {
                                              round: 1,
                                              standard: 'jedec',
                                            })}`}
                                            severity="info"
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Image
                                          src={item.file.preview}
                                          alt={item.file.name}
                                          preview
                                          imageClassName="border-round border-1 border-200"
                                          imageStyle={{
                                            width: 44,
                                            height: 44,
                                          }}
                                        />
                                        <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                          <span>{item.file.name}</span>
                                          <Badge
                                            value={`${filesize(item.file.size, {
                                              round: 1,
                                              standard: 'jedec',
                                            })}`}
                                            severity="info"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 sm:col-3 text-right">
                                  <Dropdown
                                    className="mr-2"
                                    value={fileItems[idx].documentCode}
                                    options={[
                                      {
                                        name: '1. 사업자등록증사본',
                                        code: 'P_REGISTRATION',
                                      },
                                      {
                                        name: '2. 사업자통장사본',
                                        code: 'P_BANKBOOK',
                                      },
                                      {
                                        name: '3. 업체동의서',
                                        code: 'P_AGREEMENT',
                                      },
                                    ]}
                                    onChange={(e) => {
                                      const clonedFiles = [...fileItems];
                                      const clonedFile = clonedFiles[idx];

                                      if (_.has(clonedFile, 'imgId')) {
                                        clonedFiles[idx] = {
                                          ...clonedFile,
                                          documentCode: e.value,
                                          state: FileState.Updated,
                                        };
                                      } else {
                                        clonedFiles[idx] = {
                                          ...clonedFile,
                                          documentCode: e.value,
                                        };
                                      }
                                      setFileItems(clonedFiles);
                                    }}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="종류 선택"
                                  />
                                  <Button.Default
                                    type="button"
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-outlined"
                                    onClick={() => {
                                      const clonedFiles = [...fileItems];
                                      const clonedFile = clonedFiles[idx];

                                      if (_.has(clonedFile, 'imgId')) {
                                        clonedFiles[idx] = {
                                          documentCode: clonedFile.documentCode,
                                          state: FileState.Deleted,
                                          imgId: clonedFile.imgId,
                                        };
                                      } else {
                                        clonedFiles.splice(idx, 1);
                                      }

                                      setFileItems(clonedFiles);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Panel>
          <Panel header="3. 마스터 계정 정보" className="pt-3">
            <div className="grid">
              <div className="col-12 sm:col-6 lg:col-3">
                <Controlled.ValidateInputText
                  id={partnerId}
                  isModified={isModified}
                  control={control}
                  trigger={trigger}
                  currentValues={currentValues}
                  isAvailable={isAvailable}
                  setIsAvailable={setIsAvailable}
                  setValue={setValue}
                  getValues={getValues}
                  inputData={{
                    inputLabel: formField.filter(
                      (item) => item.code === 'loginId'
                    )[0].title,
                    dataLabel: 'loginId',
                  }}
                  inputConfig={{
                    placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                    autoComplete: 'new-loginId',
                  }}
                  rules={{
                    required: '필수 입력 항목입니다.',
                  }}
                />
              </div>

              {!isModified &&
                getFilteredInputData(formField, 'password').map((item, idx) => (
                  <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                    <Controlled.InputPassword
                      item={item}
                      key={idx}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      inputConfig={{
                        autoComplete: 'chrome-off',
                      }}
                    />
                  </div>
                ))}
            </div>
          </Panel>

          <Panel header="4. 기타" className="pt-3">
            <div className="grid">
              <div className="col-12">
                <Controller
                  control={control}
                  name="reliability"
                  defaultValue="1"
                  render={({ field }) => (
                    <Titled.RadioButton
                      id={field.name}
                      {...field}
                      title="신뢰도 구분"
                      // items={RELIABILITY}
                      items={[
                        { label: '상', value: '1' },
                        { label: '중', value: '2' },
                        { label: '하', value: '3' },
                      ]}
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <Controller
                  control={control}
                  name="note"
                  defaultValue=""
                  render={({ field }) => (
                    <div className="flex flex-column">
                      <label htmlFor="note">메모</label>
                      <InputTextarea
                        id={field.name}
                        {...field}
                        autoResize
                        rows={5}
                        cols={30}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </Panel>
        </form>

        <Postcode
          open={isPostcodeOpen}
          onHide={() => setIsPostcodeOpen(false)}
          onComplete={async (data) => {
            setIsPostcodeOpen(false);
            await getAddressInfo(data.address);
            setValue('postcode', data.zonecode);
            setValue('address', data.address);
          }}
        />
      </BlockUI>
    </Modal.Form>
  );
};
