import * as Titled from './../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useState, useCallback } from 'react';

import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { CommonUtil } from '../../utils/commonUtil';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import { classNames } from 'primereact/utils';

let defaultValues = {
  useYn: 'Y',
};

const customerService = ServiceProvider.customer;

const CustomerDialog = ({
  userInfo,
  customerId = 0,
  quickClose = false,
  onHide = null,
  companyCodes,
  getList,
  params,
}) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues,
  });
  const currentValues = useWatch({ control });

  const [codes, setCodes] = useState({});
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [needReset, setNeedReset] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    delete: false,
  });

  useEffect(() => {
    if (companyCodes) {
      setCodes(companyCodes);
    }
  }, [companyCodes]);

  const onSubmit = async (formData) => {
    // return console.log(formData);
    if (!!customerId) {
      formData.customerCompanyId = customerData.customerCompanyId;
    }
    try {
      const { data } =
        customerId > 0
          ? await customerService.update(formData)
          : await customerService.register(formData);

      if (data) {
        if (customerId > 0) {
          window.cerp.toast.success(
            '고객 정보 수정 완료',
            `[${data.customerName}] 고객 정보를 수정했습니다.`
          );
        } else {
          window.cerp.toast.success(
            '고객 등록 완료',
            `[${data.customerName}] 고객을 등록했습니다.`
          );
          setNeedReset(true);
        }

        if (quickClose) onHide(data);
      }
    } catch (error) {
      window.cerp.dialog.error(
        !!customerId ? '고객 정보 수정 실패' : '고객 신규 등록 실패',
        `[${error?.code}] ${error?.message}`
      );
    } finally {
      getList(params[0], params[1], params[2]);
    }
  };

  const checkAuth = (userInfo, type) => {
    const { roleCode } = userInfo;

    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'A_ADM' ||
          r.value === 'E_ADM' ||
          r.value === 'E_OFF' ||
          r.value === 'S_ADM' ||
          r.value === 'S_OFF'
        );
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'A_ADM' ||
          r.value === 'E_ADM' ||
          r.value === 'E_OFF' ||
          r.value === 'S_ADM' ||
          r.value === 'S_OFF'
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  };

  const getCustomerData = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const { data } = await customerService.getData(id);
        if (data) {
          setCustomerData(data);
          const formatter = {
            businessRegistrationNumber: 'biz',
            mainPhone: 'phone',
            fax: 'fax',
            cellphone: 'cellphone',
            contact: 'cellphone',
          };

          for (let field in data) {
            if (formatter[field] && data[field]) {
              setValue(
                field,
                CommonUtil.Formatter[formatter[field]](_.get(data, field))
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
        window.cerp.dialog.error(
          '고객 조회 실패',
          `[${error?.code}] ${error?.message}`
        );
      } finally {
        setLoading(false);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (!!customerId) {
      getCustomerData(customerId);
    }
  }, [customerId, getCustomerData]);

  useEffect(() => {
    if (codes && !customerId) {
      setValue('customerCompanyId', codes.customerCompanies?.[0]?.value);
    }
  }, [codes, customerId, setValue]);

  useEffect(() => {
    if (needReset) {
      reset({ ...defaultValues });
      setNeedReset(false);
    }
  }, [needReset, reset]);

  useEffect(() => {
    if (customerData !== null) {
      reset(customerData);
    }
  }, [customerData, reset]);

  const deleteCustomer = async () => {
    try {
      const { data } = await customerService.delete(customerId);

      if (data) {
        window.cerp.toast.success(
          '고객 삭제 완료',
          `[${data.customerName}] 고객이 삭제되었습니다.`
        );
        onHide();
      }
    } catch (error) {
      window.cerp.dialog.error(
        '고객 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  };

  return (
    <Dialog
      modal
      breakpoints={{ '960px': '90vw' }}
      header={customerId > 0 ? '고객 정보 수정' : '고객 신규 등록'}
      visible
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
          <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
            {customerId > 0 && checkAuth(userInfo, 'DELETE') && (
              <>
                <ConfirmDialog
                  visible={showConfirmPopup['delete']}
                  header="확인"
                  message={
                    <span className="font-bold">고객을 삭제하시겠습니까?</span>
                  }
                  icon="pi pi-exclamation-triangle"
                  acceptLabel="네"
                  accept={() => deleteCustomer()}
                  rejectLabel="아니오"
                  reject={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                  }
                  onHide={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                  }
                />

                <Button
                  label="삭제"
                  icon="pi pi-trash"
                  className="p-button-outlined p-button-danger"
                  onClick={() =>
                    setShowConfirmPopup((ps) => ({ ...ps, delete: true }))
                  }
                />
              </>
            )}

            <ConfirmDialog
              visible={showConfirmPopup['submit']}
              header="확인"
              message={
                customerId > 0
                  ? '고객 정보를 수정하시겠습니까?'
                  : '신규 고객을 등록하시겠습니까?'
              }
              icon="pi pi-exclamation-triangle"
              acceptLabel={customerId > 0 ? '수정' : '등록'}
              accept={() => handleSubmit(onSubmit)()}
              rejectLabel="취소"
              reject={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: false }))
              }
              onHide={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: false }))
              }
            />
            <Button
              id="btn-save"
              label="저장"
              icon="pi pi-save"
              autoFocus
              onClick={() =>
                setShowConfirmPopup((ps) => ({ ...ps, submit: true }))
              }
            />
          </div>
        </div>
      )}
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <form>
          <Panel
            headerTemplate={
              <div className="p-panel-header">
                <div className="flex flex-column align-items-start justify-content-center gap-1">
                  <span className="p-panel-title">1. 고객 기본 정보</span>
                </div>
                {customerId > 0 && checkAuth(userInfo, 'DISABLED') && (
                  <div className="flex align-items-center justify-content-start gap-1">
                    <label
                      className={classNames({
                        'font-semibold': currentValues['useYn'] === YN.NO,
                      })}
                    >
                      미사용
                    </label>
                    <Controller
                      control={control}
                      name="useYn"
                      defaultValue="Y"
                      render={({ field }) => (
                        <InputSwitch
                          id={field.name}
                          {...field}
                          trueValue={'Y'}
                          falseValue={'N'}
                          checked={field.value}
                        />
                      )}
                    />
                    <label
                      className={classNames({
                        'font-semibold': currentValues['useYn'] === YN.YES,
                      })}
                    >
                      사용
                    </label>
                  </div>
                )}
              </div>
            }
          >
            <div className="grid">
              {[
                {
                  code: 'customerCompanyId',
                  title: '소속매매상사',
                  dataKey: 'customerCompanies',
                  placeholder: '없음',
                  required: true,
                  validate: (v) => v > 0 || '선택된 값이 없습니다.',
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue={0}
                    disabled={!!customerId}
                    rules={{
                      required: item.required ? '필수 입력항목입니다.' : false,
                      validate: item.validate,
                    }}
                    render={({ field, formState }) => (
                      <Titled.Dropdown
                        id={field.name}
                        {...item}
                        {...field}
                        showFilter
                        options={codes[item.dataKey]}
                        error={formState.errors[field.name]}
                      />
                    )}
                  />
                </div>
              ))}
              {[
                {
                  type: 'korean',
                  code: 'customerName',
                  title: '이름',
                  required: true,
                },
                {
                  type: 'cellphone',
                  code: 'contact',
                  title: '휴대전화',
                  required: true,
                  pattern: true,
                },
              ].map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controller
                    control={control}
                    name={item.code}
                    defaultValue=""
                    rules={{
                      required: item.required ? '필수 입력항목입니다.' : false,
                      pattern: {
                        value: CommonUtil.Pattern[item.type],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, formState }) => (
                      <Titled.InputText
                        id={field.name}
                        {...item}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);

                          let value = e.target.value;
                          if (item.type)
                            CommonUtil.Formatter[item.type] &&
                              (value = CommonUtil.Formatter[item.type](value));

                          setValue(field.name, value);
                        }}
                        error={formState.errors[field.name]}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </Panel>
          <Panel header="2. 메모 및 기타정보" className="pt-3">
            <div className="grid">
              <div className="col-12">
                <Controller
                  control={control}
                  name="note"
                  defaultValue=""
                  render={({ field, fieldState, formState }) => (
                    <InputTextarea
                      className="w-full mb-0"
                      {...field}
                      rows={3}
                      autoResize
                      placeholder="메모"
                    />
                  )}
                />
              </div>
            </div>
          </Panel>
        </form>
      </BlockUI>
    </Dialog>
  );
};

export default CustomerDialog;
