import * as AccountDialog from './../../components/Dialog/AccountDialog';
import * as Button from './../../components/Atoms/Button';

import React, { useEffect, useMemo, useState } from 'react';

import { COUNT_PER_PAGE } from '../../constants/Constants';
import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';
import Claim from '../../services/ClaimService';
import { Tag } from 'primereact/tag';
import { Partner } from '../../services/PartnerService';

const DEFAULT_SEARCH_CONDITIONS = {
  aids: [],
  searchText: '',
  useYN: YN.YES,
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const partnerService = ServiceProvider.partner;

const PartnerList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const { myAssociationId } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
    aids: [myAssociationId],
  });
  const [lazyParams, setLazyParams] = useState({
    ...DEFAULT_LAZY_PARAMS,
  });
  const { loading, totalRecords, data, getList } = useAuthorizedFetch(
    partnerService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [currentPartnerId, setCurrentPartnerId] = useState(null);
  const [partnerAllType, setPartnerAllType] = useState([]);

  useEffect(() => {
    getPartnerAllType();
  }, []);

  const getPartnerAllType = async () => {
    try {
      const data = await Claim.getDocsType('R');
      setPartnerAllType(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (myInfoLoadable.state === 'hasValue') {
          const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
          setSearchConditions({
            ...searchConditions,
            aids: [myInfo.myAssociationId],
          });
          await getList({
            ...searchConditions,
            aids: [myInfo.myAssociationId],
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state, myInfoLoadable.contents]);

  const deletePartner = async (id) => {
    try {
      const data = await Partner.delete(id);
      if (data) {
        window.cerp.toast.success(
          '진단점 삭제 완료',
          `[${data.partnerInfo.partnerName}] 진단점이 삭제되었습니다.`
        );
        getList({
          ...DEFAULT_SEARCH_CONDITIONS,
          aids: [myAssociationId],
        });
        setCurrentPartnerId(null);
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '진단점 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
      setCurrentPartnerId(null);
    }
  };

  const [exporting, setExporting] = useState(false);
  const getExcelFile = async () => {
    setExporting(true);
    const params = {
      associations: myAssociationId,
      searchText: null,
      useYN: 'Y',
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    };
    try {
      await Partner.exportToExcel(params);
    } catch (error) {
      console.log(error);
    }
    setExporting(false);
  };

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

              return (
                <>
                  <SearchMenu
                    onInit={
                      !!searchConditions.searchText
                        ? () => {
                            setSearchConditions({
                              ...searchConditions,
                              searchText: '',
                            });
                            getList(
                              {
                                ...DEFAULT_SEARCH_CONDITIONS,
                                aids: [myAssociationId],
                              },
                              lazyParams.page,
                              lazyParams.rows
                            );
                          }
                        : null
                    }
                    onSearch={() =>
                      searchConditions.searchText &&
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="search_text">진단점명</label>
                      <div className="flex">
                        <InputText
                          className="w-full"
                          value={searchConditions.searchText}
                          placeholder="진단점 이름으로 검색"
                          onChange={({ target: { value } }) => {
                            setSearchConditions((ps) => ({
                              ...ps,
                              searchText: value,
                              aids: [myInfo.myAssociationId],
                            }));
                          }}
                          onKeyDown={(e) =>
                            e.key === 'Enter'
                              ? getList(
                                  searchConditions,
                                  lazyParams.page,
                                  lazyParams.rows
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                    <Button.Default
                      label="엑셀 다운로드"
                      className="p-button-outlined"
                      icon="pi pi-download"
                      disabled={exporting || !data.length}
                      loading={exporting}
                      onClick={() => getExcelFile()}
                    />
                    <Button.Default
                      label="진단점 신규 등록"
                      icon="pi pi-check-square"
                      onClick={() => {
                        setCurrentPartnerId(0);
                      }}
                    />
                  </div>

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      loading={loading}
                      emptyMessage="데이터가 없습니다."
                      onRowClick={(e) => setCurrentPartnerId(e.data.partnerId)}
                      className="cursor-pointer"
                      stripedRows
                    >
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="partnerName"
                        header="진단점명"
                        bodyClassName="text-center"
                        body={({ partnerName }) => <span>{partnerName}</span>}
                      />
                      {/* <Column
                        field="mainPhone"
                        header="대표전화"
                        bodyClassName="text-center"
                        body={({ mainPhone }) => (
                          <span>
                            {mainPhone && CommonUtil.Formatter.phone(mainPhone)}
                          </span>
                        )}
                      /> */}
                      <Column
                        field="cellphone"
                        header="휴대전화"
                        bodyClassName="text-center"
                        body={({ cellphone }) => (
                          <span>
                            {cellphone &&
                              CommonUtil.Formatter.cellphone(cellphone)}
                          </span>
                        )}
                      />
                      <Column
                        align={'center'}
                        field="reliability"
                        header="신뢰도"
                        body={({ reliability }) => (
                          <span>
                            {reliability === '1'
                              ? '상'
                              : reliability === '2'
                              ? '중'
                              : '하'}
                          </span>
                        )}
                      />
                      <Column
                        field="partnerRepairType"
                        header="진단부위"
                        bodyClassName="text-center"
                        body={({ partnerId, partnerRepairType }) => {
                          let partnerTypeArray = partnerRepairType?.split(',');
                          const labels = partnerTypeArray
                            .map((type) => {
                              const match = partnerAllType.find(
                                (item) => item.value === type
                              );
                              return match ? match.label : null;
                            })
                            .filter(Boolean);
                          return (
                            <>
                              <div
                                id={`partnerType-${partnerId}`}
                                className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                              >
                                {labels.map((label, index) => (
                                  <Tag
                                    key={index}
                                    value={label}
                                    className="ml-1 mr-1"
                                    severity={
                                      label === '국산'
                                        ? 'primary'
                                        : label === '수입'
                                        ? 'danger'
                                        : label === '엔진'
                                        ? 'warning'
                                        : 'success'
                                    }
                                  ></Tag>
                                ))}
                              </div>
                            </>
                          );
                        }}
                      />
                      <Column
                        field="businessRegistrationNumber"
                        header="사업자등록증사본"
                        body={({ partnerDocuments }) => {
                          const item = partnerDocuments?.find(
                            (el) => el.documentCode === 'P_REGISTRATION'
                          );

                          return <span>{!!item ? 'O' : 'X'}</span>;
                        }}
                        align={'center'}
                      />
                      <Column
                        field="bankAccountNumber"
                        header="통장사본"
                        body={({ partnerDocuments }) => {
                          const item = partnerDocuments?.find(
                            (el) => el.documentCode === 'P_BANKBOOK'
                          );

                          return <span>{!!item ? 'O' : 'X'}</span>;
                        }}
                        align={'center'}
                      />
                      <Column
                        field="bankAccountNumber"
                        header="업체동의서"
                        body={({ partnerDocuments }) => {
                          const item = partnerDocuments?.find(
                            (el) => el.documentCode === 'P_AGREEMENT'
                          );

                          return <span>{!!item ? 'O' : 'X'}</span>;
                        }}
                        align={'center'}
                      />
                      <Column
                        field="partnerShip"
                        header="제휴구분"
                        body={({ partnerShip }) => {
                          return (
                            <span>
                              {partnerShip === 'Y' ? '제휴' : '비제휴'}
                            </span>
                          );
                        }}
                        align={'center'}
                      />
                    </DataTable>
                  </Panel>

                  {currentPartnerId !== null && (
                    <AccountDialog.Partner
                      visible
                      userInfo={myInfoLoadable.contents}
                      partnerId={currentPartnerId}
                      onHide={() => {
                        setCurrentPartnerId(null);
                      }}
                      deletePartner={deletePartner}
                      partnerTypes={partnerAllType}
                      getList={getList}
                      searchConditions={searchConditions}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

export default PartnerList;
