import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import _ from 'lodash';

import { Panel } from 'primereact/panel';
import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { compressImages } from '../../utils/compressUtil';
import FileState from '../../enums/FileState';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Claim from '../../services/ClaimService';
import { Image } from 'primereact/image';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import { ToggleButton } from 'primereact/togglebutton';
import DocsViewer from '../Common/Claim/DocsViewer';
import { ACCEPT_FILE_TYPES, DOC_TYPE } from '../../constants/Constants';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';

const CustomerDocument = ({
  deleteImgs,
  setDeleteImgs,
  fileItems,
  setFileItems,
  copyFile,
  setShowSmsType,
  setShowSmsModal,
  carNbr,
  checked,
  onToggle,
  forDealer = false,
  setIsActive = null,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  let { dealer } = useParams();
  const dealerUser = dealer?.split('=')[1] === 'true' ? true : false;
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const [partnerLoginUser, setPartnerLoginUser] = useState(false);

  useEffect(() => {
    if (myInfo?.state === 'hasValue') {
      if (myInfo?.contents?.roleCode === 'D_ADM') {
        setPartnerLoginUser(true);
      } else {
        setPartnerLoginUser(false);
      }
    }
  }, [myInfo.state, myInfo]);

  const deleteFile = (e) => {
    const imgId = e.target.alt ? Number(e.target.alt) : Number(e.target.id);

    if (!!imgId) {
      const trashFile = fileItems.filter((el) => el.imgId === imgId);
      setDeleteImgs(deleteImgs.concat(trashFile));
      const deletedList = fileItems.filter((el) => el.imgId !== imgId);
      setFileItems(deletedList);
    } else {
      const name = e.target.alt || e.target.id;
      const trashFile = fileItems.filter((el) => el.file?.name === name);
      setDeleteImgs(deleteImgs.concat(trashFile));
      const deletedList = fileItems.filter((el) => el.file?.name !== name);
      setFileItems(deletedList);
    }
  };

  const deleteFileForDealer = (e) => {
    const imgId = e.target.alt ? Number(e.target.alt) : Number(e.target.id);

    if (!!imgId) {
      const trashFile = fileItems.map((el) => {
        if (el.imgId === imgId) {
          return el.state ? { ...el, state: 'D' } : el; // state가 있으면 'D'로 변경
        }
        return el;
      });
      setDeleteImgs(deleteImgs.concat(trashFile));
      setFileItems(trashFile);
    } else {
      const name = e.target.alt || e.target.id;
      const trashFile = fileItems.filter((el) => el.file?.name === name);
      setDeleteImgs(deleteImgs.concat(trashFile));
      const deletedList = fileItems.filter((el) => el.file?.name !== name);
      setFileItems(deletedList);
    }
  };

  const undoDeleteFile = (e) => {
    const id = e.target.alt
      ? Number(e.target.alt)
      : Number(e.target.id.slice(3));
    const findFile = copyFile?.find((el) => el.imgId === id);
    const deletedList = deleteImgs?.filter((el) => el.imgId !== id);
    setDeleteImgs(deletedList);
    if (findFile) {
      const files = fileItems?.concat(findFile);
      setFileItems(files);
    }
  };

  const dashboardImg = fileItems?.filter(
    (el) => el.documentCode === 'C_DASHBOARD'
  );
  const contractImg = fileItems?.filter(
    (el) => el.documentCode === 'C_CONTRACT'
  );
  const certificateImg = fileItems?.filter(
    (el) => el.documentCode === 'C_CERTIFICATE'
  );
  const check1Img = fileItems?.filter((el) => el.documentCode === 'S_CHECK1');
  const check2Img = fileItems?.filter((el) => el.documentCode === 'S_CHECK2');
  const saleseContractImg = fileItems?.filter(
    (el) => el.documentCode === 'S_CONTRACT'
  );
  const deletedImg = fileItems?.filter((el) => el.documentCode === 'C_DELETE');
  const etcImg = fileItems?.filter((el) => el.documentCode === 'C_ETC');
  const originImg = fileItems?.filter(
    (el) => el.documentCode === 'C_ORIGINALDOCS'
  );
  const combinedOriginCheck2 = [...originImg, ...check2Img];
  const valueImg = fileItems?.filter((el) => el.documentCode === 'C_VALUE');
  const recallImg = fileItems?.filter((el) => el.documentCode === 'C_RECALL');
  const etcDocsImg = fileItems?.filter((el) => el.documentCode === 'C_ETCDOCS');
  const consentDocsImg = fileItems?.filter(
    (el) => el.documentCode === 'C_CONSENT'
  );

  const [customerDownLoading, setCustomerDownLoading] = useState(false);
  const [dealerDownLoading, setdealerDownLoading] = useState(false);
  const [deleteDownLoading, setDeleteDownLoading] = useState(false);
  const saveFilesAsZip = async (title) => {
    // setDownloadLoading(true);
    title === '고객'
      ? setCustomerDownLoading(true)
      : title === '매매'
      ? setdealerDownLoading(true)
      : setDeleteDownLoading(true);
    const folderName =
      // carDetailData?.performanceCheckData.VHRNO
      carNbr +
      `${
        title === '고객'
          ? '_고객접수서류'
          : title === '매매'
          ? '_매매상사접수서류'
          : '_삭제된서류'
      }`;
    const files =
      title === '고객'
        ? [
            ...dashboardImg,
            ...contractImg,
            ...certificateImg,
            ...etcImg,
            ...valueImg,
            ...recallImg,
            ...etcDocsImg,
            ...consentDocsImg,
          ]
        : title === '매매'
        ? [...check1Img, ...combinedOriginCheck2, ...saleseContractImg]
        : [...deletedImg];
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    const findFile = files.find((el) => el.state === 'I');
    if (!haveIds.length || !!findFile)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files.map(async (file, index) => {
      const imgBlob = await Claim.getBlobFromUrl(file.filePath);
      const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(file.originalFileName);

      const fileName = isImage ? `img_${index + 1}.jpg` : file.originalFileName;
      imagesFolder.file(fileName, imgBlob, { binary: true });
    });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });

    title === '고객'
      ? setCustomerDownLoading(false)
      : title === '매매'
      ? setdealerDownLoading(false)
      : setDeleteDownLoading(false);
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const S_CHECK1 = useRef(null);
  const S_CHECK2 = useRef(null);
  const S_CONTRACT = useRef(null);

  const elementRefs = {
    S_CHECK1,
    S_CHECK2,
    S_CONTRACT,
  };

  const handleClick = (e) => {
    const docType = DOC_TYPE.find((doc) => doc.label === e.target.id);
    if (docType && elementRefs[docType.value]) {
      elementRefs[docType.value].current.click();
    }
  };

  const uploadFiles = async (acceptedFiles, title) => {
    setLoading(true);
    const options = {
      maxSizeMB: 8 * 1024 * 1024,
      // maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    const files = Object.values(acceptedFiles);
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    const compressedImages = await compressImages(files, options);
    compressedImages.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    const code = DOC_TYPE.find((el) => el.label === title)?.value;
    const partCodeAttachedFiles = _.reduce(
      compressedImages,
      (res, v) => {
        res.push({
          documentCode: code,
          file: v,
          state: FileState.Inserted,
        });
        return res;
      },
      []
    );

    setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    setLoading(false);
    window.cerp.toast.info(
      '업로드 성공',
      '저장 버튼을 클릭하여 저장해 주세요.'
    );
  };

  const [curImg, setCurImg] = useState('');

  // 업로드 예정 파일
  const [uploadList, setUploadList] = useState({
    S_CHECK1: [],
    S_CHECK2: [],
    S_CONTRACT: [],
  });

  useEffect(() => {
    if (!forDealer) return;
    const fileData = {
      S_CHECK1: fileItems.filter(
        (item) => item.documentCode === 'S_CHECK1' && item.state === 'I'
      ),
      S_CHECK2: fileItems.filter(
        (item) => item.documentCode === 'S_CHECK2' && item.state === 'I'
      ),
      S_CONTRACT: fileItems.filter(
        (item) => item.documentCode === 'S_CONTRACT' && item.state === 'I'
      ),
    };
    setUploadList(fileData);
  }, [fileItems, forDealer]);

  useEffect(() => {
    if (!forDealer) return;
    const isS_CHECK1Ready = check1Img.filter((el) => el.state !== 'D');
    const isS_CHECK2Ready = check2Img.filter((el) => el.state !== 'D');
    const isS_CONTRACTReady = saleseContractImg.filter(
      (el) => el.state !== 'D'
    );
    const isAllTrue =
      !!isS_CHECK1Ready.length &&
      !!isS_CHECK2Ready.length &&
      !!isS_CONTRACTReady.length;
    setIsActive(!isAllTrue);
  }, [
    uploadList,
    check1Img,
    check2Img,
    forDealer,
    saleseContractImg,
    setIsActive,
  ]);

  return (
    <>
      <Panel
        header={
          <div className="flex justify-content-between align-items-center">
            <div>3. 서류접수 (고객/매매상사)</div>
            {!dealerUser && !partnerLoginUser ? (
              <ToggleButton
                checked={checked.find((el) => el.status === 'Z03')?.value}
                onChange={(e) => onToggle('Z03', e.value)}
                onLabel="완료"
                offLabel="미확인"
                onIcon="pi pi-flag-fill"
                offIcon="pi pi-flag"
              />
            ) : null}
          </div>
        }
        className="pt-3"
      >
        <div className="grid p-2">
          <div className="col-12">
            {!dealerUser && !partnerLoginUser && (
              <>
                <div className="flex justify-content-between align-items-center">
                  <span className="p-card-title">고객</span>
                  <div className="flex gap-2">
                    <Button.Default
                      icon="pi pi-comment"
                      label="서류 재요청"
                      className="p-button-outlined"
                      onClick={() => {
                        setShowSmsModal(true);
                        setShowSmsType('CUSTOMER_DOCS');
                      }}
                    />
                    {!dealerUser && (
                      <Button.Default
                        className="p-button-outlined p-button-secondary"
                        icon="pi pi-download"
                        label="다운로드"
                        onClick={() => saveFilesAsZip('고객')}
                        loading={customerDownLoading}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-column md:flex-row justify-content-between mt-2">
                  <div className="field grid mb-0 lg:col-7 md:col-6 col-12 align-items-center">
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="현재 기준 계기판 사진"
                        imgFiles={dashboardImg}
                        buttonName="C_DASHBOARD"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="매매계약서"
                        imgFiles={contractImg}
                        buttonName="C_CONTRACT"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="자동차등록증"
                        imgFiles={certificateImg}
                        buttonName="C_CERTIFICATE"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="기타서류"
                        imgFiles={etcImg}
                        buttonName="C_ETC"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="개인정보동의서"
                        imgFiles={consentDocsImg}
                        buttonName="C_CONSENT"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                      />
                    </div>
                  </div>
                  <div className="field grid mb-0 lg:col-5 md:col-6 col-12">
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="차량가액"
                        imgFiles={valueImg}
                        buttonName="C_VALUE"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                        upload={true}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="리콜"
                        imgFiles={recallImg}
                        buttonName="C_RECALL"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                        upload={true}
                      />
                    </div>
                    <div className="lg:col-6 md:col-12 col-12">
                      <DocsViewer
                        title="기타서류(외판,튜닝)"
                        imgFiles={etcDocsImg}
                        buttonName="C_ETCDOCS"
                        setCurTitle={setCurTitle}
                        setShowModal={setShowModal}
                        curImg={curImg}
                        setCurImg={setCurImg}
                        deleteFile={deleteFile}
                        upload={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!dealerUser && !partnerLoginUser && <Divider />}
          <div className={'col-12'}>
            {!dealerUser && !partnerLoginUser && (
              <div className="flex justify-content-between align-items-center">
                <span className="p-card-title">매매상사</span>
                <div className="flex gap-2">
                  <Button.Default
                    icon="pi pi-comment"
                    label="서류 재요청"
                    className="p-button-outlined"
                    onClick={() => {
                      setShowSmsModal(true);
                      setShowSmsType('DEALER_ADD_UPLOAD_URL');
                    }}
                  />
                  <Button.Default
                    className="p-button-outlined p-button-secondary"
                    icon="pi pi-download"
                    label="다운로드"
                    onClick={() => saveFilesAsZip('매매')}
                    loading={dealerDownLoading}
                  />
                </div>
              </div>
            )}

            <div className="field grid col-12 md:mt-2 mt-0 align-items-center">
              <div className="lg:col-3 md:col-6 col-12">
                <DocsViewer
                  title="성능기록부"
                  imgFiles={check1Img}
                  buttonName="S_CHECK1"
                  setCurTitle={setCurTitle}
                  setShowModal={setShowModal}
                  curImg={curImg}
                  setCurImg={setCurImg}
                  deleteFile={forDealer ? deleteFileForDealer : deleteFile}
                  handleClick={handleClick}
                  userType={dealerUser}
                  disabled={partnerLoginUser}
                />
                <input
                  ref={S_CHECK1}
                  className="hidden"
                  type="file"
                  accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                  multiple
                  onChange={(e) => uploadFiles(e.target.files, '성능기록부')}
                />
              </div>

              {!partnerLoginUser && (
                <>
                  <div className="lg:col-3 md:col-6 col-12 mt-3 sm:mt-0">
                    <DocsViewer
                      title="자동차등록원부(갑)"
                      imgFiles={combinedOriginCheck2}
                      buttonName="S_CHECK2"
                      setCurTitle={setCurTitle}
                      setShowModal={setShowModal}
                      curImg={curImg}
                      setCurImg={setCurImg}
                      deleteFile={forDealer ? deleteFileForDealer : deleteFile}
                      handleClick={handleClick}
                      userType={dealerUser}
                    />
                    <input
                      ref={S_CHECK2}
                      className="hidden"
                      type="file"
                      accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                      multiple
                      onChange={(e) =>
                        uploadFiles(e.target.files, '자동차등록원부(갑)')
                      }
                    />
                  </div>
                  <div className="lg:col-3 md:col-6 col-12 mt-3 sm:mt-0">
                    <DocsViewer
                      title="매매계약서(업소보관용)"
                      imgFiles={saleseContractImg}
                      buttonName="S_CONTRACT"
                      setCurTitle={setCurTitle}
                      setShowModal={setShowModal}
                      curImg={curImg}
                      setCurImg={setCurImg}
                      deleteFile={forDealer ? deleteFileForDealer : deleteFile}
                      handleClick={handleClick}
                      userType={dealerUser}
                    />
                    <input
                      ref={S_CONTRACT}
                      className="hidden"
                      type="file"
                      accept="image/png, image/jpeg, .pdf, .hwp, .xlsx, .doc, .docx"
                      multiple
                      onChange={(e) =>
                        uploadFiles(e.target.files, '매매계약서(업소보관용)')
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {!dealerUser && !partnerLoginUser && (
            <>
              <Divider />
              <div className="grid col-12 align-items-center">
                <div className="md:col-6 sm:col">
                  <div className="flex gap-2 font-bold align-items-center">
                    <Tooltip target=".delete" />
                    <div
                      className="delete cursor-pointer"
                      data-pr-position="right"
                    >
                      삭제 완료된 서류 :
                    </div>
                    <div>
                      {!!deletedImg.length ? deletedImg.length + ' 장' : '0'}
                    </div>
                    {deletedImg?.length > 0 && (
                      <div className="flex-1 flex justify-space-between">
                        <div className="flex-1">
                          <Button.Default
                            label="보기"
                            icon="pi pi-window-maximize"
                            className="p-button-text"
                            onClick={() => {
                              const imageDocs = deletedImg.filter((item) =>
                                /\.(jpg|jpeg|png|gif|webp)$/i.test(
                                  item.originalFileName
                                )
                              );
                              sessionStorage.setItem(
                                'images',
                                JSON.stringify(imageDocs)
                              );
                              CommonUtil.Window.popup(
                                '/compensation/accident/claim/viewer',
                                '',
                                window.innerWidth * 0.9,
                                window.innerHeight * 0.9
                              );
                            }}
                          />
                          <Button.Default
                            icon={`pi ${
                              curImg === 'C_DELETE'
                                ? 'pi-angle-up'
                                : 'pi-angle-down'
                            }`}
                            label=""
                            className="p-button-text"
                            onClick={(e) => {
                              curImg === 'C_DELETE'
                                ? setCurImg('')
                                : setCurImg('C_DELETE');
                            }}
                            name="C_DELETE"
                          />
                        </div>
                        <Button.Default
                          className="p-button-outlined"
                          icon="pi pi-download"
                          label="다운로드"
                          onClick={() => saveFilesAsZip('삭제')}
                          loading={deleteDownLoading}
                        />
                      </div>
                    )}
                  </div>
                  {curImg === 'C_DELETE' && (
                    <div className="flex flex-wrap mt-2">
                      {deletedImg?.map((el, idx) => {
                        if (el.state === 'D') return;
                        if (el.state === 'I' && !el.file) return;
                        const fileName = el?.filePath
                          ? el?.filePath?.split('/').pop()
                          : el?.file?.name;

                        const fileExtension = fileName
                          .split('.')
                          .pop()
                          .toLowerCase();
                        const imageExtensions = [
                          'jpg',
                          'jpeg',
                          'png',
                          'webp',
                          'gif',
                        ];
                        const isImage = imageExtensions.includes(fileExtension);
                        return (
                          <span key={el.imgId} className="p-2">
                            {isImage ? (
                              // 이미지 파일일 경우 썸네일, 아닐 경우 div 태그
                              <Image
                                height="28"
                                alt={el.imgId ? el.imgId : el.file.name}
                                src={
                                  el?.filePath
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      el.filePath
                                    : el?.file?.preview
                                }
                              />
                            ) : (
                              <div
                                className="bg-gray-100 p-2 flex align-items-center gap-1"
                                style={{ borderRadius: '10px' }}
                              >
                                <i
                                  className="pi pi-file"
                                  style={{
                                    fontSize: '2.5rem',
                                    color: '#2196F3',
                                  }}
                                ></i>
                                <p key={idx} className="text-sm">
                                  {/* 서버에 올라간 사진인지 아닌지 여부 */}
                                  {el?.originalFileName
                                    ? el?.originalFileName
                                    : el?.file.name}
                                </p>
                              </div>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="md:col-6 sm:col">
                  <div className="flex gap-2 font-bold align-items-center">
                    <div
                      className="delete cursor-pointer"
                      data-pr-tooltip="수정완료 시 취소 불가"
                      data-pr-position="right"
                    >
                      삭제 예정 서류 :
                    </div>
                    <Tooltip target=".delete" />
                    <div>
                      {!!deleteImgs.length ? deleteImgs.length + ' 장' : '0'}
                    </div>
                    {deleteImgs?.length > 0 && (
                      <>
                        <Button.Default
                          label="보기"
                          icon="pi pi-window-maximize"
                          className="p-button-text"
                          onClick={() => {
                            const imageDocs = deleteImgs.filter((item) =>
                              /\.(jpg|jpeg|png|gif|webp)$/i.test(
                                item.originalFileName
                              )
                            );
                            sessionStorage.setItem(
                              'images',
                              JSON.stringify(imageDocs)
                            );
                            CommonUtil.Window.popup(
                              '/compensation/accident/claim/viewer',
                              '',
                              window.innerWidth * 0.9,
                              window.innerHeight * 0.9
                            );
                          }}
                        />
                        <Button.Default
                          icon={`pi ${
                            curImg === 'DELETE'
                              ? 'pi-angle-up'
                              : 'pi-angle-down'
                          }`}
                          label=""
                          className="p-button-text"
                          onClick={(e) => {
                            curImg === 'DELETE'
                              ? setCurImg('')
                              : setCurImg('DELETE');
                          }}
                          name="DELETE"
                        />
                      </>
                    )}
                  </div>
                  {curImg === 'DELETE' && (
                    <div className="flex flex-wrap mt-2">
                      {deleteImgs?.map((el, idx) => {
                        if (el.state === 'D') return;
                        if (el.state === 'I' && !el.file) return;
                        const fileName = el?.filePath
                          ? el?.filePath?.split('/').pop()
                          : el?.file?.name;

                        const fileExtension = fileName
                          .split('.')
                          .pop()
                          .toLowerCase();
                        const imageExtensions = [
                          'jpg',
                          'jpeg',
                          'png',
                          'webp',
                          'gif',
                        ];
                        const isImage = imageExtensions.includes(fileExtension);
                        return (
                          <span
                            className="cursor-pointer flex flex-column align-items-center p-2"
                            onClick={(e) => undoDeleteFile(e)}
                            key={idx}
                            id={`cs_${el?.imgId || el.file?.name}`}
                          >
                            {isImage ? (
                              // 이미지 파일일 경우 썸네일, 아닐 경우 div 태그
                              <Image
                                className="mb-2"
                                height="28"
                                alt={el?.imgId || el.file?.name}
                                src={
                                  el?.filePath
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      el.filePath
                                    : el?.file?.preview
                                }
                              />
                            ) : (
                              <div
                                className="bg-gray-100 p-2 flex align-items-center gap-1"
                                style={{ borderRadius: '10px' }}
                              >
                                <i
                                  className="pi pi-file"
                                  style={{
                                    fontSize: '2.5rem',
                                    color: '#2196F3',
                                  }}
                                ></i>
                                <p key={idx} className="text-sm">
                                  {/* 서버에 올라간 사진인지 아닌지 여부 */}
                                  {el?.originalFileName
                                    ? el?.originalFileName
                                    : el?.file.name}
                                </p>
                              </div>
                            )}
                            <i
                              style={{ color: '#d9362b' }}
                              className="pi pi-plus-circle"
                              id={el.imgId ? el?.imgId : el.file?.name}
                            ></i>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Panel>

      {showModal && curTitle && (
        <ImagesPopup
          title={curTitle}
          onHide={() => setShowModal(!showModal)}
          setFileItems={setFileItems}
          copyFile={copyFile}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default CustomerDocument;

const ImagesPopup = ({
  onHide,
  images,
  title,
  setFileItems,
  copyFile,
  loading,
  setLoading,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 20 * 1024 * 1024,
    accept: ACCEPT_FILE_TYPES,
    onDrop: async (acceptedFiles, fileRejections) => {
      setLoading(true);
      const options = {
        maxSizeMB: 8 * 1024 * 1024,
        // maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      if (!!fileRejections.length) {
        const isAcceptedFileType = (fileType, acceptFiles) => {
          return Object.keys(acceptFiles).some((key) => {
            if (key.endsWith('/*')) {
              const baseType = key.split('/')[0];
              return fileType.startsWith(baseType);
            }
            return key === fileType;
          });
        };

        const oversizedFiles = fileRejections.filter(
          (file) => file.file.size > 20 * 1024 * 1024
        );
        const invalidFiles = fileRejections.filter(
          (rejection) =>
            !isAcceptedFileType(rejection.file.type, ACCEPT_FILE_TYPES)
        );

        if (oversizedFiles.length && invalidFiles.length) {
          setLoading(false);
          return window.cerp.toast.warn('첨부가능한 파일 형식이 아닙니다.');
        } else if (oversizedFiles.length) {
          setLoading(false);
          return window.cerp.toast.warn(
            '파일용량 20MB 이상 업로드 불가',
            `${fileRejections.length} 장`
          );
        } else {
          setLoading(false);
          return window.cerp.toast.warn('첨부가능한 파일 형식이 아닙니다.');
        }
      }
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const code = DOC_TYPE.find((el) => el.label === title).value;
      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: code,
            file: v,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
      setLoading(false);
      onHide();
      window.cerp.toast.info(
        '업로드 성공',
        '저장 버튼 클릭하여 등록해 주세요.'
      );
    },
  });
  return (
    <>
      <Modal.Default
        header={`${title} 등록`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  사진을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};
